import React, { useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {useSpring, animated} from 'react-spring'
import { useHistory, useLocation } from 'react-router-dom';
import useComponentSize from '@rehooks/component-size'

interface NavIconProps {
  icon: IconProp;
  text: string;
  route?: string;
  onClick?: () => void;
  isPremium?: boolean;
  exactRoute?: boolean;
}

const AnimatedFontAwesome = animated(FontAwesomeIcon);

function NavIcon(props: NavIconProps) {
  const [active, setActive] = useState(false);

  const location = useLocation()
  const history = useHistory()
  let ref = useRef(null)
  let size = useComponentSize(ref)

  let isCurrentRoute = false;
  if (!!props.route && !!!props.exactRoute && location.pathname.startsWith(props.route)) {
    isCurrentRoute = true;
  } else if (!!props.route && props.exactRoute && location.pathname === props.route) {
    isCurrentRoute = true;
  }

  let bgColor = ['black', '#f3f6f9']
  let iconColor = ['white', 'black']

  if (props.isPremium) {
    bgColor = ['#3b0a6c', '#d4cbed']
    iconColor = ['white', '#3b0a6c']
  }

  const animationProps = useSpring({
    containerWidth: active ? size.width : 39,
    containerBackground: active ? bgColor[0] : bgColor[1],
    textOpacity: active ? 1 : 0,
    iconColor: active ? iconColor[0] : iconColor[1],
    config: {
      tension: 210,
      friction: 20
    }
  })

  useEffect(() => {
    if (!props.route) {
      return;
    }

    if (isCurrentRoute) {
      setActive(true)
    } else {
      setActive(false)
    }
  }, [location, props.route, isCurrentRoute])

  const buttonClick = useCallback(() => {
    if (props.route) {
      history.push(props.route)
    }

    if (props.onClick) {
      props.onClick()
    }

  }, [props, history])

  function onHover(toggle: boolean) {
    if (props.route && !toggle && isCurrentRoute) {
      return;
    }

    setActive(toggle);
  }

  return (
    <AnimatedContainer onClick={buttonClick} onMouseEnter={() => onHover(true)} onMouseLeave={() => onHover(false)} style={{width: animationProps.containerWidth, backgroundColor: animationProps.containerBackground}}>
      <Content ref={ref}>
        <AnimatedFontAwesome style={{ color: animationProps.iconColor }} icon={props.icon} />
        <AnimatedText style={{ opacity: animationProps.textOpacity }}>{props.text}</AnimatedText>
      </Content>
    </AnimatedContainer>
  );
}

export default observer(NavIcon);

const AnimatedContainer = styled(animated.div)`
  border-radius: 12px;
  background-color: ${(props) => props.theme.iconBg};
  width: 39px;
  height: 37px;
  display: flex;
  align-items: center;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  text-decoration: none;
  user-select: none;
`;

const Content = styled.div`
  padding-left: 11px;
  display: flex;
  align-items: center;
  padding-right: 11px;
`

const AnimatedText = styled(animated.p)`
  color: ${(props) => props.theme.lightText};
  font-size: 14px;
  font-weight: 600;
  margin-left: 7px;
  opacity: 0;
  white-space: nowrap;
`