import { Instance, types } from 'mobx-state-tree';
import { MomentPrimitive } from '@util/mstTypes';
import SessionRecording from '@models/SessionRecording';

export interface ISessionSettings {
  enableAudioPostProcessing: boolean;
}

const ClientJoinUrls = types.model('ClientJoinUrls', {
  web: types.string
})

const Session = types.model('Session', {
  id: types.identifier,
  userId: types.string,
  name: types.string,
  slug: types.string,
  callState: types.union(types.literal('CREATED'), types.literal('IN_PROGRESS'), types.literal('FINISHED')),
  sessionType: types.union(types.literal('SINGLE_PLAYER'), types.literal('MULTI_PLAYER')),
  recordingEnabled: types.boolean,
  joinUrls: types.model('JoinUrls', {
    host: ClientJoinUrls,
    guests: ClientJoinUrls
  }),
  dateCreated: MomentPrimitive,
  sessionRecordings: types.array(SessionRecording),
}).views(self => ({
  get isUploading() {
    return self.sessionRecordings.some(item => item.isUploading)
  },
  get isProcessing() {
    return self.sessionRecordings.some(item => item.isProcessing)
  },
})).views(self => ({
  get combinedState() {
    if (self.isProcessing) {
      return 'PROCESSING'
    }

    if (self.isUploading) {
      return 'UPLOADING'
    }

    return self.callState
  },
  get hasRecordings() {
    return self.sessionRecordings.some(item => item.hasRecordings)
  },
  get hasRemoteRecordings() {
    return self.sessionRecordings.some(item => item.hasRemoteRecordings)
  }
}))

export default Session;

export interface ISession extends Instance<typeof Session> {}