import Editor, { CutSection as CutSectionModel } from '@models/editor/Editor';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import styled from 'styled-components/macro';
import {useSpring, animated} from 'react-spring'
import useResizeObserver from "use-resize-observer";
import { autorun } from 'mobx';
import { lighten } from 'polished';

interface TimelineProps {
  editor: Editor;
}


function Timeline(props: TimelineProps) {
  const ref = useRef<HTMLDivElement>(null)
  const {width = 1} = useResizeObserver({ref: ref});
  const [isDragging, setIsDragging] = useState(false);

  const [styles, api] = useSpring(() => ({
    from: { backgroundLeft: '-100%', draggerLeft: '0%' },
  }))

  const timelineClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (!e) return;
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const jump = props.editor.lengthMs * (x / width);
    props.editor.updateCurrentTime(jump / 1000, true);
  }, [width, props.editor])

  useEffect(() => {
    // current time is updated very often (50ms). We dont want to rerender this entire component on every little current time change
    // So we use mobx autorun to JUST run an animation. And skip all other react render stuff.
    const disposer = autorun(() => {
      const currentTime = props.editor.currentTimeMs;
      const totalTime = props.editor.lengthMs;
      let percent = (currentTime / totalTime) * 100;
      if (percent > 100) {
        percent = 100;
      } else if (percent < 0) {
        percent = 0;
      }

      api.set({ backgroundLeft: `${percent - 100}%`, draggerLeft: `${percent}%` })
    })

    return () => disposer()
  }, [props.editor, api])

  useEffect(() => {
    if (!isDragging) {
      return;
    }

    function setDrag() {
      setIsDragging(false);
    }

    window.addEventListener('mouseup', setDrag);
    return () => window.removeEventListener('mouseup', setDrag)
  }, [isDragging])

  useEffect(() => {
    if (!isDragging || !ref.current) {
      return;
    }

    function drag(e: MouseEvent) {
      const X = e.clientX;
      const rect = ref.current!.getBoundingClientRect();
      const position = Math.max(Math.min(X - rect.x, rect.width), 0);
      let percent = (position / rect.width) * 100;
      if (percent > 100) {
        percent = 100;
      } else if (percent < 0) {
        percent = 0;
      }

      const jump = props.editor.lengthMs * (percent / 100)
      window.requestAnimationFrame(() => props.editor.updateCurrentTime(jump / 1000, true));      
    }

    window.addEventListener('mousemove', drag);
    return () => window.removeEventListener('mousemove', drag)
  }, [isDragging, ref, api, props.editor])

  const strips = props.editor.visibleCutSections.map(item => <CutStrip cutSection={item} key={item.id}/>)

  return (
    <div style={{position: 'relative'}}>
      <Container onClick={(e) => timelineClick(e)} ref={ref}>
        <TimelineBackground>
        <Line />
        </TimelineBackground>
        {strips}
        <TimeBackground style={{left: styles.backgroundLeft}} />
      </Container>
      <TimeDragger onMouseDown={() => setIsDragging(true)} style={{left: styles.draggerLeft}}><div></div></TimeDragger>
    </div>

  )
}

export default observer(Timeline)


interface CutStripProps {
  cutSection: CutSectionModel;
}

const CutStrip = observer((props: CutStripProps) => {
  const dimensions = props.cutSection.dimensionsPercentage;

  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    props.cutSection.jumpToNode();
  }

  // minimum size on timeline
  if (dimensions.width < 0.005) {
    dimensions.width = 0.005;
  }

  return <CutSection onClick={onClick} {...dimensions} />
})

const Container = styled.div`
  margin-top: 15px;
  position: relative;
  user-select: none;
  overflow-x: hidden;
`

const TimelineBackground = styled.div`
  width: 100%;
  height: 30px;
  border-radius: 6px;
  background-color: #d7dfe8;
  position: relative;
  padding: 3px 5px;
`

const CutSection = styled.div<{left: number; width: number}>`
  width: ${(props) => props.width * 100}%;
  left: ${(props) => props.left * 100}%;
  background: #FE4545;
  border-radius: 4px;
  position: absolute;
  height: 100%;
  top: 0;
  z-index: 2;
  cursor: pointer;

  &:hover {
    background: ${() => lighten(0.05, '#FE4545')};
  }
`

const TimeBackground = styled(animated.div)`
  position: absolute;
  border-radius: 3px;
  width: 100%;
  z-index: 1;
  background-color: #98A8BA;
  height: 3px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`

const Line = styled.div`
  position: absolute;
  border-radius: 2px;
  width: 100%;
  z-index: 1;
  background-color: #BEC9D7;
  height: 2px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`

const TimeDragger = styled(animated.div)`
  width: 18px;
  height: 18px;
  background: #FFFFFF;
  box-shadow: 0px 1px 5px #A1ADBA;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-9px);
  cursor: pointer;
  transition: 0.2s transform;

  > div {
    width: 10px;
    height: 10px;
    background: #FECA45;
    box-shadow: 0px 0px 3px #FFE8AD;
    border-radius: 50%;
  }

  &:hover {
    transform: translateY(-50%) translateX(-9px) scale(1.2);
  }
`