import { flow, types } from 'mobx-state-tree';
import StandaloneTranscript from '@models/StandaloneTranscript';
import {getApi, getNotifications} from '@stores/root';

export default types.model('TranscriptStore', {
  standaloneTranscripts: types.map(StandaloneTranscript),
  currentTranscript: types.maybeNull(types.reference(StandaloneTranscript))
}).volatile(self => ({
  uploadingFiles: new Map<string, string>(),
  loadingTranscripts: false
})).views(self => ({
  get displayTranscripts() {
    return [...self.standaloneTranscripts.values()].filter(item => item.state === 'READY' || item.state === 'PROCESSING').sort((a, b) => b.createdAt as any - (a.createdAt as any))
  },
  get hasTranscripts() {
    return self.standaloneTranscripts.size > 0
  }
})).actions(self => ({
  getTranscript: flow(function* (id: string) {
    const data = yield getApi(self).fetchStandaloneTranscript(id);
    self.standaloneTranscripts.put(data);
  })
})).actions(self => ({
  loadTranscripts: flow(function* () {
    self.loadingTranscripts = true

    try {
      const data = yield getApi(self).fetchStandaloneTranscripts()
      data.forEach((item: any) => {
        self.standaloneTranscripts.put(item)
      })
    } catch (e) {
      console.error(e)
    } finally {
      self.loadingTranscripts = false;
    }
  }),
  createTranscript: flow(function*(file: any) {
    const data = yield getApi(self).createStandaloneTranscript(file.type);
    self.standaloneTranscripts.put(data);
    self.uploadingFiles.set(file.id, data.id)
    return self.standaloneTranscripts.get(data.id)
  }),
  finishTranscriptUpload: flow(function* (file: any) {
    const id = self.uploadingFiles.get(file.id);
    if (id) {
      yield getApi(self).updateStandaloneTranscript(id, { state: 'UPLOADED' });
      yield self.getTranscript(id)
      self.uploadingFiles.delete(file.id)
      getNotifications(self).success('Upload complete. Please wait while we process your file.')
    }
  }),
})).actions(self => ({
  setCurrentTranscript: flow(function* (id: string | null) {
    if (!id) {
      self.currentTranscript = null;
      return
    }

    const transcript = self.standaloneTranscripts.get(id);

    if (!transcript) {
      yield self.getTranscript(id);
    }

    self.currentTranscript = self.standaloneTranscripts.get(id)!
  })
}))