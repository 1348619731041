import { types } from 'mobx-state-tree';
import Organization from '@models/Organization';

const FeatureFlags = types.model('FeatureFlags', {})

export default types.model('User', {
  id: types.identifier,
  email: types.string,
  extraInfo: types.frozen(),
  organization: Organization,
  featureFlags: FeatureFlags,
  googleId: types.maybeNull(types.string)
})