import '@fortawesome/fontawesome-svg-core/styles.css';
import { library, config } from '@fortawesome/fontawesome-svg-core';

import {
  faRecordVinyl,
  faArrowRight,
  faArrowLeft,
  faSignInAlt,
  faLink,
  faFilmAlt,
  faChevronLeft,
  faArrowDown,
  faChevronDown,
  faSignOut,
  faCog,
  faLifeRing,
  faDove,
  faTags,
  faStar,
  faQuestionCircle, faPlus, faCircle, faMinus, faSave, faTrash, faText, faFileAlt, faTag, faSearch, faUser, faUsers, faCloudDownload, faSignalStream, faShare, faPlay, faClosedCaptioning, faShareSquare, faCut, faPause, faUndo, faShapes, faInfoCircle, faVolumeMute, faVolumeUp, faDownload
} from '@fortawesome/pro-solid-svg-icons';

import {
  faApple,
  faWindows
} from '@fortawesome/free-brands-svg-icons'

// dont autoadd font awesome css
config.autoAddCss = false;
// wired typings bug
(library as any).add(
  faRecordVinyl,
  faPlus,
  faApple,
  faWindows,
  faCircle,
  faMinus,
  faArrowRight,
  faArrowLeft,
  faSignInAlt,
  faLink,
  faFilmAlt,
  faChevronLeft,
  faArrowDown,
  faTags,
  faSignOut,
  faChevronDown,
  faCog,
  faLifeRing,
  faDove,
  faStar,
  faQuestionCircle,
  faSave,
  faTrash,
  faText,
  faFileAlt,
  faTag,
  faSearch,
  faUser,
  faUsers,
  faCloudDownload,
  faSignalStream,
  faShare,
  faPlay,
  faClosedCaptioning,
  faShareSquare,
  faCut,
  faPause,
  faUndo,
  faShapes,
  faInfoCircle,
  faVolumeMute,
  faVolumeUp,
  faDownload
);