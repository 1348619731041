import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useRouteMatch } from 'react-router';
import { useCurrentSession } from '../../../hooks/useCurrentSession';
import Transcript from '@components/containers/transcription/Transcript';

function SessionTranscript() {
  const match = useRouteMatch<{session_recording_id: string}>();
  const session = useCurrentSession();
  const sessionRecording = session?.sessionRecordings.find(item => item.id === match.params.session_recording_id)

  useEffect(() => {
    if (sessionRecording && !sessionRecording.recordingInfo) {
      sessionRecording.loadRecordingInfo()
    }
  }, [sessionRecording]);

  if (!sessionRecording || !session) {
    return null;
  }

  return <Transcript sessionRecording={sessionRecording} name={`${session.name} - ${sessionRecording.name}`} backToUrl={`/sessions/session/${session.id}/recordings`} />
}

export default observer(SessionTranscript);