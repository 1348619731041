import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import { toast } from 'react-toastify';
import { useStore } from '@stores/root';
import { useLocation } from 'react-router-dom';

interface GoogleLoginProps {}

function GoogleLogin(props: GoogleLoginProps) {
  const store = useStore();
  const location = useLocation();

  const onSignIn = useCallback(
    async (user: any) => {
      const idToken = user.getAuthResponse().id_token as string;
      const { from } = (location.state as any) || { from: { pathname: '/' } };
      await store.authStore.googleLogin(idToken, from);

      const { gtag } = window as any;
      if (gtag && idToken !== '') {
        gtag('event', 'sign_up', {
          event_category: 'auth',
          event_label: 'Signup with Google',
        });
        gtag('event', 'sign_up', {
          event_category: 'auth',
          event_label: 'Signup',
        });
      }
    },
    [store.authStore, location.state]
  );

  const onFail = useCallback(() => {
    toast.error('Could not login using Google. Please try again.');
  }, []);

  useEffect(() => {
    let interval = setInterval(() => {
      const { gapi } = window as any;

      if (gapi) {
        clearInterval(interval);
        gapi.load('auth2', () => {
          gapi.auth2.init().then((auth: any) => {
            auth.signOut().then(() => {
              gapi.signin2.render('google-signin-button', {
                longtitle: true,
                scope: 'profile email',
                theme: 'dark',
                height: 60,
                onsuccess: onSignIn,
                onfailure: onFail,
              });
            });
          });
        });
      }
    }, 300);
  }, [onSignIn, onFail]);

  return (
    <Container>
      <div id="google-signin-button" className="google-signin-button" />
    </Container>
  );
}

export default observer(GoogleLogin);

const Container = styled.div``;
