import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCurrentSession } from '../../../hooks/useCurrentSession';
import { useStore } from '@stores/root';
import Toggle from '@components/ui/Toggle';
import { Tooltip } from 'react-tippy';
import SessionRecordings from '@components/ui/SessionRecordings';

interface RecordingsProps {}

function Recordings(props: RecordingsProps) {
  const [showBackups, setShowBackups] = useState(false);
  const store = useStore();
  const session = useCurrentSession();

  useEffect(() => {
    if (!session) {
      return
    }

    let timer: number | null = null;

    async function fetchSessions() {
      await store.sessionStore.getSession(session!.id)
      timer = setTimeout(() => {
        fetchSessions()
      }, 3000)
    }

    fetchSessions()

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    }

  }, [store.sessionStore, session])

  if (!session) {
    return null;
  }

  const recordings = session.sessionRecordings.map(sessionRecording => <SessionRecordings key={sessionRecording.id} session={session} sessionRecording={sessionRecording} showBackups={showBackups} />)

  return (
    <Container>
      <Header>
        <Headline to='/sessions'>
          <FontAwesomeIcon icon='chevron-left' />
          {session.name}
        </Headline>
        {session.hasRemoteRecordings ?
          <Backups onClick={() => setShowBackups(!showBackups)}>
            <BackupsText>Show serverside recordings</BackupsText>
            <Tooltip
              html={(
                <p style={{ maxWidth: 310, textAlign: 'left' }}>In case of any issue with local recording you can use backups recorded on our server.</p>
              )}
              style={{ color: '#949ba3' }}
              animateFill={false}
            >
              <FontAwesomeIcon icon='question-circle' />
            </Tooltip>
            <Toggle active={showBackups} />
          </Backups>
        : null}
      </Header>
      <div>
        {recordings}
      </div>
    </Container>
  );
}

export default observer(Recordings);

const Container = styled.div`
  max-width: 1012px;
  margin: 0 auto;
`;

const Headline = styled(Link)`
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  line-height: 29.63px;
  text-decoration: none;
  display: flex;
  align-items: center;
  
  svg {
    font-size: 14px;
    font-weight: 400;
    line-height: 23.04px;
    margin-right: 11px;
  }
  
  &:hover {
    text-decoration: underline;
  }
`

const Header = styled.div`
  margin-top: 75px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Backups = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  
  > *:last-child {
    margin-left: 8px;
  }
`

const BackupsText = styled.p`
  color: #949ba3;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 26.34px;
  text-align: left;
  margin-right: 10px;
`