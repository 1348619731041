import { applySnapshot, getEnv, types } from 'mobx-state-tree';
import React from 'react';
import API from '../api';
import Auth from './auth';
import Account from './account'
import Sessions from './sessions';
import Studio from './studio';
import Editor from './editors';
import TranscriptStore from './transcripts';
import history from '@util/history';
import Toaster from '@util/toaster';
import { Websocket } from '@util/websocket';

const api = new API();
const notifications = new Toaster();
const websocket = new Websocket();
export const getApi = (self: any) => getEnv(self).api as API
export const getHistory = (self: any) => getEnv(self).history as typeof history;
export const getNotifications = (self: any) => getEnv(self).notifications as Toaster;
export const getWebsocket = (self: any) => getEnv(self).websocket as Websocket;

const RootStore = types.model('Store', {
  authStore: Auth,
  accountStore: Account,
  sessionStore: Sessions,
  studioStore: Studio,
  transcriptStore: TranscriptStore,
  editorStore: Editor
}).actions(self => {
  const api = getApi(self);

  api.on('unauthorized.api.access', () => {
    self.authStore.removeToken();
  })

  function afterCreate() {
    const token = window.localStorage.getItem('token')
    if (token) {
      websocket.connect(token);
      api.setToken(token);
      self.authStore.token = token;
    }
  }

  function resetState() {
    applySnapshot(self, initialSnapshot)
  }

  return {
    afterCreate,
    resetState
  }
})

const initialSnapshot = {
  authStore: {
    inProgress: false,
    email: '',
    password: '',
    basePlanSlug: ''
  },
  sessionStore: {
    sessions: {},
    loadingSessions: false,
    creatingSession: false,
    loadingNextPage: false
  },
  studioStore: {
    studios: [],
    broadcast: {},
    currentBroadcast: null
  },
  transcriptStore: {},
  accountStore: {
    submittingExtraInfo: false,
    loadingUser: false
  },
  editorStore: {}
}

export const store = RootStore.create(initialSnapshot, { api, history, notifications, websocket });

const storeContext = React.createContext<typeof store | null>(null);

export const StoreProvider = (props: any) => {
  return <storeContext.Provider value={store}>{props.children}</storeContext.Provider>
}

export const useStore = () => {
  const store = React.useContext(storeContext)
  if (!store) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error('useStore must be used within a StoreProvider.')
  }
  return store
}

(window as any).ROOT_STORE = store;