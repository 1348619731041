import React, { useEffect } from 'react';
import { useStore } from '@stores/root';
import { observer } from 'mobx-react-lite';
import { Redirect, Route, RouteProps } from 'react-router';


function PrivateRoute(props: RouteProps) {
  const store = useStore();

  if (!store.authStore.isAuthorized) {
    return <Redirect to={{
      pathname: '/auth/login',
      state: { from: props.location }
    }} />
  }

  return (
    <Route {...props}>
      <UserFetch>
        {props.children}
      </UserFetch>
    </Route>
  );
}

interface ForwardProps {
  children: React.ReactNode;
}

function UserFetch(props: ForwardProps) {
  const store = useStore();

  useEffect(() => {
    store.accountStore.fetchUser();
  }, [store.accountStore])

  useEffect(() => {
    store.accountStore.fetchCurrentPlan();
  }, [store.accountStore])

  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  )
}

export default observer(PrivateRoute);