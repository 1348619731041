import { Word as WordModel } from '@models/editor/Paragraph';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components/macro';

interface WordProps {
  word: WordModel;
}

function Word(props: WordProps) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current && props.word.selected) {
      ref.current.scrollIntoView({behavior: "smooth", block: "center"});
    }
  }, [props.word.selected, ref])

  const isEmpty = props.word.text === '';

  return (
    <Text isEmpty={isEmpty} data-word-id={props.word.id} onClick={props.word.jumpToWord} ref={ref} selected={props.word.selected}>{isEmpty ? '●' : props.word.text}</Text>
  )
}

export default observer(Word)

const Text = styled.span<{selected: boolean; isEmpty: boolean}>`
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #000000;

  display: inline-flex;
  align-items: center;

  ${(props) => props.isEmpty && css`
    color: #949BA3;
  `}

  ${(props) => props.selected && css`
    background-color: #FECA45;
    color: #000000;
  `}
`