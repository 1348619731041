import { useTextSelection } from '../../hooks/useTextSelection'
import React, { FunctionComponent, PropsWithChildren } from 'react'
import { createPortal } from 'react-dom'

type Props = {
  target: HTMLElement | null;
  mount: HTMLElement | null;
  render: FunctionComponent<any>
  [key: string]: any;
}

function Portal(props: PropsWithChildren<{mount?: HTMLElement}>) {
  return createPortal(
    props.children,
    props.mount || document.body
  )
}

export default function Popover(props: PropsWithChildren<Props>) {
  const { render: Render } = props
  const textSelectionProps = useTextSelection(props.target, props.mount)
  return <Portal mount={props.mount as any}>
    <Render {...textSelectionProps} {...props} />
  </Portal>
}