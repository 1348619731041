import { getParentOfType, Instance, types } from 'mobx-state-tree';
import { closestIndex } from '@util/object';
import SessionRecordingInfo from '@models/SessionRecordingInfo';


export const Timestamp = types.model('Timestamp', {
  start: types.number,
  end: types.number,
  id: types.identifier
}).views(self => ({
  get word() {
    const info = getParentOfType(self, SessionRecordingInfo);
    const index = closestIndex(self.start, info.transcript.wordTimings);
    return info.transcript.allWords[index];
  },
}))

export interface ITimestamp extends Instance<typeof Timestamp> {}