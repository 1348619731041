import ParagraphModel from '@models/editor/Paragraph';
import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components/macro';
import Word from '@components/ui/editor/content/Word';
import TextCut from './TextCut';


interface ParagraphProps {
  paragraph: ParagraphModel
}


function Paragraph(props: ParagraphProps) {

  const words = [];
  for (let word of props.paragraph.words) {
    const delSection = props.paragraph.cutSectionStartIndex.get(word.index);
    if (delSection) {
      words.push(<TextCut key={delSection.id} cutSection={delSection} />, ' ')
      continue;
    }

    if (props.paragraph.deletedWords.has(word)) {
      continue;
    }

    words.push(<Word key={word.id} word={word} />, ' ')
  }

  // just delete the last space from array so it does not get rendered
  words.pop();

  return (
    <Container>
      {words}
    </Container>
  )
}

export default observer(Paragraph)

const Container = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`