import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IParagraph, IParagraphWord } from '@models/Paragraph';
import { Tooltip } from 'react-tippy';
import { formatToMMSS } from '@util/string';
import { darken } from 'polished';

interface WordProps {
  word: IParagraphWord;
  paragraph: IParagraph;
  hasTimestamp: boolean;
  matched?: {startIndex: number, endIndex: number};
  isMatchedSelected: boolean;
}

const Popover = Tooltip as any;

function Word(props: WordProps) {
  const ref = useRef<HTMLDivElement>(null);

  const [isOpen, toggleOpen] = useState(false);
  const [tooltipDisabled, toggleDisabled] = useState(false)

  useEffect(() => {
    function updateBounds() {
      if (ref.current) {
        props.paragraph.setTimestampWordBounds(props.word, {offsetTop: ref.current!.offsetTop})
      }
    }

    if (props.hasTimestamp && ref.current) {
      window.addEventListener('resize', updateBounds)
      updateBounds()
    }

    return () => {
      if (props.hasTimestamp) {
        window.addEventListener('resize', updateBounds)
      }
    }
  }, [ref, props.paragraph, props.hasTimestamp, props.word]);


  useEffect(() => {
    function onScroll() {
      if (!tooltipDisabled) {
        toggleDisabled(true)
        setTimeout(() => toggleDisabled(false), 1000)
      }
    }

    if (isOpen) {
      document.addEventListener('transcript-editor-scroll', onScroll)
    }

    return () => {
      if (isOpen) {
        document.removeEventListener('transcript-editor-scroll', onScroll)
      }
    };
  }, [toggleDisabled, isOpen, tooltipDisabled]);


  let timestampBg: JSX.Element | null = null;
  let tooltipContent = <TooltipContent><span>{formatToMMSS(props.word.startTimestamp - props.paragraph.recordingStartTimestamp)}</span>|<FontAwesomeIcon onClick={() => props.paragraph.createTimestamp(props.word)} icon='tag' /></TooltipContent>

  if (props.hasTimestamp) {
    timestampBg = <TimestampBackground />
    tooltipContent = <TooltipContent noIcon><span>{formatToMMSS(props.word.startTimestamp - props.paragraph.recordingStartTimestamp)}</span></TooltipContent>
  }

  let text: JSX.Element = <span>{props.word.text}</span>

  if (props.matched) {
    let style: React.CSSProperties = { backgroundColor: '#c8d7ff' }
    if (props.isMatchedSelected) {
      style = { backgroundColor: '#0047ff', color: 'white'}
    }

    text = <span>{props.word.text.substring(0, props.matched.startIndex)}<span style={style}>{props.word.text.substring(props.matched.startIndex, props.matched.endIndex + 1)}</span>{props.word.text.substring(props.matched.endIndex + 1)}</span>
  }

  return (
    <Popover
      theme='light'
      arrow
      disabled={tooltipDisabled}
      interactive
      delay={400}
      onShow={() => toggleOpen(true)}
      onHidden={() => toggleOpen(false)}
      html={tooltipContent}>
      {timestampBg ? (
        <TimestampContainer ref={ref}>
          {text}
          {timestampBg}
        </TimestampContainer>
      ) : text}
    </Popover>
  );
}

export default observer(Word);

const TimestampBackground = styled.div`
  position: absolute;
  width: 111%;
  height: 110%;
  background: #fee295;
  top: -5%;
  left: -5.5%;
  z-index: 1;
  pointer-events: none;
`

const TooltipContent = styled.div<{noIcon?: boolean}>`
  display: flex;
  align-items: center;
  color: #f3f6f9;
  
  span {
    margin-right: 9px;
    color: #949ba3;
    font-size: 14px;
    font-weight: 700;
    
    ${(props) => props.noIcon && css`
      margin-right: 0;
    `}
  }
  
  svg {
    color: #feca45;
    margin-left: 4px;
    font-size: 11px;
    font-weight: 400;
    cursor: pointer;
    
    &:hover {
      color: ${() => darken(0.15, '#feca45')}
    }
  }
`

const TimestampContainer = styled.span`
  position: relative;
  
  span {
    position: relative;
    z-index: 2;
  }
`