import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { lighten } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ISession } from '@models/Session';
import { replaceAll } from '@util/string';
import DeletePopup from '@components/ui/DeletePopup';
import { useStore } from '@stores/root';

interface SessionListItemProps {
  session: ISession;
}

function SessionListItem(props: SessionListItemProps) {
  const store = useStore();
  const [archiveVisible, setArchiveVisible] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const history = useHistory();
  const joinLink = `/sessions/session/${props.session.id}/join`;

  const statusColors = {
    'IN_PROGRESS': ['#1258ab', '#d9e4f1'],
    'CREATED': ['#c78f01', '#ffe8ae'],
    'FINISHED': ['#0a6c5b', '#cbede7'],
    'UPLOADING': ['#d0891e', '#f5e8d4'],
    'PROCESSING': ['#d0891e', '#f5e8d4']
  }

  const statusColor = statusColors[props.session.combinedState];

  function openRecordings() {
    history.push(`/sessions/session/${props.session.id}/recordings`)
  }

  function joinSession() {
    history.push(joinLink)
  }

  return (
    <Container onMouseEnter={() => setArchiveVisible(true)} onMouseLeave={() => setArchiveVisible(false)}>
      <NameContainer>
        <Name>{props.session.name}</Name>
        <DeleteContainer>
          <DeletePopup toggleIsVisible={(toggle) => setIsPopupVisible(toggle)} onClick={() => store.sessionStore.archiveSession(props.session.id)} text='Do you want to archive this session?'>
            {(archiveVisible || isPopupVisible) &&
            <FontAwesomeIcon style={{
              color: '#949ba3',
              fontSize: '15px',
            }} icon='trash' />
            }
          </DeletePopup>
        </DeleteContainer>
      </NameContainer>
      <InfoContainer>
        <Date>{props.session.dateCreated.fromNow()}</Date>
        <Status color={statusColor[0]} background={statusColor[1]}>{replaceAll(props.session.combinedState, '_', ' ')}</Status>
      </InfoContainer>
      <Buttons>
        {props.session.hasRecordings &&
          <SessionButton onClick={openRecordings}>
            <FontAwesomeIcon icon='film-alt' />
            Recordings
          </SessionButton>
          }
        

        <SessionButton primary onClick={joinSession}>
          <FontAwesomeIcon icon='sign-in-alt' />
          Join
        </SessionButton>

      </Buttons>
    </Container>
  );
}

export default observer(SessionListItem);

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
  margin-right: 67px;
  margin-left: 25px;
  
  > *:first-child {
    margin-right: 15px;
  }
`

const Date = styled.p`
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  line-height: 26.34px;
`

const NameContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`

const DeleteContainer = styled.div`
  width: 15px;
  margin-left: 15px;
`

const Name = styled.p`
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  line-height: 26.34px;
  cursor: default;
  word-break: break-word;
`;

const Status = styled.div<{background: string; color: string;}>`
  color: ${(props) => props.color};
  background-color: ${(props) => props.background};
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 6px 13px;
  border-radius: 28px;
  user-select: none;
 
`

const Buttons = styled.div`
  display: flex;
  align-items: center;
  min-width: 258px;
  justify-content: flex-end;
  
  > *:first-child {
    margin-right: 13px;
  }
  
  > *:last-child {
    margin-right: 0;
  }
`

const SessionButton = styled.div<{primary?: boolean}>`
  text-decoration: none;
  border-radius: 12px;
  background-color: ${(props) => props.primary ? '#feca45' : '#e2e8ee'};
  padding: 8px 11px;
  color: #000000;
  font-size: 14px;
  font-weight: 700;
  user-select: none;
  cursor: pointer;
  transition: 0.1s background-color, 0.1s color;
  width: 120px;
  text-align: center;
  
  svg {
    font-size: 15px;
    font-weight: 400;
    line-height: 15px;
    margin-right: 8px;
  }
  
  &:hover {
    background-color: ${(props) => props.primary ? lighten(0.1, '#feca45') : 'black'};
    color: ${(props) => props.primary ? 'black' : 'white'};
  }
`

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 23px;
  padding-bottom: 23px;
  text-decoration: none;
`;