import { observer } from 'mobx-react-lite';
import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';

interface ILocalDisabledProps {}


function LocalDisabled(prosp: ILocalDisabledProps) {
  const location = useLocation();
  const show = location.pathname.startsWith('/sessions') ? true : false;

  if (!show) {
    return null;
  }

  return (
    <Container href='https://www.getwelder.com/blog/we-decided-to-terminate-local-recording'>
      <p><i>Protect your files!</i> We no longer support the local recording. You can still access your previously recorded files until 2022 March 15. After that, they will be deleted. Try Welder Studio instead!</p>
    </Container>
  )
}

export default observer(LocalDisabled)

const Container = styled.a`
  border-radius: 12px;
  background: rgba(254, 69, 69, 0.2);
  padding: 11px;
  color: #FE4545;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;

  margin-right: 10px;
  max-width: 780px;
  line-height: 16px;


  &:hover {
    background-color: #FE4545;
    color: white;
  }
`
