import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components/macro';
import RecordingProcessing from '@assets/recording-processing-placeholder@2x.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import copyToClipboard from '@util/copyToClipboard';
import { IRecordingGroup } from '@models/RecordingGroup';
import { toast } from 'react-toastify';
import moment from 'moment';

interface RecordingItemProps {
  group: IRecordingGroup;
  isServerSide: boolean;
}

function RecordingItem(props: RecordingItemProps) {
  let processingText = 'No files yet';
  let info: JSX.Element | null = null;
  const videoRef = useRef< HTMLVideoElement | null>(null)

  function copyToClipboardButton(text: string) {
    const res = copyToClipboard(text);
    if (res) {
      toast.info('Link copied. Send it to your guest so they can continue the uploading.')
    } else {
      toast.warn(`Could not copy link.`)
    }
  }

  function videoMouseEnter() {
    if (videoRef.current) {
      videoRef.current!.play()
    }
  }

  function videoMouseLeave() {
    if (videoRef.current) {
      videoRef.current!.pause()
    }
  }

  if (props.group.isProcessing) {

    processingText = 'Files are being processed'
    info = (
      <UploadProgress>
        <p>Processing</p>
      </UploadProgress>
    )
  }

  if (props.group.isUploading) {
    processingText = 'Files are being uploaded';

    info = (
      <UploadProgress>
        <p>Uploading - {props.group.uploadPercent?.toFixed(0)}%</p>
        <UploadProgressbar>
          <div style={{width: `${props.group.uploadPercent}%`}} />
        </UploadProgressbar>
      </UploadProgress>
    )
  }

  let lengthElement = props.group.lengthSeconds ? (
    <LengthContainer>
      <p>{moment.utc(props.group.lengthSeconds).format('HH:mm:ss')}</p>
    </LengthContainer>
  ) : null

  const recordings = props.group.recordings
    .filter(item => item.isFinal)
    .sort((a, b) => (a.isVideo === b.isVideo)? 0 : b.isVideo ? 1 : -1)

  let buttons = recordings.map(item => {
      let text = 'Video';

      if (item.isAudio){
        text = 'Audio'
      }

      if (item.isAudio && !props.isServerSide) {
        text = 'Audio only'
      }

      return (
        <DownloadButton key={item.id} href={item.downloadUrl!}>
          <p>{text}</p>
          <div>
            <FontAwesomeIcon icon='arrow-down' />
          </div>
        </DownloadButton>
      )
    })

  let processingButton = props.group.isUploading ?
    <DownloadButton onClick={() => copyToClipboardButton('https://web.getwelder.com/uploader')}>
      <p>Guest left? Copy upload link</p>
      <div>
        <FontAwesomeIcon icon='link' />
      </div>
    </DownloadButton> :
    <DownloadButton lightText>
      <p>{processingText}</p>
    </DownloadButton>

  return (
    <Container>
      {props.isServerSide &&
        <ServerSideBanner>
          <FontAwesomeIcon icon='save' />
          <p>Serverside</p>
        </ServerSideBanner>
      }
      <ImageContainer>
        {props.group.thumbnail_url ?
          <Video onMouseEnter={videoMouseEnter} onMouseLeave={videoMouseLeave} ref={videoRef} muted>
            <source type='video/mp4' src={props.group.thumbnail_url} />
          </Video>
        : <img alt='Placeholder' src={RecordingProcessing} width='100%' height='100%' />
        }
        {info}
        {lengthElement}
      </ImageContainer>
      <ContentContainer>
        <RecordingName>{props.group.isScreenShare ? `${props.group.fullName} Screen Share` : props.group.fullName}</RecordingName>
        <DownloadControls>
          {props.group.showFiles ?
            <React.Fragment>
              {buttons}
            </React.Fragment>
          :
            processingButton
          }
        </DownloadControls>
      </ContentContainer>
    </Container>
  );
}

export default observer(RecordingItem);

const Container = styled.div`
  box-shadow: 0 15px 44px rgba(219, 223, 227, 0.7);
  border-radius: 14px;
  background-color: #ffffff;
  max-width: 320px;
  overflow: hidden;
  position: relative;
`;

const ServerSideBanner = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 90;
  
  height: 29px;
  border-radius: 28px;
  background-color: #f0d9b5;
  padding: 6px 12px;
  
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  svg, p {
    color: #d0891e;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
  }
  
  svg {
    font-size: 12px;
    font-weight: 400;
    margin-right: 6px;
  }
  
  p {
    font-size: 12px;
    font-weight: 700;
  }
`

const Video = styled.video`
  object-fit: cover;
  height: 100%;
  width: 100%;
`

const ImageContainer = styled.div`
  height: 200px;
  width: 100%;
  position: relative;
  min-width: 320px;
`

const ContentContainer = styled.div`
  margin: 14px 17px 22px 17px;
`

const RecordingName = styled.p`
  color: #000000;
  font-size: 16px;
  font-weight: 600;
`

const DownloadControls = styled.div`
  display: flex;
  align-items: center;
  margin-top: 14px;
  
  > *:last-child {
    margin-left: 10px;
  }
  
  > *:first-child {
    margin-left: 0px;
  }
`

const DownloadButton = styled.a<{lightText?: boolean}>`
  text-decoration: none;
  user-select: none;
  border-radius: 12px;
  background-color: #e2e8ee;
  display: flex;
  align-items: center;
  cursor: initial;
  transition: 0.1s background-color;
  
  p {
    color: ${props => props.lightText ? '#949ba3' : '#000000'};
    font-size: 14px;
    font-weight: 700;
    padding: 7px 14px 7px 14px;
    transition: 0.1s color;
  }
  
  div {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    background-color: #d9dfe5;
    padding: 8px 10px;
    transition: 0.1s background-color;
  }
  
  svg {
    color: #000000;
    font-size: 13px;
    font-weight: 400;
    transition: 0.1s color;
  }
  
  ${props => !props.lightText && css`
    cursor: pointer;
    
    &:hover {
      background-color: black;
 
      p, svg {
        color: white;
      }
      
      div {
        color: white;
        background-color: black;
      }
    }
  `}
`

const UploadProgress = styled.div`
  position: relative;
  bottom: 23px;
  z-index: 999;
  user-select: none;
  
  p {
    position: relative;
    bottom: 7px;
    text-align: center;
    color: #feca45;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
  }
`

const UploadProgressbar = styled.div`
  background-color: #fefaf0;
  height: 6px;
  width: 100%;
  overflow: hidden;
  
  div {
    height: 6px;
    width: 50%;
    background-image: linear-gradient(90deg, #feca45 0%, #ffe39b 100%);
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    transition: width 0.2s;
  }
`

const LengthContainer = styled.div`
  position: absolute;
  left: 10px;
  top: 10px;
  background: black;
  border-radius: 14px;
  
  p {
    font-weight: 700;
    font-size: 12px;
    color: white;
    padding: 5px;
    line-height: 12px;
  }
`


