import React, { useCallback, useRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import Paragraph from '@components/ui/transcript/Paragraph';
import { ISessionRecordingInfo } from '@models/SessionRecordingInfo';
import Card from '@components/ui/Card';
import Timeline from '@components/ui/transcript/Timeline';
import { ITranscript } from '@models/Transcript';
import { ITimestamp } from '@models/Timestamp';

interface TranscriptEditorProps {
  recordingInfo: ISessionRecordingInfo;
  transcript: ITranscript;
  recordingLength: number;
  recordingStartTimestamp: number;
  name: string;
}

function TranscriptEditor(props: TranscriptEditorProps) {
  const ref = useRef<HTMLDivElement>(null);
  const paraRef = useRef<HTMLDivElement>(null);

  let paragraphs = props.recordingInfo.transcript.paragraphs.map((item) => <Paragraph key={item.id} timestamps={props.recordingInfo.timestamps} transcript={props.recordingInfo.transcript} paragraph={item} />)

  const onCardScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      if (paraRef.current) {
        const scrollTop = e.currentTarget.scrollTop
        const scrollEvent = new CustomEvent('transcript-editor-scroll', { detail: { current: scrollTop, total: e.currentTarget.scrollHeight } })
        document.dispatchEvent(scrollEvent)
      }
    },
    [paraRef]
  );

  useEffect(() => {
    if (ref.current) {
      props.transcript.setTranscriptEditorNode(ref.current)
    }
  }, [ref, props.transcript]);


  const onLineClick = useCallback(
    (percentage: number) => {
      if (ref.current && paraRef.current) {
        const height = ref.current.scrollHeight;
        ref.current.scrollTo({
          top: height * percentage,
          behavior: 'smooth'
        })
      }
    },
    [ref, paraRef]
  );

  const onStampClick = useCallback((stamp: ITimestamp) => {
    const word = stamp.word;
    const dimensions = props.transcript.timestampWordBounds.get(word);
    if (ref.current && dimensions) {
      const y = dimensions.offsetTop - ref.current.offsetTop - ref.current.offsetHeight / 2;
      ref.current.scrollTo({
        top: y,
        behavior: 'smooth'
      })
    }
  }, [ref, props.transcript])

  return (
    <Container>
      <ScrollCard innerRef={ref} onScroll={onCardScroll} padding='35px'>
        <CardContent>
          <Header>
            <div />
            <h2>{props.name}</h2>
          </Header>
          <ContentContainer ref={paraRef}>
            {paragraphs}
          </ContentContainer>
        </CardContent>
      </ScrollCard>
      <Timeline cardRef={ref} onStampClick={onStampClick} recordingInfo={props.recordingInfo} onLineClick={onLineClick} recordingLength={props.recordingLength} recordingStartTimestamp={props.recordingStartTimestamp} />
    </Container>
  );
}

export default observer(TranscriptEditor);

const Container = styled.div`
  max-height: 80vh;
  position: relative;
`

const CardContent = styled.div`
  width: 100%;
`

const ScrollCard = styled(Card)`
  overflow-y: scroll;
  max-height: 80vh;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`

const ContentContainer = styled.div`
  width: 100%;
`

const Header = styled.div`
  display: flex;
  margin-bottom: 40px;
  
  > *:first-child {
    width: 15%;
    margin-right: 35px;
  }
  
  > *:last-child {
    flex: 1;
  }
  
  h2 {
    color: #000000;
    font-size: 26px;
    font-weight: 700;
    line-height: 42.8px;
    text-align: left;
  }
`