import React, { Fragment } from 'react';
import { observer } from 'mobx-react-lite';
import history from '@util/history';

import { Switch, Route } from 'react-router-dom';

import { Redirect, Router as DefaultRouter } from 'react-router';

import SidebarLayout from '@components/layout/SidebarLayout';
import SessionNew from '@components/containers/session/SessionNew';
import SessionJoin from '@components/containers/session/SessionJoin';
import Recordings from '@components/containers/session/Recordings';
import LogoLayout from '@components/layout/LogoLayout';
import Register from '@components/containers/auth/Register';
import ExtraInfo from '@components/containers/account/ExtraInfo';
import Login from '@components/containers/auth/Login';
import PrivateRoute from '@util/PrivateRoute';
import Help from '@components/containers/Help';
import Pricing from '@components/containers/Pricing';
import ForgotPassword from '@components/containers/auth/ForgotPassword';
import SetPassword from '@components/containers/auth/SetPassword';
import Walkthrough from '@components/containers/account/Walkthrough';
import TermsOfService from '@components/containers/auth/TermsOfService';
import PrivacyPolicy from '@components/containers/auth/PrivacyPolicy';
import Transcriptions from '@components/containers/transcription/Transcriptions';
import SessionTranscript from '@components/containers/session/SessionTranscript';
import StandaloneTranscript from '@components/containers/transcription/StandaloneTranscript';
import Studios from '@components/containers/studio/Studios';
import Editor from '@components/containers/editor/Editor';
import FullLayout from '@components/layout/FullLayout';
import LocalRecording from './LocalRecording';

interface RouterProps {}

function Router(props: RouterProps) {
  return (
    <Fragment>
      <DefaultRouter history={history}>
        <Switch>
          <Route exact path="/auth/register">
            <LogoLayout>
              <Register />
            </LogoLayout>
          </Route>
          <Route exact path="/auth/login">
            <LogoLayout>
              <Login />
            </LogoLayout>
          </Route>
          <Route exact path="/terms-of-service">
            <LogoLayout>
              <TermsOfService />
            </LogoLayout>
          </Route>
          <Route exact path="/privacy-policy">
            <LogoLayout>
              <PrivacyPolicy />
            </LogoLayout>
          </Route>
          <Route exact path="/auth/forgot-password">
            <LogoLayout>
              <ForgotPassword />
            </LogoLayout>
          </Route>
          <Route exact path="/auth/set-password">
            <LogoLayout>
              <SetPassword />
            </LogoLayout>
          </Route>
          <PrivateRoute exact path="/org/pricing">
            <SidebarLayout>
              <Pricing />
            </SidebarLayout>
          </PrivateRoute>
          <PrivateRoute exact path="/account/extra-info">
            <LogoLayout>
              <ExtraInfo />
            </LogoLayout>
          </PrivateRoute>
          <PrivateRoute exact path="/account/walkthrough">
            <LogoLayout>
              <Walkthrough />
            </LogoLayout>
          </PrivateRoute>
          <PrivateRoute exact path='/studios'>
            <SidebarLayout>
              <Studios />
            </SidebarLayout>
          </PrivateRoute>
          <PrivateRoute exact path="/sessions/session/:session_id/transcript/:session_recording_id">
            <SidebarLayout>
              <SessionTranscript />
            </SidebarLayout>
          </PrivateRoute>
          <PrivateRoute exact path="/sessions/session/:session_id/recordings">
            <SidebarLayout>
              <Recordings />
            </SidebarLayout>
          </PrivateRoute>
          <PrivateRoute exact path="/sessions/session/:session_id/join">
            <SidebarLayout>
              <SessionJoin />
            </SidebarLayout>
          </PrivateRoute>
          <PrivateRoute exact path="/transcriptions">
            <SidebarLayout>
              <Transcriptions />
            </SidebarLayout>
          </PrivateRoute>
          <PrivateRoute exact path="/transcriptions/:standalone_transcript_id">
            <SidebarLayout>
              <StandaloneTranscript />
            </SidebarLayout>
          </PrivateRoute>
          <PrivateRoute exact path="/sessions/new/:session_type">
            <SidebarLayout>
              <SessionNew />
            </SidebarLayout>
          </PrivateRoute>
          <PrivateRoute exact path="/sessions">
            <SidebarLayout>
              <LocalRecording />
            </SidebarLayout>
          </PrivateRoute>
          <PrivateRoute exact path="/help">
            <SidebarLayout>
              <Help />
            </SidebarLayout>
          </PrivateRoute>
          <PrivateRoute exact path='/editor/:editor_id'>
            <FullLayout>
              <Editor />
            </FullLayout>
          </PrivateRoute>  
          <Redirect from='/' to='/transcriptions' />
        </Switch>
      </DefaultRouter>
    </Fragment>
  );
}

export default observer(Router);
