import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lighten } from 'polished';
import { ITranscript } from '@models/Transcript';

interface SearchProps {
  transcript: ITranscript;
}

function Search(props: SearchProps) {
  const [showButton, toggleShow] = useState(false)

  useEffect(() => {
    toggleShow(!!props.transcript.searchString)
  }, [props.transcript.searchString]);

  const handleSearch = useCallback((event: any) => {
      const value = event.target.value
      setTimeout(() => {
        props.transcript.setSearch(value)
      }, 50)
    },
    [props.transcript]
  );

  const handleSkip = useCallback((event: any) => {
    if (event.key === 'Enter') {
      props.transcript.jumpToNextSearch()
    }
  }, [props.transcript]);


  return (
    <Container>
      <InputContainer>
        <FontAwesomeIcon icon='search' />
        <Input onKeyDown={handleSkip} value={props.transcript.searchString} onChange={handleSearch} placeholder={'Search...'} type='text' />
        <SearchButton show={showButton}>
          <FontAwesomeIcon icon='arrow-right' />
        </SearchButton>
      </InputContainer>
      <SearchMatches show={props.transcript.totalSearchMatches > 0}>{props.transcript.currentSearchMatch} / {props.transcript.totalSearchMatches}</SearchMatches>
    </Container>
  );
}

export default observer(Search);

const Container = styled.div`
  margin-right: 10px;
  position: relative;
`

const SearchMatches = styled.div<{show: boolean}>`
  color: #bdc5ce;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 3px;
  text-align: right;
  margin-right: 10px;
  opacity: ${(props) => props.show ? 1 : 0};
  position: absolute;
  right: 0px;
`

const InputContainer = styled.div`
  position: relative;
  
  > svg {
    color: #000000;
    font-size: 15px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 15px;
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 12px;
  }
`

const Input = styled.input`
  border-radius: 14px;
  border: 2px solid #d7dfe8;
  outline: none;
  color: #000000;
  font-size: 14px;
  font-weight: 600;
  height: 38px;
  background: transparent;
  padding-left: 32px;
  padding-right: 39px;

  &::placeholder {
    color: #d7dfe8;
  }
`

const SearchButton = styled.div<{show: boolean}>`
  width: 34px;
  height: 28px;
  box-shadow: 0 11px 41px rgba(188, 160, 89, 0.4);
  border-radius: 9px;
  background-image: linear-gradient(90deg, #ffd260 0%, #feca45 100%);
  position: absolute;
  
  display: flex;
  justify-content: center;
  align-items: center;

  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  
  opacity: ${(props) => props.show ? 1 : 0};
  transition: 0.1s opacity;
  
  svg {
    color: #000000;
    font-size: 15px;
    font-weight: 400;
  }
  
  &:hover {
    cursor: pointer;
    background-image: linear-gradient(90deg, ${() => lighten(0.05, '#ffd260')} 0%, ${() => lighten(0.05, '#feca45')} 100%);
  }
`