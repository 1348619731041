import React, { Fragment } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Tooltip,
} from 'react-tippy';
import { ISessionSettings } from '@models/Session';
import { lighten } from 'polished';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Toggle from '@components/ui/Toggle';

interface SessionSettingsProps {
  settings: ISessionSettings;
  onChange: (newSettings: ISessionSettings) => void;
}

interface ISessionItemInfo {
  text: string;
  tooltip?: React.ReactNode;
  premiumRedirect: boolean;
}

function SessionSettings(props: SessionSettingsProps) {
  function onChange(name: keyof ISessionSettings, value: boolean) {
    props.onChange({ [name]: value } as any)
  }

  const SessionSettingsInfo: { [key in keyof ISessionSettings]: ISessionItemInfo } = {
    enableAudioPostProcessing: {
      text: 'Echo cancellation',
      tooltip: <Fragment>Leave echo cancellation off to get the best sound quality <b>BUT</b> we highly recommend using headphones</Fragment>,
      premiumRedirect: false
    }
  }

  const items = Object.entries(props.settings).map(item => {
    const key = item[0] as keyof ISessionSettings;
    const info = SessionSettingsInfo[key];
    return <SessionSettingItem key={key} premiumRedirect={info.premiumRedirect} text={info.text} tooltip={info.tooltip} name={key} value={item[1]} onChange={onChange} />
  })

  return (
    <Container>
      <Headline>Preferences</Headline>
      <SettingsContainer>
        {items}
      </SettingsContainer>
    </Container>
  );
}

export default observer(SessionSettings);

interface SessionSettingsItemProps {
  name: keyof ISessionSettings;
  value: boolean;
  onChange: (name: keyof ISessionSettings, value: boolean) => void;
  text: string;
  tooltip?: React.ReactNode;
  premiumRedirect: boolean;
}

function SessionSettingItem(props: SessionSettingsItemProps) {
  const active = props.value;
  const history = useHistory();

  function onClick() {
    if (props.premiumRedirect) {
      history.push('/org/pricing')
      return;
    }

    props.onChange(props.name, !active)
  }

  return (
    <SessionItem onClick={onClick}>
      <Toggle active={active} />
      <Text>
        {props.text}
      </Text>
      {props.tooltip &&
        <Tooltip
          html={(
            <p style={{ maxWidth: 310, textAlign: 'left' }}>{props.tooltip}</p>
          )}
          animateFill={false}
          position='right'
        >
          <FontAwesomeIcon icon='question-circle' />
        </Tooltip>
      }
      {props.premiumRedirect &&
        <PremiumRedirect to='/org/pricing'>get business plan</PremiumRedirect>
      }
    </SessionItem>
  )
}

const Container = styled.div`
  margin-top: 25px;
`;

const Headline = styled.h2`
  color: #949ba3;
  font-size: 18px;
  font-weight: 600;
  line-height: 29.63px;
  margin-bottom: 14px;
`

const SettingsContainer = styled.div`
   > * {
    margin-bottom: 20px;
   }
   
   > *:last-child {
    margin-bottom: 0;
   }
`

const SessionItem = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  
   svg {
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
  }
`

const Text = styled.p`
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  line-height: 26.34px;
  margin-left: 10px;
  margin-right: 7px;
`

const PremiumRedirect = styled(Link)`
  border-radius: 28px;
  background-color: #cbede7;
  padding: 6px 12px;
  color: #0a6c5b;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  margin-left: 12px;
  
  &:hover {
    color: ${() => lighten(0.1, '#0a6c5b')}
  }
`