import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components/macro';
import StudioListItem from '@components/ui/studio/StudioListItem';
import { useStore } from '@stores/root';

interface StudioListProps {}

function StudioList(props: StudioListProps) {
  const store = useStore();

  const items = store.studioStore.broadcastList.map(item => <StudioListItem key={item.id} broadcast={item} />)

  return (
    <Container>
      <Headline>Your Sessions</Headline>
      <Sessions>
        {items}
      </Sessions>
    </Container>
  )
}

export default observer(StudioList)

const Container = styled.div`
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
`;

const Headline = styled.div`
  color: #949ba3;
  font-size: 18px;
  font-weight: 800;
  line-height: 29.63px;
`

const Sessions = styled.div`
  > * {
    border-bottom: 1px solid #e4ebf3;
  }
  
  > *:last-child {
    border-bottom: 0;
  }
`