import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';

interface FullLayoutProps {
  children: React.ReactNode;
}

function FullLayout(props: FullLayoutProps) {
  return (
    <Container>
      {props.children}
    </Container>
  );
}

export default observer(FullLayout);

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;
`;