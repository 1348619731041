import { types } from 'mobx-state-tree';

export default types.model('BillingPlan', {
  id: types.identifier,
  state: types.string,
  canChange: types.boolean,
  isUnlimited: types.optional(types.boolean, false),
  basePlan: types.model('BillingPlanBasePlan', {
    name: types.string,
    slug: types.union(types.literal('basic'), types.literal('professional'), types.literal('free'), types.literal('standard-unlimited-monthly'), types.literal('standard-unlimited-yearly'))
  }),
  subscription: types.maybeNull(types.model('BillingPlanSubscription', {
    id: types.identifierNumber,
    state: types.string
  }))
}).views(self => ({
  get isPremium() {
    return !!self.subscription
  }
}))
