import { useCallback, useLayoutEffect, useState } from 'react'

type ClientRect = Record<keyof Omit<DOMRect, "toJSON">, number>

function roundValues(_rect: ClientRect) {
  const rect = {
    ..._rect
  }
  for (const key of Object.keys(rect)) {
    // @ts-ignore
    rect[key] = Math.round(rect[key])
  }
  return rect
}

function shallowDiff(prev: any, next: any) {
  if (prev != null && next != null) {
    for (const key of Object.keys(next)) {
      if (prev[key] !== next[key]) {
        return true
      }
    }
  } else if (prev !== next) {
    return true
  }
  return false
}

/**
 * useTextSelection(ref)
 * 
 * @description
 * hook to get information about the current text selection
 * 
 */
export function useTextSelection(target: HTMLElement | null, mount: HTMLElement | null) {
  const [clientRect, setRect] = useState<ClientRect>();
  const [mountRect, setMountRect] = useState<ClientRect>();
  const [isCollapsed, setIsCollapsed] = useState<boolean>()
  const [textContent, setText] = useState<string>();
  const [selectionNodes, setSelectionNodes] = useState<{anchorNode: HTMLElement; focusNode: HTMLElement}>();

  const reset = useCallback((deleteSelection = true) => {
    setRect(undefined)
    setIsCollapsed(undefined)
    setText(undefined)
    setSelectionNodes(undefined);

    const selection = window.getSelection();
    if (selection !== null && deleteSelection) {
      selection.removeAllRanges()
    }
  }, [])

  const handler = useCallback(() => {
    if (!mount) {
      reset();
      return;
    }

    const selection = window.getSelection()

    if (selection == null || !selection.rangeCount) {
      reset()
      return
    } 

    const range = selection.getRangeAt(0)

    if (target != null && !target.contains(range.commonAncestorContainer)) {
      reset(false)
      return
    }

    if (range == null) {
      reset()
      return
    }

    const contents = range.cloneContents()

    if (contents.textContent != null) setText(contents.textContent)

    const rangeRect = range.getBoundingClientRect();
    const mountRect = roundValues(mount.getBoundingClientRect().toJSON())
    const mountTop = mount.scrollTop;

    let finalRect = {
      top: rangeRect.top - mountRect.top + mountTop,
      left: rangeRect.left - mountRect.left,
      bottom: rangeRect.bottom - mountRect.bottom,
      right: rangeRect.right - mountRect.right,
      width: rangeRect.width,
      height: rangeRect.height,
      x: rangeRect.x - mountRect.x,
      y: rangeRect.y - mountRect.y
    }

    setRect(oldRect => {
      if (shallowDiff(oldRect, finalRect)) {
        return finalRect
      }
      return oldRect
    })

    setMountRect(oldRect => {
      if (shallowDiff(oldRect, mountRect)) {
        return mountRect
      }
      return oldRect
    })

    setIsCollapsed(range.collapsed)
    
    setSelectionNodes({
      anchorNode: getNodeWithDataset(selection.anchorNode as HTMLElement),
      focusNode: getNodeWithDataset(selection.focusNode as HTMLElement)
    })
  }, [target, reset, mount])

  useLayoutEffect(() => {
    document.addEventListener('selectionchange', handler);
    document.addEventListener('keydown', handler);
    document.addEventListener('keyup', handler);
    window.addEventListener('resize', handler);
  
    return () => {
      document.removeEventListener('selectionchange', handler);
      document.removeEventListener('keydown', handler);
      document.removeEventListener('keyup', handler);
      window.removeEventListener('resize', handler);
    }
  }, [target, handler, mount])

  return {
    clientRect,
    isCollapsed,
    textContent,
    mountRect,
    selectionNodes,
    reset
  }
}


function getNodeWithDataset(node: any): any {
  if (!node) {
    return;
  }

  if (node.dataset?.wordId) {
    return node;
  }

  if (node.textContent === ' ') {
    return node.nextElementSibling! as HTMLElement;
  }

  function deepSearch(node: any, key: string): HTMLElement | undefined {
    if (!node) {
      return;
    }
    if (node.dataset?.wordId) {
      return node;
    } else {
      return deepSearch(node[key], key);
    }
  }

  const downSearch = deepSearch(node, 'lastElementChild');
  if (downSearch) return downSearch;
  const upSearch = deepSearch(node, 'parentElement');
  if (upSearch) return upSearch;
}