export const isEmptyObject = (obj: Object) => Object.keys(obj).length === 0 && obj.constructor === Object

export function closestIndex(num: number, arr: any[]) {
  let curr = arr[0],
    diff = Math.abs(num - curr),
    index = 0;

  for (let val = 0; val < arr.length; val++) {
    let newdiff = Math.abs(num - arr[val]);
    if (newdiff < diff) {
      diff = newdiff;
      curr = arr[val];
      index = val;
    }
  }
  return index;
}