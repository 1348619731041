import React, { useRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import { IParagraph } from '@models/Paragraph';
import { formatToMMSS } from '@util/string';
// @ts-ignore
import { useIsVisible } from 'react-is-visible'
import Word from '@components/ui/transcript/Word';
import { ITranscript } from '@models/Transcript';
import { ITimestamp } from '@models/Timestamp';

interface ParagraphProps {
  paragraph: IParagraph;
  transcript: ITranscript;
  timestamps: ITimestamp[]
}

function Paragraph(props: ParagraphProps) {
  const nodeRef = useRef<HTMLDivElement>(null)
  const isVisible = useIsVisible(nodeRef)

  useEffect(() => {
    if (nodeRef.current) {
      props.paragraph.setNode(nodeRef.current)
    }
  }, [nodeRef, props.paragraph]);

  let text: any = [];

  for (let word of props.paragraph.words) {
    const hasTimestamp = !!props.timestamps.find(item => word === item.word);

    if (isVisible) {
      const matched = props.transcript.matchedWordsMap.get(word);
      const isMatchedSelected = props.transcript.matchedWords[props.transcript.currentSearchMatch - 1]?.word === word;
      text.push(<Word word={word} paragraph={props.paragraph} matched={matched} isMatchedSelected={isMatchedSelected} key={word.id} hasTimestamp={hasTimestamp} />, ' ')
    } else {
      if (hasTimestamp) {
        text.push(<Word word={word} paragraph={props.paragraph} isMatchedSelected={false} key={word.id} hasTimestamp={hasTimestamp} />, ' ')
      } else {
        text.push(word.text, ' ')
      }
    }
  }

  return (
    <Container ref={nodeRef}>
      <LegendColumn>
        <Participant color={props.paragraph.participant.participantColor}>{props.paragraph.participant.name}</Participant>
        <TimeText>{formatToMMSS(props.paragraph.paragraphStartTimeSec)}</TimeText>
      </LegendColumn>
      <ContentColumn>
        <Text>{text}</Text>
      </ContentColumn>
    </Container>
  );
}

export default observer(Paragraph);

const Container = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 30px;
`

const LegendColumn = styled.div`
  width: 15%;
  margin-right: 35px;
  text-align: right;
  user-select: none;
`

const ContentColumn = styled.div`
  flex: 1;
  margin-top: -8px;
`

const Participant = styled.p<{color: string}>`
  color: ${(props) => props.color};
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
`

const Text = styled.div`
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 29.6px;
  text-align: left;
`

const TimeText  = styled.p`
  color: #bdc5ce;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 3px;
`