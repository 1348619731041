import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components/macro';
import Button from '@components/ui/Button';
import Editor from '@models/editor/Editor';

interface DownloadButtonsProps {
  editor: Editor;
}

function DownloadButtons(props: DownloadButtonsProps) {
  return (
    <Buttons>
      <SavedText>Saved automatically</SavedText>
      <Button onClick={props.editor.fullExport} text='Export Video' icon='share' colorScheme='primary' reverseIcon />
    </Buttons>
  )
}

export default observer(DownloadButtons)

const Buttons = styled.div`
  display: flex;
  align-items: center;
  
  > *:last-child {
    text-decoration: none;
    margin-left: 36px;
  }

  > * {
    white-space: nowrap;
  }
  
  > *:first-child {
    margin-right: 7px;
  }
`

const SavedText = styled.p`
  color: #949ba3;
  font-size: 14px;
  font-weight: 700;
`