import React, { useEffect, useState } from 'react';
import { useStore } from '@stores/root';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import Card from '@components/ui/Card';
import Input from '@components/ui/form/Input';
import Button from '@components/ui/Button';
import RegisterBackground from '@assets/register-background@2x.png';
import { useForm } from 'react-hook-form';
import QS from 'query-string';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

interface SetPasswordProps {}

type FormData = {
  password: string;
}

function SetPassword(props: SetPasswordProps) {
  const store = useStore();
  const location = useLocation();
  const [token, setToken] = useState<string | null>(null)

  const { register, handleSubmit } = useForm<FormData>();
  const onSubmit = async (data: any) => {
    await store.authStore.setNewPassword(data.password, token!);
  }

  useEffect(() => {
    const query = QS.parse(location.search);
    if (query.token) {
      setToken(query.token as string);
    } else {
      toast.error('Please open this link from the password reset email.')
    }
  }, [location.search])

  return (
    <Container>
      <Content>
        <Headline>Set new password</Headline>
        <Card padding='45px 57px' maxWidth={618}>
          <CardForm onSubmit={handleSubmit(onSubmit)}>
            <Inputs>
              <Input autoFocus ref={register({required: true})} required type='password' placeholder='password' label='Your new password' name='password' />
            </Inputs>
            <Button loading={store.authStore.inProgress} type='submit' text='Set new password' icon='arrow-right' />
          </CardForm>
        </Card>
      </Content>
      <img width={646} src={RegisterBackground} alt='background' />
    </Container>
  );
}

export default observer(SetPassword);

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  
  > img {
    position: absolute;
    right: -80px;
    bottom: -50px;
  }
`;

const Content = styled.div`
  max-width: 618px;
  width: 100%;
  position: relative;
  z-index: 10;
`

const CardForm = styled.form`
  width: 100%
`

const Inputs = styled.div`
  > * {
    margin-bottom: 40px;
  }
`

const Headline = styled.h1`
  color: #000000;
  font-size: 38px;
  font-weight: 400;
  margin-bottom: 33px;
`