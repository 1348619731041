import React, { Fragment, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import PaidPlan from '@components/ui/pricing/PaidPlan';
import BizPlan from '@components/ui/pricing/BizPlan';
import { useStore } from '@stores/root';
import FreeBanner from '@components/ui/pricing/FreeBanner';

interface PricingProps {}

function Pricing(props: PricingProps) {
  const store = useStore();

  useEffect(() => {
    store.accountStore.fetchCurrentPlan();
  }, [store.accountStore]);

  if (!store.accountStore.currentPlan) {
    return null;
  }

  const canChangePlan = store.accountStore.currentPlan.canChange;
  const activePlanSlug = store.accountStore.currentPlan.basePlan.slug;
  const hasPremiumPlan = store.accountStore.currentPlan.isPremium;

  async function planClick(basePlanSlug: string) {
    await store.accountStore.redirectToCheckout(basePlanSlug);

    const { gtag } = window as any;
    gtag('event', 'checkout', {
      event_category: 'engagement',
      event_label: basePlanSlug,
    });
  }

  return (
    <Container>
      <Content>
        {!canChangePlan ? (
          <CannotChangeText>
            You are on a business plan. Please contact us at support@getwelder.com to manage your subscription.
          </CannotChangeText>
        ) : (
          <Fragment>
            <Headline>Stream, record, & transcribe without limits.</Headline>
            <PricingContent>
              <PaidPlan
                planClick={planClick}
                activePlanSlug={activePlanSlug}
                hasPremium={hasPremiumPlan}
                slug="standard-unlimited-monthly"
                name="Everything"
                price="20"
                features={[
                  'Everything from free plan',
                  'Paid monthly',
                  'No watermark',
                ]}
                description="Get rid of all the watermarks."
              />
              <PaidPlan
                yearly
                planClick={planClick}
                activePlanSlug={activePlanSlug}
                hasPremium={hasPremiumPlan}
                slug="standard-unlimited-yearly"
                name="For a year"
                price="200"
                features={[
                  'Everything from free plan',
                  'For a whole year',
                  'No watermark',
                ]}
                description="2 months for free!"
                popular
              />
              <BizPlan />
            </PricingContent>
            <FreeBanner hasPremium={hasPremiumPlan} />
          </Fragment>
        )}
      </Content>
    </Container>
  );
}

export default observer(Pricing);

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  width: 100%;
  max-width: 1100px;
  padding-top: 140px;
  margin: 0 auto;
  margin-bottom: 150px;
`;

const Headline = styled.h1`
  color: #000000;
  font-size: 38px;
  font-weight: 400;
  margin-bottom: 40px;
`;

const PricingContent = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const CannotChangeText = styled.h2`
  color: #000000;
  font-size: 28px;
  font-weight: 400;
  margin-bottom: 40px;
`;
