import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import UppyUpload from '@components/ui/file/UppyUpload';
import TranscriptList from '@components/ui/standaloneTranscript/TranscriptList';
import { useStore } from '@stores/root';

interface TranscriptionsProps {}

function Transcriptions(props: TranscriptionsProps) {
  const store = useStore();

  useEffect(() => {
    store.transcriptStore.loadTranscripts()
  }, [store.transcriptStore]);


  return (
    <Container>
      <h1>Transcribe any file</h1>
      <UploadContainer>
        <UppyUpload />
        {store.transcriptStore.hasTranscripts && (
          <TranscriptList transcriptions={store.transcriptStore.displayTranscripts} />
        )}
      </UploadContainer>
    </Container>
  )
}

export default observer(Transcriptions);

const Container = styled.div`
  max-width: 750px;
  width: 90%;
  margin: 0 auto;
  margin-top: 60px;
  
  h1 {
    color: #000000;
    font-size: 38px;
    font-weight: 400;
    margin: 0;
  }
`;

const UploadContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
`
