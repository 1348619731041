import axios, { AxiosInstance, AxiosTransformer } from 'axios';
import humps from 'humps';
import EventEmitter from 'eventemitter3';

export let BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://api.getwelder.com/v1';

if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_ENVIRONMENT === 'staging') {
  BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://api.staging.getwelder.com/v1';
}

export default class API extends EventEmitter{
  axios: AxiosInstance;
  token: string | undefined;

  constructor() {
    super();

    this.axios = axios.create({
      baseURL: BASE_URL,
      transformRequest: [
        (data) => {
          data = humps.decamelizeKeys(data);
          return data;
        },
        ...(axios.defaults.transformRequest as AxiosTransformer[]),
      ],
      transformResponse: [
        ...(axios.defaults.transformResponse as AxiosTransformer[]),
        (data) => {
          return humps.camelizeKeys(data);
        },
      ],
    });

    this.axios.interceptors.request.use(config => {
      if (this.token) {
        config.headers.Authorization = `Bearer ${this.token}`
      }

      return config;
    });

    this.axios.interceptors.response.use(res => res, (error) => {
      const { response } = error;

      if (response?.status === 401) {
        this.emit('unauthorized.api.access')
      }

      return Promise.reject(error);
    })
  }

  setToken(token: string) {
    this.token = token;
  }

  removeToken() {
    this.token = undefined;
  }

  register(data: any) {
    return this.axios.post('/accounts/users/', data).then(res => res.data);
  }

  login(data: any) {
    return this.axios.post('/accounts/auth_tokens/', data).then(res => res.data);
  }

  googleLogin(googleToken: string, referralToken: string | null) {
    return this.axios.post('/accounts/google-login/', { googleToken, referralToken }).then(res => res.data);
  }

  resetPasswordEmail(email: string) {
    return this.axios.post('/accounts/password-reset-tokens/', { email }).then(res => res.data);
  }

  setNewPassword(password: string, token: string) {
    return this.axios.post(`accounts/password-reset-tokens/${token}/use/`, { password }).then(res => res.data);
  }

  addExtraInfo(data: any) {
    return this.axios.patch(`/accounts/users/me`, { extraInfo: data }).then(res => res.data);
  }

  getCurrentUser() {
    return this.axios.get('/accounts/users/me').then(res => res.data);
  }

  createSession(name: string, settings: any, sessionType: string) {
    return this.axios.post('/calls/sessions/', { name, settings, sessionType }).then(res => res.data);
  }

  fetchSessions(limit?: number, after?: string) {
    return this.axios.get('/calls/sessions/', { params: { limit, after }}).then(res => res.data);
  }

  fetchSession(id: string) {
    return this.axios.get(`/calls/sessions/${id}`).then(res => res.data);
  }
  fetchSessionRecordingTranscript(sessionRecordingId: string) {
    return this.axios.get(`/calls/transcripts/${sessionRecordingId}`).then(res => res.data);
  }

  createTranscriptTimestamp(sessionRecordingId: string, participantId: string, startTimestamp: number, endTimestamp: number) {
    return this.axios.post('/calls/timestamps/', {
      sessionRecordingId,
      participantId,
      start: startTimestamp,
      end: endTimestamp
    }).then(res => res.data);
  }

  createStandaloneTranscript(contentType: string) {
    return this.axios.post('/calls/standalone-transcripts/', { contentType }).then(res => res.data);
  }

  fetchStandaloneTranscript(id: string) {
    return this.axios.get(`/calls/standalone-transcripts/${id}`).then(res => res.data);
  }

  fetchStandaloneTranscripts() {
    return this.axios.get('/calls/standalone-transcripts/').then(res => res.data);
  }

  updateStandaloneTranscript(id: string, data: any) {
    return this.axios.put(`/calls/standalone-transcripts/${id}`, data).then(res => res.data);
  }

  fetchSessionRecordingTimestamps(sessionRecordingId: string) {
    return this.axios.get(`/calls/timestamps/?session_recording_id=${sessionRecordingId}`).then(res => res.data)
  }

  archiveSession(id: string) {
    return this.axios.patch(`/calls/sessions/${id}`, { archived: true }).then(res => res.data);
  }

  getStripeBillingPortalUrl() {
    return this.axios.post('/billing/stripe-billing-portal-sessions/', {}).then(res => res.data);
  }

  getPlanCheckoutSession(basePlanSlug: string) {
    return this.axios.post('/billing/plans/current', { basePlanSlug }).then(res => res.data);
  }

  getCurrentPlan() {
    return this.axios.get('/billing/plans/current').then(res => res.data);
  }

  getCredits() {
    return this.axios.get('/billing/credits/current-balance').then(res => res.data);
  }

  loadStudios() {
    return this.axios.get('/studio/studios/').then(res => res.data);
  }

  loadBroadcasts() {
    return this.axios.get('/studio/broadcasts/').then(res => res.data);
  }

  fetchBroadcast(id: string) {
    return this.axios.get(`/studio/broadcasts/${id}`).then(res => res.data);
  }

  getOrCreateEditorProject(clipId: string) {
    return this.axios.post('/editor/projects/', { clipId }).then(res => res.data);
  }

  getProject(projectId: string) {
    return this.axios.get(`/editor/projects/${projectId}`).then(res => res.data);
  }

  getClip(clipId: string) {
    return this.axios.get(`/clips/clips/${clipId}`).then(res => res.data);
  }

  getTranscript(clipId: string) {
    return this.axios.get(`/clips/transcripts/${clipId}`).then(res => res.data)
  }

  createExport(projectId: string, startIndex: number, endIndex: number) {
    return this.axios.post(`/editor/segments/`, {
      projectId,
      startIndex,
      endIndex,
      segmentType: 'EXPORT'
    }).then(res => res.data);
  }

  createCut(projectId: string, startIndex: number, endIndex: number) {
    return this.axios.post(`/editor/segments/`, {
      projectId,
      startIndex,
      endIndex,
      segmentType: 'CUT_OUT'
    }).then(res => res.data);
  }

  getSegments(projectId: string) {
    return this.axios.get(`/editor/projects/${projectId}/segments`).then(res => res.data);
  }

  revertSegment(segmentId: string) {
    return this.axios.delete(`/editor/segments/${segmentId}`).then(res => res.data);
  }

  createFullExport(projectId: string) {
    return this.axios.post(`/editor/projects/${projectId}/export-clip`).then(res => res.data);
  }
}