import { Instance, types } from 'mobx-state-tree';
import { MomentPrimitive } from '@util/mstTypes';


const ClientJoinUrls = types.model('ClientJoinUrls', {
  guests: types.string,
  host: types.string
})

const Studio = types.model('Studio', {
  id: types.identifier,
  userId: types.string,
  name: types.string,
  slug: types.string,
  createdAt: MomentPrimitive,
  state: types.union(types.literal('IDLE'), types.literal('RECORDING'), types.literal('STREAMING_AND_RECORDING')),
  dashboardUrl: types.string,
  joinUrls: ClientJoinUrls
})

export default Studio;

export interface IStudio extends Instance<typeof Studio> {}