import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Editor from '@models/editor/Editor';
import { observer } from 'mobx-react-lite';
import { darken } from 'polished';
import React from 'react';
import styled from 'styled-components/macro';
import { Tooltip } from 'react-tippy';
import { BASE_URL } from '../../../../api';


interface HeaderProps {
  editor: Editor;
}

function Header(props: HeaderProps) {

  const searchShortcut = navigator.platform.toLowerCase().includes('mac') ? 'CMD + F' : 'CTRL + F'
  return (
    <Container>
      <SearchContainer>
        <Tooltip hideOnClick={false} title={`Use browser search (${searchShortcut}) to find items`}>
          <Icon>
            <FontAwesomeIcon icon='search' />
          </Icon>
        </Tooltip>
      </SearchContainer>
      <Icons>
        <Icon download href={`${BASE_URL}/editor/projects/${props.editor.projectId}/transcript/txt`}>
          <FontAwesomeIcon icon='closed-captioning' />
        </Icon>
        <Icon download href={`${BASE_URL}/editor/projects/${props.editor.projectId}/transcript/srt`}>
          <FontAwesomeIcon icon='file-alt' />
        </Icon>
      </Icons>
    </Container>
  )
}

export default observer(Header)

const Container = styled.div`
  border-bottom: 2px solid #d7dfe8;
  height: 60px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 9px;
`

const Icons = styled.div`
  display: flex;
  align-items: center;

  > *:first-child {
    margin-right: 10px;
  }
`

const Icon = styled.a`
  width: 37px;
  height: 37px;
  border-radius: 12px;
  background-color: #e2e8ee;

  color: #000000;
  font-size: 15px;
  font-weight: 400;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: ${() => darken(0.05, '#e2e8ee')};
  }
`

const SearchContainer = styled.div`

`
