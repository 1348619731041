import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import Card from '@components/ui/Card';

interface HelpProps {}

function Help(props: HelpProps) {
  return (
    <Container>
      <Card maxWidth={834}>
        <CardContent>
          <Headline>Something went wrong?</Headline>
          <Text>Well, it could have. We are doing our best but there might be some issues once in awhile.</Text>
          <Text>If that’s the case, let us help you. Just send us a message at: <b>support@getwelder.com</b></Text>
        </CardContent>
      </Card>
    </Container>
  );
}

export default observer(Help);

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  
  > * {
    margin-bottom: 100px;
  }
`;

const CardContent = styled.div`
  width: 100%;
  
  > *:last-child {
    margin-top: 25px;
  }
`

const Headline = styled.h1`
  color: #000000;
  font-size: 48px;
  font-weight: 400;
  margin-bottom: 26px;
`

const Text = styled.p`
  color: #000000;
  font-size: 18px;
  font-weight: 400;
  line-height: 29.52px;
  
  b {
    font-weight: 700;
  }
`
