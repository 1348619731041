import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import TranscriptListItem from './TranscriptListItem';
import { IStandaloneTranscript } from '@models/StandaloneTranscript';

interface TranscriptListProps {
  transcriptions: IStandaloneTranscript[];
}

function TranscriptList(props: TranscriptListProps) {

  const transcriptions = props.transcriptions.map(item => <TranscriptListItem key={item.id} standaloneTranscript={item} />)

  return (
    <Container>
      <Headline>Transcripts</Headline>
      <Transcriptions>
        {transcriptions}
      </Transcriptions>
    </Container>
  );
}

export default observer(TranscriptList);

const Container = styled.div`
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
  margin-top: 30px;
`;

const Headline = styled.div`
  color: #949ba3;
  font-size: 18px;
  font-weight: 800;
  line-height: 29.63px;
`

const Transcriptions = styled.div`
  > * {
    border-bottom: 1px solid #e4ebf3;
  }
  
  > *:last-child {
    border-bottom: 0;
  }
`
