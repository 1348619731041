import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import LogoBig from '@assets/logo-big.svg';

interface LogoLayoutProps {
  children: React.ReactNode;
}

function LogoLayout(props: LogoLayoutProps) {
  return (
    <Container>
      <Header>
        <img src={LogoBig} alt='Logo' />
      </Header>
      <Content>
        {props.children}
      </Content>
    </Container>
  );
}

export default observer(LogoLayout);

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;
`;

const Header = styled.div`
  position: absolute;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 1385px;
  padding-right: 50px;
  padding-left: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Content = styled.div``