import React  from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import { ThemeProvider } from 'styled-components';
import theme from '@util/theme';
import Router from '@components/containers/Router';
import { ToastContainer } from 'react-toastify';

interface AppProps {}

function App(props: AppProps) {

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <Container>
        <Router />
      </Container>
    </ThemeProvider>
  );
}

export default observer(App);

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: ${(props) => props.theme.background};
`;