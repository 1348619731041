import { toast } from 'react-toastify';

export default class Toaster {
  success(text: string) {
    return toast.success(text)
  }

  error(text: string) {
    return toast.error(text);
  }
}