import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components/macro';
import { lighten } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RingSpinner } from 'react-spinners-kit';
import { IStandaloneTranscript } from '@models/StandaloneTranscript';
import { useHistory } from 'react-router-dom';

interface IProps {
  standaloneTranscript: IStandaloneTranscript;
}

function TranscriptListItem(props: IProps) {
  const history = useHistory();
  const isProcessing = props.standaloneTranscript.isProcessing;

  useEffect(() => {
    let interval: undefined | number;

    if (props.standaloneTranscript.isProcessing) {
      interval = setInterval(() => {
        props.standaloneTranscript.reload()
      }, 2000) as any
    }

    return () => {
      clearInterval(interval)
    };
  }, [props.standaloneTranscript.isProcessing, props.standaloneTranscript]);

  function openTranscript() {
    history.push(`/transcriptions/${props.standaloneTranscript.id}`)
  }

  return (
    <Container>
      <InfoContainer>
        <Date>{props.standaloneTranscript.createdAt.fromNow()}</Date>
        <Name>{props.standaloneTranscript.name}</Name>
      </InfoContainer>
      {isProcessing ?
        (
          <TranscriptButton processing>
            <RingSpinner size={13} color='#0a6c5b' />
            Processing
          </TranscriptButton>
        )
        :
        (
          <TranscriptButton onClick={openTranscript}>
            <FontAwesomeIcon icon='film-alt' />
            Transcription
          </TranscriptButton>
        )
      }

    </Container>
  );
}

export default observer(TranscriptListItem)

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 23px;
  padding-bottom: 23px;
  text-decoration: none;
`

const Date = styled.p`
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  line-height: 26.34px;
  margin-right: 15px;
  min-width: 160px;
`

const Name = styled.p`
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  line-height: 26.34px;
`

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
`

const TranscriptButton = styled.div<{processing?: boolean}>`
  text-decoration: none;
  border-radius: 12px;
  background-color: ${(props) => props.processing ? '#0a6c5b1a' : '#e2e8ee'};
  padding: 8px 11px;
  color: ${(props) => props.processing ? '#0a6c5b' : '#000000'};;
  font-size: 14px;
  font-weight: 700;
  user-select: none;
  cursor: pointer;
  transition: 0.1s background-color, 0.1s color;
  text-align: center;
  display: flex;
  align-items: center;
  
  svg, div {
    margin-right: 8px;
  }
  
  svg {
    font-size: 15px;
    font-weight: 400;
    line-height: 15px;
  }
  
  &:hover {
    background-color: ${(props) => props.processing ? '#0a6c5b1a' : lighten(0.05, '#e2e8ee')}};
  }

  ${(props) => props.processing && css`
    pointer-events: none;
  `}
`