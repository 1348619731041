import { types } from 'mobx-state-tree';
import RecordingGroup from '@models/RecordingGroup';

const Participant = types.model('Participant', {
  id: types.identifier,
  name: types.string,
  recordingGroups: types.array(RecordingGroup),
}).views(self => ({
  get isUploading() {
    return self.recordingGroups.some(item => item.isUploading)
  },
  get isProcessing() {
    return self.recordingGroups.some(item => item.isProcessing)
  },
  get localRecordingGroups() {
    return self.recordingGroups.filter(item => item.type.includes('LOCAL') || item.type.includes('SCREEN'))
  },
  get remoteRecordingGroups() {
    return self.recordingGroups.filter(item => item.type.includes('REMOTE'))
  },
  get hasRemoteRecordings() {
    return this.remoteRecordingGroups.length > 0
  }
}))

export default Participant;