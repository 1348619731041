import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import styled from 'styled-components/macro';
import VideoFile from '@components/ui/editor/video/VideoFile';
import VideoControls from '@components/ui/editor/video/VideoControls';
import Timeline from '@components/ui/editor/video/Timeline';
import { useRect } from '@util/rect';
import Editor from '@models/editor/Editor';

interface VideoProps {
  editor: Editor;
}

function Video(props: VideoProps) {
  const containerRef = useRef(null);
  const controlRef = useRef(null);

  const containerRect = useRect(containerRef);
  const controlRect = useRect(controlRef);
  const containerHeight = window.innerHeight - containerRect.top - 25;

  const containerMaxWidth = containerRect.width;

  let videoHeight = containerHeight - controlRect.height;
  let contentWidth = videoHeight * (16 / 9);
  if (contentWidth > containerMaxWidth) {
    contentWidth = containerMaxWidth;
    videoHeight = containerMaxWidth / (16 / 9)
  }

  return (
    <Container height={containerHeight} ref={containerRef}>
      <Content width={contentWidth}>
        <VideoFile editor={props.editor} height={videoHeight} />
        <ControlsContainer ref={controlRef}>
          <Timeline editor={props.editor} />
          <VideoControls editor={props.editor} />
        </ControlsContainer>
      </Content>
    </Container>
  )
}

export default observer(Video)

const Container = styled.div<{height: number}>`
  height: ${(props) => props.height}px;
  flex: 1;
`

const ControlsContainer = styled.div``

const Content = styled.div<{width: number}>`
  width: ${(props) => props.width}px;
  margin: 0 auto;
`