import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lighten } from 'polished';
import { IBroadcast } from '@models/Broadcast';
import { RingSpinner } from "react-spinners-kit";

interface StudioListItemProps {
  broadcast: IBroadcast;
}

function StudioListItem(props: StudioListItemProps) {
  const processing = !props.broadcast.isEditorReady;

  const onClick = () => {
    if (processing) {
      return;
    }

    props.broadcast.jumpToEditor()
  }

  return (
    <Container>
      <NameContainer>
        <Name>{props.broadcast.name}</Name>
      </NameContainer>
      <InfoContainer>
        <Date>{props.broadcast.createdAt.fromNow()}</Date>
      </InfoContainer>
      <Buttons>
      {!processing && (
          <SessionButton onClick={props.broadcast.downloadClip}>
            <FontAwesomeIcon icon='download' />
            <p>Download</p>
          </SessionButton>
        )}
        <SessionButton onClick={onClick}>
          {processing ? <RingSpinner color='black' loading={true} size={14} /> : <FontAwesomeIcon icon='cut' />}
          <p>{processing ? 'processing' : 'Edit'}</p>
        </SessionButton>
      </Buttons>
    </Container>
  )
}

export default observer(StudioListItem)

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
  margin-right: 67px;
  margin-left: 25px;
  
  > *:first-child {
    margin-right: 15px;
  }
`

const Date = styled.p`
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  line-height: 26.34px;
`

const NameContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`

const Name = styled.p`
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  line-height: 26.34px;
  cursor: default;
  word-break: break-word;
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  min-width: 258px;
  justify-content: flex-end;
  
  > *:first-child {
    margin-right: 13px;
  }
  
  > *:last-child {
    margin-right: 0;
  }
`

const SessionButton = styled.div<{primary?: boolean}>`
  text-decoration: none;
  border-radius: 12px;
  background-color: ${(props) => props.primary ? '#feca45' : '#e2e8ee'};
  padding: 8px 11px;
  color: #000000;
  font-size: 14px;
  font-weight: 700;
  user-select: none;
  cursor: pointer;
  transition: 0.1s background-color, 0.1s color;
  text-align: center;

  display: flex;
  align-items: center;
  
  svg {
    font-size: 15px;
    font-weight: 400;
    line-height: 15px;
  }

  p {
    margin-left: 8px;
  }
  
  &:hover {
    background-color: ${(props) => props.primary ? lighten(0.1, '#feca45') : 'black'};
    color: ${(props) => props.primary ? 'black' : 'white'};
  }
`

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 23px;
  padding-bottom: 23px;
  text-decoration: none;
`;