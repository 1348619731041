import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import Card from '@components/ui/Card';
import LinkButton from '@components/ui/LinkButton';

interface ExtraInfoProps {}

function ExtraInfo(props: ExtraInfoProps) {

  return (
    <Container>
      <Content>
        <Headline>How to use Welder</Headline>
        <Card padding='30px 15px' maxWidth={618}>
          <div>
            <iframe title='Introduction' width="560" height="315" src="https://www.youtube.com/embed/oJRC3EnpXss" frameBorder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  allowFullScreen/>
            <Text>We also sent this video to you mail, if your don’t have time now</Text>
            <LinkButton link='/' text='Finish the setup' icon='arrow-right' />
          </div>
        </Card>
      </Content>
    </Container>
  );
}

export default observer(ExtraInfo);

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
`;

const Content = styled.div`
  max-width: 618px;
  width: 100%;
  position: relative;
  z-index: 10;
  
  iframe {
    border-radius: 14px;
  }
`

const Text = styled.p`
  color: #949ba3;
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 29.63px;
  text-align: left;
  padding-top: 20px;
  padding-bottom: 30px;
`

const Headline = styled.h1`
  color: #000000;
  font-size: 38px;
  font-weight: 400;
  margin-bottom: 33px;
`