import { flow, types } from 'mobx-state-tree';
import { getApi, getNotifications } from '@stores/root';
import User from '@models/User';
import { isEmptyObject } from '@util/object';
import { getStore } from '@stores/getStore';
import { reaction } from 'mobx';
import { toast } from 'react-toastify';
import stripe from '@util/stripe';
import BillingPlan from '@models/BillingPlan';
import BillingCredits from '@models/BillingCredits';

export default types
  .model('AccountStore', {
    submittingExtraInfo: types.boolean,
    loadingUser: types.boolean,
    account: types.maybe(User),
    currentPlan: types.maybe(BillingPlan),
    credits: types.array(BillingCredits),
  })
  .views((self) => ({
    get requiredExtraInfo() {
      if (!self.account) {
        return false;
      }

      return isEmptyObject(self.account.extraInfo);
    },
    get recordingHours() {
      const credits = self.credits.find((item) => item.metric === 'STANDARD_RECORDING');
      if (!credits) return null;
      return { used: credits.used, total: credits.total, available: credits.available };
    },
    get canCreateSession() {
      const credits = self.credits.find((item) => item.metric === 'STANDARD_RECORDING');
      if (!credits) return false;
      const hours = { used: credits.used, total: credits.total, available: credits.available };
      return hours.available > 0;
    },
  }))
  .actions((self) => {
    const setExtraInfo = flow(function* (extraInfo: any) {
      self.submittingExtraInfo = true;

      try {
        yield getApi(self).addExtraInfo(extraInfo);
        self.account!.extraInfo = extraInfo;
      } catch (e) {
        console.error(e);
        getNotifications(self).error('Please try again later');
      } finally {
        self.submittingExtraInfo = false;
      }
    });

    const fetchCurrentPlan = flow(function* () {
      try {
        const plan = yield getApi(self).getCurrentPlan();
        self.currentPlan = BillingPlan.create(plan);
      } catch (e) {
        console.error(e);
      }
    });

    const fetchBillingCredits = flow(function* () {
      try {
        const credits = yield getApi(self).getCredits();
        self.credits.replace(credits);
      } catch (e) {
        console.error(e);
      }
    });

    const fetchUser = flow(function* () {
      self.loadingUser = true;

      try {
        const user = yield getApi(self).getCurrentUser();
        self.account = User.create(user);
      } finally {
        self.loadingUser = false;
      }
    });

    function logout() {
      getStore(self).authStore.removeToken();
      getStore(self).resetState();
    }

    const redirectToBilling = flow(function* () {
      try {
        const data = yield getApi(self).getStripeBillingPortalUrl();
        window.open(data.url, '_self');
      } catch (e) {
        console.error(e);
        toast.error('Could not open billing. Please try again later');
      }
    });

    const redirectToCheckout = flow(function* (slug: string) {
      try {
        const data = yield getApi(self).getPlanCheckoutSession(slug);
        if (data.stripeCheckoutSessionId) {
          yield stripe.redirectToCheckout({ sessionId: data.stripeCheckoutSessionId });
        }

        yield fetchCurrentPlan();
      } catch (e) {
        toast.error('Could not redirect to checkout. Please try again later.');
      }
    });

    function afterCreate() {
      reaction(
        () => self.account,
        (account) => {
          if (account) {
            const zE = (window as any).zE;
            zE('webWidget', 'identify', {
              email: account.email,
              name: account.extraInfo.name,
            });
          }
        }
      );
    }

    return {
      setExtraInfo,
      fetchUser,
      redirectToBilling,
      afterCreate,
      redirectToCheckout,
      fetchCurrentPlan,
      fetchBillingCredits,
      logout,
    };
  });
