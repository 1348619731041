import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { Tooltip } from 'react-tippy';
import { lighten } from 'polished';

interface DeletePopupProps {
  onClick: () => void;
  text: string;
  children: React.ReactNode;
  toggleIsVisible: (toggle: boolean) => void;
}

function DeletePopup(props: DeletePopupProps) {
  const [isOpen, setIsOpen] = useState(false);

  const { toggleIsVisible } = props;
  useEffect(() => {
    toggleIsVisible(isOpen)
  }, [isOpen, toggleIsVisible])

  const tooltipComponent = (
    <Content>
      <Headline>{props.text}</Headline>
      <Buttons>
        <Button onClick={() => setIsOpen(false)}>Nope</Button>
        <Button primary onClick={() => { props.onClick(); setIsOpen(false) }}>Yes, archive</Button>
      </Buttons>
    </Content>
  )

  return (
    <Container>
      <Tooltip
        html={tooltipComponent}
        open={isOpen}
        onRequestClose={() => setIsOpen(false)}
        trigger='manual'
        animateFill={false}
        theme={'welder' as any}
        interactive={true}
      >
        <Child onClick={() => setIsOpen(true)}>
          {props.children}
        </Child>
      </Tooltip>
    </Container>
  )
}

export default DeletePopup;

const Container = styled.div``
const Content = styled.div``
const Child = styled.div`
  cursor: pointer;
`

const Headline = styled.p`
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  margin-bottom: 15px;
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  *:first-child {
    margin-right: 18px;
  }
`

const Button = styled.div<{primary?: boolean}>`
  border-radius: 12px;
  background-color: #e2e8ee;
  padding: 7px 13px;
  color: #000000;
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  cursor: pointer;
  
  &:hover {
    background-color: ${lighten(0.02, '#e2e8ee')};
  }
  
  ${(props) => props.primary && css`
    background-color: #feca45;
    
    &:hover {
      background-color: ${lighten(0.05, '#feca45')};
    }
  `}
`