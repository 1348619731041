import { MomentPrimitive } from '@util/mstTypes';
import { flow, Instance, types, applySnapshot } from 'mobx-state-tree';
import { getApi, getHistory, getWebsocket } from '@stores/root';
import downloadURI from '@util/download';


const Clip = types.model('Clip', {
  downloadUrl: types.maybeNull(types.string),
  id: types.identifier,
  startedAt: MomentPrimitive,
  state: types.union(types.literal('CREATING'), types.literal('PROCESSING'), types.literal('READY')),
  transcriptId: types.maybeNull(types.string),
  type: types.union(types.literal('STUDIO_RECORDING')),
  transcriptState: types.union(types.literal('CREATING'), types.literal('READY'), types.literal('DOES_NOT_EXIST'))
}).views(self => ({
  get isReady() {
    return self.state === 'READY'
  },
}))


const Broadcast = types.model('Broadcast', {
  id: types.identifier,
  studioId: types.string,
  createdAt: MomentPrimitive,
  broadcastType: types.union(types.literal('RECORDING'), types.literal('STREAMING_AND_RECORDING')),
  state: types.union(types.literal('ONGOING'), types.literal('FINISHED'), types.literal('FAILED')),
  name: types.string,
  clips: types.array(Clip)
}).views(self => ({
  get mainClip() {
    return self.clips.find(item => item.type === 'STUDIO_RECORDING')!;
  },
})).views(self => ({
  get isEditorReady() {
    return self.mainClip.state === 'READY' && self.mainClip.transcriptState === 'READY';
  }
})).actions(self => ({
  jumpToEditor: flow(function* () {
    const clip = self.mainClip;
    const api = getApi(self);
    const project = yield api.getOrCreateEditorProject(clip.id);
    getHistory(self).push(`/editor/${project.id}`)
  }),
  downloadClip: function() {
    if (self.mainClip.downloadUrl) {
      downloadURI(self.mainClip.downloadUrl, self.name)
    }
  },
  afterCreate: function() {
    const ws = getWebsocket(self);
    const api = getApi(self);

    ws.on('transcript.updated', async (msg) => {
      if (self.mainClip.transcriptId === msg.transcriptUuid) {
        const broadcastData = await api.fetchBroadcast(self.id);
        applySnapshot(self, broadcastData)
      }
    })

    ws.on('clip.updated', async (msg) => {
      if (self.mainClip.id === msg.clipUuid) {
        const broadcastData = await api.fetchBroadcast(self.id);
        applySnapshot(self, broadcastData)
      }
    })
  }
}))

export default Broadcast;

export interface IBroadcast extends Instance<typeof Broadcast> {}
