import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import { Dashboard, useUppy } from '@uppy/react'
import Uppy from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import { useStore } from '@stores/root';

const UppyBox = Uppy as any

interface UppyUploadProps {}

function UppyUpload(props: UppyUploadProps) {
  const store = useStore();

  const uppy = useUppy(() => {
    return new UppyBox({
      id: 'uppy',
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ['audio/*', 'video/*']
      },
      locale: {
        strings: {
          dropHint: 'Drop your files here',
          dropPaste: 'Drop file here or %{browse}',
          dropPasteImport: 'Drop file here, paste, %{browse} or import from:',
        },
      },
      proudlyDisplayPoweredByUppy: false
    }).use(AwsS3, {
      async getUploadParameters(file: any) {
        const transcript = await store.transcriptStore.createTranscript(file);
        return {
          method: 'PUT',
          url: transcript!.uploadUrl,
          fields: [],
          headers: {}
        }
      }
    })
  })

  useEffect(() => {
    async function upload(file: any) {
      await store.transcriptStore.finishTranscriptUpload(file)
      uppy.reset()
    }

    uppy.on('upload-success', upload)

    return () => {
      uppy.off('upload-success', upload)
    }
  }, [uppy, store.transcriptStore])

  return (
    <Container>
      <Dashboard height={300} proudlyDisplayPoweredByUppy={false} uppy={uppy} />
    </Container>
  )
}

export default observer(UppyUpload);

const Container = styled.div``;
