import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import StartSessionBanner from '@components/ui/StartSessionBanner';
import SessionList from '@components/ui/SessionList';
import { useStore } from '@stores/root';
import FirstSession from '@components/ui/FirstSession';
import usePageBottom from '../../hooks/usePageBottom';
import Spinner from '@components/ui/Spinner';


interface LocalRecordingProps {}

function LocalRecording(props: LocalRecordingProps) {
  const store = useStore();
  const { isBottom, getIsBottom } = usePageBottom();

  useEffect(() => {
    store.sessionStore.fetchNextSessionPage();

    const interval = setInterval(() => {
      store.sessionStore.refreshSessions();
    }, 20000);

    return () => {
      clearInterval(interval);
    };
  }, [store.sessionStore]);

  useEffect(() => {
    if (isBottom && store.sessionStore.sessionList.length > 0 && getIsBottom()) {
      store.sessionStore.fetchNextSessionPage();
    }
  }, [isBottom, store.sessionStore.sessionList, store.sessionStore, getIsBottom]);

  if (store.sessionStore.loadingSessions && !store.sessionStore.hasSessions) {
    return null;
  }

  return (
    <Container>
      {store.sessionStore.hasSessions ? (
        <SessionsContainer>
          <SessionList sessions={store.sessionStore.sessionList} />
          <ListSpinner>
            <Spinner loading={store.sessionStore.loadingNextPage} />
          </ListSpinner>
        </SessionsContainer>
      ) : (
        <FirstSessionContainer>
          <FirstSession />
        </FirstSessionContainer>
      )}
    </Container>
  );
}

export default observer(LocalRecording);

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const FirstSessionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const SessionsContainer = styled.div`
  width: 100%;
  max-width: 930px;
  margin: 0 auto;
  padding-top: 150px;
  margin-bottom: 150px;
`;

const ListSpinner = styled.div`
  margin-top: 10px;
`;
