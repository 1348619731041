import { getParentOfType, Instance, types } from 'mobx-state-tree';
import Transcript from '@models/Transcript';

export const TranscriptParticipant = types.model('TranscriptParticipant', {
  name: types.string,
  id: types.identifier
}).views(self => ({
  get participantColor() {
    const transcript = getParentOfType(self, Transcript) as any
    const participantColors: { [key: number]: string } = {
      0: '#0A6C5B',
      1: '#D0891E',
      2: '#5F41B7',
      3: '#27B280',
      4: '#E46D3A',
      5: '#33ABB2',
      6: '#D65D88',
      7: '#5CC26D'
    }
    return participantColors[transcript.participants.findIndex((item: any) => item.id === self.id)] || Object.values(participantColors).slice(-1)[0]
  }
}))

export interface ITranscriptParticipant extends Instance<typeof TranscriptParticipant> {}