import { types } from 'mobx-state-tree';
import moment from 'moment';

export const MomentPrimitive = types.custom<string, moment.Moment>({
  name: 'MomentDate',
  fromSnapshot(value: string) {
    return moment.utc(value).local()
  },
  toSnapshot(value: moment.Moment) {
    return value.toISOString()
  },
  isTargetType(value: string | moment.Moment): boolean {
    return value instanceof moment;
  },
  getValidationMessage(value: string): string {
    const date = moment(value)
    return !date.isValid() ? 'Not a valid date' : '';
  }
})