import { observer } from 'mobx-react-lite';
import React, { useCallback, useRef, useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import {useSpring, animated } from 'react-spring'
import Header from '@components/ui/editor/content/Header';
import Paragraph from '@components/ui/editor/content/Paragraph';
import { useRect } from '@util/rect';
import TextSelectionPopover from '@components/ui/TextSelectionPopover';
import { useTextSelection } from '../../../../hooks/useTextSelection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Editor from '@models/editor/Editor';
import { darken } from 'polished';
import { useKeyPress } from '../../../../hooks/useKeyPress';

interface ContentProps {
  editor: Editor;
}

function Content(props: ContentProps) {
  const ref = useRef(null)

  const containerRef = useRef(null);

  const containerRect = useRect(containerRef);  
  const containerHeight = window.innerHeight - containerRect.top - 25;

  const paragraphs = props.editor.paragraphs.map(item => <Paragraph key={item.id} paragraph={item} />)

  return (
    <Container ref={containerRef} height={containerHeight}>
      <ContentContainer>
        <Header editor={props.editor} />
        <TextContent ref={ref}>
          {paragraphs}
        </TextContent>
      </ContentContainer>
      <TextSelectionPopover editor={props.editor} target={ref.current} mount={ref.current} render={Popover} />
    </Container>
  )
}

export default observer(Content)

interface PopoverProps {
  editor: Editor;
}


function Popover(props: ReturnType<typeof useTextSelection> & PopoverProps) {
  const ref = useRef<HTMLDivElement | null>(null);
  const ELEMENT_WIDTH = 236;
  const leftRect = props.clientRect?.left || 0;
  const widthRect = props.clientRect?.width || 0;
  const mountWidth = (props.mountRect?.width || 0) - 33; // padding
  const [isHidden, setIsHidden] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);


  let left = leftRect + widthRect / 2 - ELEMENT_WIDTH / 2;
  let top = (props.clientRect?.top || 0) - 45;

  if (left < 0) {
    left = 33; // include margin on the sides
  } else if (left + ELEMENT_WIDTH > mountWidth) {
    left = mountWidth - ELEMENT_WIDTH;
  }

  if (top < 38) {
    top = (props.clientRect?.top || 0) - 20;
  }

  const onSectionClick = useCallback(() => {
    if (props.selectionNodes) {
      props.editor.createSection(props.selectionNodes.anchorNode.dataset.wordId!, props.selectionNodes.focusNode.dataset.wordId!)
      props.reset()
    }
  }, [props])

  const onCut = useCallback(() => {
    if (props.selectionNodes) {
      props.editor.createCut(props.selectionNodes.anchorNode.dataset.wordId!, props.selectionNodes.focusNode.dataset.wordId!)
      props.reset()
    }
  }, [props])

  useEffect(() => {
    if (props.clientRect == null || props.isCollapsed) {
      setIsHidden(true)
    } else {
      setIsHidden(false)
    }
  }, [props.clientRect, props.isCollapsed])

  useEffect(() => {
    function mouseDown(e: MouseEvent) {
      if (!ref.current) {
        return;
      }

      if (e.target === ref.current || ref.current!.contains(e.target as any)) {
        return;
      }

      setIsDisabled(true)
    }

    function mouseUp() {
      setIsDisabled(false);
    }

    window.addEventListener('mousedown', mouseDown);
    window.addEventListener('mouseup', mouseUp);

    return () => {
      window.removeEventListener('mousedown', mouseDown);
      window.removeEventListener('mouseup', mouseUp);
    }
  }, [ref])

  useKeyPress(() => {
    if (props.selectionNodes) {
      onCut()
    }
  }, ['Backspace'])

  const animation = useSpring({
    from: {},
    config: {
      mass: 5.0,
      tension: 600,
      friction: 150
    },
    to: { 
      left: left,
      top: top,
    },
  })

  const style = {
    position: 'absolute',
    width: ELEMENT_WIDTH,
    height: 38,
    boxShadow: '0 2px 14px rgba(0, 0, 0, 0.15)',
    'border': '2px solid rgba(10, 108, 91, 0.3)',
    backgroundColor: '#ffffff',
    borderRadius: 12,
    display: isHidden ? 'none' : 'block',
    userSelect: 'none',
    pointerEvents: isDisabled ? 'none' : 'all'
  }

  return <AnimationContainer style={{...style, ...animation} as any}>
    <PopoverContainer ref={ref} hidden={isHidden}>
      <ExportSection onClick={onSectionClick}>
        <FontAwesomeIcon icon='share-square' />
        Export Section
      </ExportSection>
      <Line />
      <CutSection onClick={onCut}>
        <FontAwesomeIcon icon='cut' />
        Cut
      </CutSection>
    </PopoverContainer>
  </AnimationContainer>
}

const Container = styled.div<{height: number}>`
  width: 100%;
  width: 750px;
  max-width: 750px;
  height: ${(props) => props.height}px;
`

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background-color: #ffffff;

  overflow: hidden;
`

const TextContent = styled.div`
  padding: 0px 33px;
  overflow-y: scroll;
  height: calc(100% - 60px);
  padding-right: 15px;
  position: relative;

  *::selection {
    background: #0A6C5B;
    color: white;
  }
`

const PopoverContainer = styled.div<{hidden: boolean}>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const AnimationContainer = styled(animated.span)`
  user-select: none;
`

const ExportSection = styled.button`
  display: flex;
  align-items: center;
  color: #000000;
  font-weight: 600;
  font-size: 16px;
  padding: 0px 10px;
  height: 100%;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
  appearance: none;
  border: none;
  outline: none;
  background-color: white;

  svg {
    margin-right: 10px;
    font-size: 15px;
  }

  &:hover {
    background-color: ${() => darken(0.1, '#ffffff')};
  }
`

const CutSection = styled.div`
  display: flex;
  align-items: center;
  color: #000000;
  font-weight: 600;
  font-size: 16px;
  padding: 0px 10px;
  height: 100%;
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
  flex: 1;

  svg {
    margin-right: 10px;
    font-size: 15px;
  }

  &:hover {
    background-color: ${() => darken(0.1, '#ffffff')};
  }
`

const Line = styled.div`
  height: 18px;
  width: 1px;
  background: #D7DFE8;
  position: absolute;
  left: 68.6%;
`