import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';

interface BizPlanProps {}

function BizPlan(props: BizPlanProps) {

  return (
    <Container>
      <Headline>Business</Headline>
      <Description>
        Tailored for a progressive business that will benefit from these features
      </Description>
      <Features>
        <Feature>Multi user access</Feature>
        <Feature>Single sign-on</Feature>
        <Feature>8+ participants</Feature>
        <Feature>API access</Feature>
        <Feature>Premium support</Feature>
      </Features>
      <Contact>
        Contact us at business@getwelder.com
      </Contact>
    </Container>
  );
}

export default observer(BizPlan);

const Container = styled.div`
  border-radius: 33px;
  background-color: rgba(226, 232, 238, 0.33);
  padding: 15px 30px 0 30px;
  max-width: 350px;
`;

const Headline = styled.h2`
  color: #000000;
  font-size: 26px;
  font-weight: 700;
  line-height: 42.8px;
  margin-bottom: 5px;
`

const Description = styled.p`
  color: #949ba3;
  font-size: 16px;
  font-weight: 400;
  line-height: 26.34px;
`

const Features = styled.div`
  margin-top: 27px;
`

const Feature = styled.p`
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  line-height: 26.34px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  
  &::before {
    content: ' ';
    display: block;
    margin-right: 9px;
    max-width: 6px;
    width: 100%;
    height: 6px;
    border-radius: 50%;
    background-color: black;
  }
  
  &:first-child {
    margin-top: 0px;
  }
`

const Contact = styled.p`
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  line-height: 26.34px;
  margin-top: 35px;
  margin-bottom: 20px;
`