import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import Card from '@components/ui/Card';
import Input from '@components/ui/form/Input';
import Button from '@components/ui/Button';
import RegisterBackground from '@assets/register-background@2x.png';
import { useForm } from 'react-hook-form';
import { useStore } from '@stores/root';
import { Link, useLocation } from 'react-router-dom';
import QS from 'query-string';
import GoogleLogin from '@components/ui/auth/GoogleLogin';

interface LoginProps {}

type FormData = {
  email: string;
  password: string;
}

function Login(props: LoginProps) {
  const store = useStore();
  const location = useLocation<{from: string | undefined}>();

  const { register, handleSubmit } = useForm<FormData>();
  const onSubmit = async (data: any) => {
    const { from } = location.state as any || { from: { pathname: "/" } }
    await store.authStore.login(data, from);
  }

  useEffect(() => {
    const query = QS.parse(location.search);
    if (query.token) {
      store.authStore.adminLogin(query.token as string);
    }
  }, [location.search, store.authStore])

  return (
    <Container>
      <Content>
        <Headline>Sign in</Headline>
        <Card padding='45px 57px' maxWidth={618}>
          <CardForm onSubmit={handleSubmit(onSubmit)}>
            <ButtonContainer>
              <div>
                <GoogleLogin />
              </div>
              <div />
            </ButtonContainer>
            <Inputs>
              <Input ref={register({required: true})} required type='email' placeholder='email@gmail.com' label='Your email' name='email' />
              <Input ref={register({required: true})} required type='password' placeholder='password' label='Your password' name='password' />
            </Inputs>
            <Button loading={store.authStore.inProgress} type='submit' text='Sign in' icon='arrow-right' />
            <SmallText>Did you forget your password? <Link to='/auth/forgot-password'>Reset here</Link></SmallText>
          </CardForm>
        </Card>
        <Redirect to={{pathname: '/auth/register', state: { from: location.state?.from || '/'}}}>Don't have an account? Sign up.</Redirect>
      </Content>
      <img width={646} src={RegisterBackground} alt='background' />
    </Container>
  );
}

export default observer(Login);

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  
  > img {
    position: absolute;
    right: -80px;
    bottom: -50px;
  }
`;

const Content = styled.div`
  max-width: 618px;
  width: 100%;
  position: relative;
  z-index: 10;
`

const CardForm = styled.form`
  width: 100%
`

const Inputs = styled.div`
  > * {
    margin-bottom: 40px;
  }
`

const Headline = styled.h1`
  color: #000000;
  font-size: 38px;
  font-weight: 400;
  margin-bottom: 33px;
`

const Redirect = styled(Link)`
  color: #949ba3;
  font-size: 18px;
  font-weight: 600;
  line-height: 29.63px;
  display: block;
  margin-top: 20px;
  text-align: center;
  cursor: pointer;
  
  &:hover {
    color: #feca45;
  }
`

const SmallText = styled.p`
  margin-top: 25px;
  color: #949ba3;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  
  a {
    color: #feca45;
    text-decoration: none;
  }
`

const ButtonContainer = styled.div`
  
  > div:first-child {
    min-height: 60px;
  }
  
  > div:last-child {
    margin-top: 40px;
    margin-bottom: 31px;
    width: 100%;
    height: 1px;
    background: #e4ebf3;
  }
`