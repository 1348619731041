import React from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { lighten } from 'polished';
import copyToClipboard from '@util/copyToClipboard';
import { toast } from 'react-toastify';
import { useCurrentSession } from '../../../hooks/useCurrentSession';

interface SessionJoinProps {}

function SessionJoin(props: SessionJoinProps) {
  const session = useCurrentSession();

  if (!session) {
    return null;
  }

  function copyToClipboardButton(text: string) {
    const res = copyToClipboard(text);
    if (res) {
      toast.info('Link copied to clipboard')
    } else {
      toast.warn(`Could not copy link. Please try to copy link manually. ${text}`)
    }
  }

  return (
    <Container>
      <ContainerContent>
        <GoBack to='/sessions'><FontAwesomeIcon icon='arrow-left' /> Back</GoBack>
        <NewCard>

          <CreatorContainer isMultiplayer={session.sessionType === 'MULTI_PLAYER'}>
            <Headline>{session.name}</Headline>
            <JoinContainer>
              <Button onClick={() => window.open(session.joinUrls.host.web, '_blank')}>
                <FontAwesomeIcon icon='sign-in-alt' />
                <p>Join as creator</p>
              </Button>
              <JoinHelpText>Joining through this button will give you an ability to start recording</JoinHelpText>
            </JoinContainer>
          </CreatorContainer>

          {session.sessionType === 'MULTI_PLAYER' && (
            <GuestContainer>
              <GuestHeadline>Link for your guests</GuestHeadline>
              <GuestPicker>
                <div>
                  <ButtonCopy onClick={() => copyToClipboardButton(session.joinUrls.guests.web)}>
                    <FontAwesomeIcon icon='link' />
                    <p>Copy link for <b>web</b> access</p>
                  </ButtonCopy>
                  <JoinTypeExplanation>
                    <Explanation>
                      <FontAwesomeIcon icon='plus' />
                      <p>Up to 4k</p>
                    </Explanation>
                    <Explanation>
                      <FontAwesomeIcon style={{fontSize: '5px'}} icon='circle' />
                      <p>Works on Chrome, Firefox, Edge</p>
                    </Explanation>
                    <Explanation>
                      <FontAwesomeIcon icon='minus' />
                      <p>On Safari guest will get a popup to join from Chrome</p>
                    </Explanation>
                  </JoinTypeExplanation>
                </div>

              </GuestPicker>
            </GuestContainer>
          )}

        </NewCard>
      </ContainerContent>
    </Container>
  );
}

export default observer(SessionJoin);

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ContainerContent = styled.div`
  max-width: 930px;
  width: 100%;
  padding-top: 55px;
`

const CreatorContainer = styled.div<{isMultiplayer: boolean}>`
  margin-bottom: 67px;
  padding: 67px 90px 0 90px;

  ${(props) => !props.isMultiplayer && css`
    padding: 67px 90px 67px 90px;
  `}
`

const GuestContainer = styled.div`
  background-color: #fafafa;
  border-top: 1px solid #eef3f8;
  padding: 0 90px 67px 90px;
  border-bottom-left-radius: 34px;
  border-bottom-right-radius: 34px;
`

const GoBack = styled(Link)`
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 26.34px;
  color: #000000;
  
  svg {
    font-weight: 400;
    line-height: 26.34px;
    margin-right: 9px;
  }
  
  &:hover {
    text-decoration: underline;
  }
`

const NewCard = styled.div`
  border-radius: 33px;
  max-width: 930px;
  background-color: #ffffff;
  margin-top: 30px;
  width: 100%;
  margin-bottom: 150px;
`

const Headline = styled.h1`
  color: #000000;
  font-size: 48px;
  font-weight: 400;
  margin-bottom: 35px;
`

const Button = styled.button`
  outline: none;
  appearance: none;
  box-shadow: 0 11px 41px rgba(188, 160, 89, 0.4);
  background-image: linear-gradient(90deg, #ffd260 0%, #feca45 100%);
  padding: 18px 20px;
  color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  user-select: none;
  cursor: pointer;
  text-decoration: none;
  border-radius: 14px;
  
  p {
    font-size: 18px;
    font-weight: 700;
  }
  
  svg {
    color: #000000;
    font-size: 18px;
    font-weight: 400;
    margin-right: 13px;
  }
  
  &:hover {
    background-image: linear-gradient(90deg, ${() => lighten(0.05, '#ffd260')} 0%, ${() => lighten(0.05, '#feca45')} 100%);
  }
`

const GuestHeadline = styled.h2`
  color: #000000;
  font-size: 26px;
  font-weight: 700;
  line-height: 42.8px;
  margin-top: 45px;
  margin-bottom: 0px;
`

const JoinContainer = styled.div`
  display: flex;
  align-items: center;
`

const JoinHelpText = styled.p`
  color: #feca45;
  font-size: 14px;
  font-weight: 600;
  line-height: 22.97px;
  max-width: 314px;
  margin-left: 21px;
`

const GuestPicker = styled.div`
  display: flex;
  justify-content: space-between;
  
  > * {
    flex: 1;
  }
  
  > div:first-child {
    margin-right: 55px;
    border-right: 1px solid #eef3f8;
  }
`

const ButtonCopy = styled.div`
  border-radius: 14px;
  border: 2px solid #d7dfe8;
  background-color: #d7dfe8;
  padding: 18px 24px;
  padding-left: 17px;
  color: #000000;
  font-size: 18px;
  font-weight: 400;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-top: 15px;
  user-select: none;
  
  b {
    font-weight: 700;
  }
  
  svg {
    margin-right: 15px;
  }
  
  &:hover {
    background-color: ${() => lighten(0.02, '#d7dfe8')}
  }
`

const JoinTypeExplanation = styled.div`
  margin-top: 22px;
`

const Explanation = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;

  > svg {
    margin-right: 11px;
    color: #949ba3;
    font-size: 10px;
    font-weight: 400;
  }
  
  p {
    color: #949ba3;
    font-size: 16px;
    font-weight: 600;
    
    svg {
      font-weight: 400;
      font-size: 16px;
    }
    
    svg:first-child {
      margin-left: 20px;
      margin-right: 6px;
    }
  }
`