import React from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components/macro';

interface ToggleProps {
  active: boolean;
  toggle?: () => void;
}

function Toggle(props: ToggleProps) {
  const animationProps = useSpring({
    buttonX: props.active ? 26 : -4,
    backgroundColor: props.active  ? '#feca45' : '#d7dfe8',
    textColor: props.active  ? '#cc9914' : '#ffffff',
    offOpacity: props.active  ? 0 : 1,
    onOpacity: props.active  ? 1 : 0,
    config: {
      tension: 210,
      friction: 20
    }
  })

  return (
    <Checkbox onClick={() => props.toggle?(!props.active) : null} style={{ backgroundColor: animationProps.backgroundColor }}>
      <CheckboxText style={{ color: animationProps.textColor, opacity: animationProps.onOpacity }} >ON</CheckboxText>
      <CheckboxText style={{ color: animationProps.textColor, opacity: animationProps.offOpacity }} >OFF</CheckboxText>
      <CheckboxButton style={{ transform: animationProps.buttonX.interpolate(x => `translateX(${x}px)`) }} />
    </Checkbox>
  )
}

export default Toggle;

const Checkbox = styled(animated.div)`
  cursor: pointer;
  user-select: none;
  width: 60px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 53px;
  background-color: #d7dfe8;
  position: relative;
  padding: 6px;
  
  > *:first-child {
    margin-left: 3px;
  }
`

const CheckboxText = styled(animated.p)`
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: white;
`

const CheckboxButton = styled(animated.div)`
  width: 26px;
  height: 26px;
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  background-color: #ffffff;
  position: absolute;
`