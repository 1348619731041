import { useCallback, useEffect, useState } from 'react';

export default function() {
  const [isBottom, setIsBottom] = useState(false);

  //**
  // Use this function to get realtime isBottom value. Since isBottom is async and does not have to be
  // updated in a middle of the render
  const getIsBottom = useCallback(() => {
    let bottom = false
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 150) {
      bottom = true
    }

    setIsBottom(bottom)
    return bottom;
  }, [setIsBottom])

  useEffect(() => {
    getIsBottom();
    const resizeObserver = new ResizeObserver(getIsBottom)
    resizeObserver.observe(document.body)
    window.addEventListener('scroll', getIsBottom)
    return () => {
      resizeObserver.disconnect()
      window.removeEventListener('scroll', getIsBottom)
    }
  }, [getIsBottom])

  return { isBottom, getIsBottom }
}