import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import Card from '@components/ui/Card';
import DropdownButton, { DropdownItem } from '@components/ui/DropdownButton';
import { useHistory } from 'react-router';

interface FirstSessionProps {
}

function FirstSession(props: FirstSessionProps) {
  const history = useHistory()

  return (
    <Card maxWidth={834}>
      <Content>
        <Headline>Local recording is disabled. <br /> Try Welder Studio instead!</Headline>
      </Content>
    </Card>
  );
}

export default observer(FirstSession);

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button {
    margin-top: 32px;
    max-width: 380px;
  }
`;

const Headline = styled.h1`
  color: #000000;
  font-size: 48px;
  font-weight: 400;
`
