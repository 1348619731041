import Editor, { CutSection } from "./Editor";
import { action, makeAutoObservable, makeObservable, observable, computed } from "mobx"


export interface IParagraph {
  words: IWord[];
  id: string;
}

export interface IWord {
  id: string;
  startedAt: number;
  endedAt: number;
  text: string;
  index: number;
}

export default class Paragraph {
  id: string;
  words: Word[];

  editor!: Editor;

  get deletedWords() {
    return new Set(this.words.filter(item => item.deleted))
  }

  get cutSectionStartIndex() {
    const map = new Map<number, CutSection>();

    for (let delSection of this.editor.visibleCutSections) {
      if (delSection.startingParagraph === this) {
        map.set(delSection.startWordIndex, delSection)
      }
    }

    return map;
  }

  constructor(id: string, words: Word[]) {
    this.id = id;
    this.words = words;

    makeAutoObservable(this, {
      cutSectionStartIndex: computed.struct
    })
  }

  static createFromData(paragraph: IParagraph) {
    const words =  paragraph.words.map(word => Word.createFromData(word))
    const para = new Paragraph(paragraph.id, words);

    for (let word of words) {
      word.paragraph = para;
    }

    return para;
  }
}

export class Word {

  id: string;
  text: string;
  index: number;
  startedAt: number;
  endedAt: number;

  editor!: Editor;
  paragraph!: Paragraph;

  // changed by reaction in Editor for performance
  selected = false;

  deleted = false;

  constructor(id: string, text: string, index: number, startedAt: number, endedAt: number) {
    this.id = id;
    this.text = text;
    this.index = index;
    this.startedAt = startedAt;
    this.endedAt = endedAt;
    
    makeObservable(this, {
      selected: observable,
      deleted: observable,
      jumpToWord: action.bound,
      markCut: action,
      uncut: action
    })
  }

  jumpToWord() {
    const timeSec = (this.startedAt - this.editor.startTimestamp) / 1000
    this.editor.updateCurrentTime(timeSec, true)
  }

  markCut() {
    this.deleted = true
  }

  uncut() {
    this.deleted = false;
  }

  static createFromData(word: IWord) {
    return new Word(word.id, word.text, word.index, word.startedAt, word.endedAt);
  }
}