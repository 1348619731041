import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  body {
   margin: 0;
   font-family: "jaf-bernina-sans";
   box-sizing: border-box;
   background: #f3f6f9;
   -webkit-font-smoothing: antialiased;
   overflow-x: hidden;
   overscroll-behavior-y: none;
  }

  * {
    box-sizing: border-box;
  }
  
  .Toastify__toast {
    border-radius: 14px;
    font-weight: 600;
    font-family: "jaf-bernina-sans";
  }
  
  .Toastify__toast--info {
    background: black;
    color: white;
  }
  
  .tippy-tooltip {
    border-radius: 14px;
  }

  .tippy-box {
    border-radius: 14px;
  }

  .tippy-content {
    color: #000000;
    font-family: "jaf-bernina-sans";
    font-size: 14px;
    font-weight: 700;
    line-height: 22.34px;
  }
  
  .tippy-tooltip-content {
    color: #000000;
    font-family: "jaf-bernina-sans";
    font-size: 14px;
    font-weight: 700;
    line-height: 22.34px;
  }
  
  .tippy-tooltip.dark-theme {
    border-radius: 5px;
    background-color: #000000;
  }
  
  .tippy-tooltip.dark-theme > .tippy-tooltip-content {
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    line-height: 26.34px;
    
     b {
      font-weight: 700;
     }
  }
  
  .tippy-tooltip.welder-theme {
    box-shadow: 0 15px 44px rgba(219, 223, 227, 0.7);
    border-radius: 14px;
    background-color: #ffffff;
    padding: 18px 25px;
  }
  
  #google-signin-button {
    width: 100%;
  }

  .abcRioButtonBlue {
    width: 100%!important;
  }
  
  .abcRioButtonContents {
    font-weight: 600!important;
  }
`