import { Instance, types } from 'mobx-state-tree';
import Transcript from '@models/Transcript';
import { Timestamp } from '@models/Timestamp';

const SessionRecordingInfo = types.model('SessionRecordingInfo', {
  timestamps: types.array(Timestamp),
  transcript: Transcript
})

export default SessionRecordingInfo;
export interface ISessionRecordingInfo extends Instance<typeof SessionRecordingInfo> {}
