import Editor from '@models/editor/Editor';
import { useKeyPress } from '../../../../hooks/useKeyPress';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components/macro';

interface VideoFileProps {
  editor: Editor;
  height: number;
}

function VideoFile(props: VideoFileProps) {
  const ref = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    if (!props.editor.isPlaying || !ref.current) {
      return;
    }
  
    const interval = setInterval(() => {
      props.editor.updateCurrentTime(ref.current!.currentTime);
    }, 50)

    return () => clearInterval(interval);
  }, [props.editor.isPlaying, ref, props.editor])

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    if (props.editor.isPlaying) {
      ref.current.play();
    } else {
      ref.current.pause();
    }
  
  }, [ref, props.editor.isPlaying])

  useEffect(() => {
    if (ref.current) {
      props.editor.addVideoNode(ref.current)
    }
  }, [ref, props.editor])

  useEffect(() => {
    if (ref.current) {
      ref.current.muted = props.editor.muted;
    }
  }, [ref, props.editor.muted])

  useKeyPress(() => {
    props.editor.togglePlayback()
  }, ['Space'], true)

  useKeyPress(() => {
    props.editor.updateCurrentTime(ref.current!.currentTime - 10, true)
  }, ['ArrowLeft'])

  useKeyPress(() => {
    props.editor.updateCurrentTime(ref.current!.currentTime + 10, true)
  }, ['ArrowRight'])

  useKeyPress(() => {
    props.editor.toggleMute();
  }, ['KeyM'])

  return (
    <Container height={props.height}>
      <video onClick={() => props.editor.togglePlayback()} ref={ref} src={props.editor.fileUrl} poster={props.editor.firstFrameUrl}></video>
    </Container>
  )
}

export default observer(VideoFile)

const Container = styled.div<{height: number}>`
  video {
    object-fit: contain;
    border-radius: 14px;
    height: ${(props) => props.height}px;
  }
`