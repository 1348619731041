import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import Card from '@components/ui/Card';
import Input from '@components/ui/form/Input';
import Button from '@components/ui/Button';
import RegisterBackground from '@assets/register-background@2x.png';
import { useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import { useStore } from '@stores/root';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import GoogleLogin from '@components/ui/auth/GoogleLogin';

interface RegisterProps {}

type FormData = {
  email: string;
  password: string;
};

const schema = yup.object().shape({
  email: yup.string().email().required('Email is required'),
  password: yup.string().min(6).required('Password is required'),
});

function Register(props: RegisterProps) {
  const store = useStore();
  const location = useLocation<{ from: string | undefined }>();

  const { register, handleSubmit, errors } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback(
    async (data: any) => {
      const { from } = (location.state as any) || { from: { pathname: '/' } };
      await store.authStore.register(data, from);

      const { gtag } = window as any;
      gtag('event', 'sign_up', {
        event_category: 'auth',
        event_label: 'Signup with email',
      });
      gtag('event', 'sign_up', {
        event_category: 'auth',
        event_label: 'Signup',
      });
    },
    [store.authStore, location.state]
  );

  return (
    <Container>
      <Content>
        <Headline>Create your account</Headline>
        <Card padding="45px 57px" maxWidth={618}>
          <CardForm onSubmit={handleSubmit(onSubmit)}>
            <ButtonContainer>
              <div>
                <GoogleLogin />
              </div>
              <div />
            </ButtonContainer>
            <Inputs>
              <Input
                ref={register({ required: true })}
                required
                type="email"
                placeholder="email@gmail.com"
                label="Your email"
                name="email"
              />
              <Input
                errors={errors.password}
                ref={register({ required: true })}
                required
                type="password"
                placeholder="password"
                label="Your password"
                name="password"
              />
            </Inputs>
            <Button loading={store.authStore.inProgress} type="submit" text="Create account" icon="arrow-right" />
            <SmallText>
              By clicking on the button you agree to the <Link to="/terms-of-service">terms of service</Link> and you
              are aware we will use your personal data in accordance with{' '}
              <Link to="/privacy-policy">privacy policy</Link>
            </SmallText>
          </CardForm>
        </Card>
        <Redirect to={{ pathname: '/auth/login', state: { from: location.state?.from || '/' } }}>
          Already have an account? Sign in.
        </Redirect>
      </Content>
      <img width={646} src={RegisterBackground} alt="background" />
    </Container>
  );
}

export default observer(Register);

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  overflow: hidden;
  position: relative;

  > img {
    position: absolute;
    right: -80px;
    bottom: -50px;
  }
`;

const Content = styled.div`
  max-width: 618px;
  width: 100%;
  position: relative;
  z-index: 10;
  padding-top: 50px;
  padding-bottom: 50px;
`;

const CardForm = styled.form`
  width: 100%;
`;

const Inputs = styled.div`
  > * {
    margin-bottom: 40px;
  }
`;

const Headline = styled.h1`
  color: #000000;
  font-size: 38px;
  font-weight: 400;
  margin-bottom: 33px;
`;

const Redirect = styled(Link)`
  color: #949ba3;
  font-size: 18px;
  font-weight: 600;
  line-height: 29.63px;
  display: block;
  margin-top: 20px;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: #feca45;
  }
`;

const SmallText = styled.p`
  margin-top: 25px;
  color: #949ba3;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;

  a {
    color: #feca45;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const ButtonContainer = styled.div`
  > div:first-child {
    min-height: 60px;
  }

  > div:last-child {
    margin-top: 40px;
    margin-bottom: 31px;
    width: 100%;
    height: 1px;
    background: #e4ebf3;
  }
`;
