import { useRouteMatch } from 'react-router';
import { useStore } from '@stores/root';
import { useEffect } from 'react';


export function useCurrentStandaloneTranscript() {
  const match = useRouteMatch<{standalone_transcript_id: string}>();
  const store = useStore();

  if (!match.params.standalone_transcript_id) {
    throw new Error('Session ID not in route params')
  }

  useEffect(() => {
    store.transcriptStore.setCurrentTranscript(match.params.standalone_transcript_id)

    return () => {
      store.transcriptStore.setCurrentTranscript(null)
    }
  }, [store.transcriptStore, match.params.standalone_transcript_id])

  return store.transcriptStore.currentTranscript
}