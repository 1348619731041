import { flow, types } from 'mobx-state-tree';
import { getApi, getWebsocket } from '@stores/root';
import Studio, { IStudio } from '@models/Studio';
import Broadcast, { IBroadcast } from '@models/Broadcast';
import { values } from 'mobx';
import moment from 'moment';


export default types
  .model('Studio', {
    studios: types.array(Studio),
    broadcasts: types.map(Broadcast),
    currentBroadcast: types.maybeNull(types.reference(Broadcast))
  })
  .views(self => ({
    get linkStudio(): IStudio | null {
      if (self.studios.length > 0) {
        return self.studios[0]
      } else {
        return null;
      }
    },
    get broadcastList() {
      return (values(self.broadcasts) as unknown as IBroadcast[]).sort((a, b) => moment.utc(b.createdAt).diff(moment.utc(a.createdAt))).filter(item => item.state !== 'FAILED');
    },
  }))
  .views(self => ({
    get mainClips() {
      return self.broadcastList.map(item => {
        return {
          broadcastId: item.id,
          mainClipId: item.mainClip.id
        }
      })
    }
  }))
  .actions((self) => {
    const loadStudios = flow(function* loadStudios() {
      try {
        const studioData = yield getApi(self).loadStudios();
        self.studios.replace(studioData);
      } catch (e) {
        console.error(e);
      }
    })

    const loadBroadcasts = flow(function* loadBroadcasts() {
      try {
        const studioData = yield getApi(self).loadBroadcasts();
        studioData.forEach((element: any) => {
          if (element.clips.length === 0) {
            return;
          }

          self.broadcasts.put(element);
        });
      } catch (e) {
        console.error(e);
      }
    })

    const getBroadcast = flow(function* (id: string) {
      try {
        const broadcastData = yield getApi(self).fetchBroadcast(id);
        self.broadcasts.put(broadcastData);
      } catch (e) {
        console.error(e);
      }
    })

    const setCurrentBroadcast = flow(function* (id: string | null) {
      if (!id) {
        self.currentBroadcast = null;
        return
      }
  
      const session = self.broadcasts.get(id);
  
      if (!session) {
        yield getBroadcast(id)
      }
  
      self.currentBroadcast = self.broadcasts.get(id)!
    })
  
    return {
      loadStudios,
      loadBroadcasts,
      setCurrentBroadcast,
      getBroadcast
    };
  }).actions(self => ({
    afterCreate() {
      const ws = getWebsocket(self);

      ws.on('studio.updated', (msg) => {
        self.loadStudios();
      })

      ws.on('broadcast.created', (msg) => {
        self.getBroadcast(msg.broadcastUuid);
      })

      ws.on('broadcast.updated', (msg) => {
        self.getBroadcast(msg.broadcastUuid)
      })
    }
  }))
