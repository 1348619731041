import Paragraph, { Word } from '@models/editor/Paragraph';
import { makeObservable, observable, computed, action, reaction, makeAutoObservable, flow } from "mobx"
import moment from 'moment';
import { binarySearchByProperty } from '@util/binarySearch';
import API from 'api';
import { toast } from 'react-toastify';
import downloadURI from '@util/download';


export default class Editor {
  clipId: string;
  projectId: string;

  startTimestamp: number;
  endTimestamp: number;
  paragraphs: Paragraph[];

  currentTimeMs = 0;

  fileUrl: string;
  firstFrameUrl: string;
  transcriptId: string;
  isPlaying = false;
  videoNode: HTMLVideoElement | null = null;

  muted = false;

  cutSections: CutSection[];

  exportingSection = false;
  cutting = false;
  exportingFullVideo = false;

  api: API;

  get lengthMs() {
    return this.endTimestamp - this.startTimestamp;
  }

  get lengthFormatted() {
    return moment(this.lengthMs).utc().format('HH:mm:ss')
  }

  get currentTimeFormatted() {
    return moment(this.currentTimeMs).utc().format('HH:mm:ss')
  }

  get words() {
    return this.paragraphs.map(item => item.words).flat();
  }

  get timeWords() {
    const words = [];
    for (let word of this.words) {
      words.push({ timestamp: word.startedAt, word }, { timestamp: word.endedAt, word })
    }
    return words;
  }

  get currentWord() {
    if (this.timeWords.length === 0) {
      return null;
    }

    const element = binarySearchByProperty(this.timeWords, this.startTimestamp + this.currentTimeMs, 'timestamp')
    return element.word as Word;
  }

  get visibleCutSections() {
    const invisible: CutSection[] = [];

    for (let i = 0; i < this.cutSections.length; i++) {
      const element = this.cutSections[i];

      const filter = this.cutSections.filter(item => item !== element);
      for (let searchItem of filter) {
        if (element.startWordIndex > searchItem.startWordIndex && element.endWordIndex < searchItem.endWordIndex) {
          invisible.push(element)
        }
      }
    }

    return this.cutSections.filter(x => !invisible.includes(x));
  }

  constructor(projectId: string, clipId: string, startTimestamp: number, endTimestamp: number, paragraphs: Paragraph[], fileUrl: string, transcriptId: string, api: API, firstFrameUrl: string) {
    this.clipId = clipId;
    this.projectId = projectId;
    this.startTimestamp = startTimestamp;
    this.endTimestamp = endTimestamp;
    this.paragraphs = paragraphs;
    this.fileUrl = fileUrl;
    this.cutSections = [];
    this.transcriptId = transcriptId;
    this.api = api;
    this.firstFrameUrl = firstFrameUrl;

    this.loadCuts();

    makeObservable(this, {
      startTimestamp: observable,
      endTimestamp: observable,
      currentTimeMs: observable,
      isPlaying: observable,
      cutSections: observable,
      exportingSection: observable,
      cutting: observable,
      muted: observable,
      lengthMs: computed,
      lengthFormatted: computed,
      currentTimeFormatted: computed,
      words: computed,
      timeWords: computed,
      currentWord: computed,
      visibleCutSections: computed,
      togglePlayback: action.bound,
      updateCurrentTime: action.bound,
      createSection: action.bound,
      revertCut: action.bound,
      loadCuts: action.bound,
      toggleMute: action.bound,
      fullExport: action.bound
    })

    reaction(
      () => this.currentWord,
      (curr, prev) => {
        if (!curr || !prev) {
          return;
        }
        
        curr.selected = true
        prev.selected = false;
      }
    )
  }

  togglePlayback() {
    this.isPlaying = !this.isPlaying;
  }

  toggleMute() {
    this.muted = !this.muted;
  }

  createSection = flow(function* (this: Editor, startId: string, endId: string) {
    this.exportingSection = true;
  
    const startWord = this.words.find(item => item.id === startId)!;
    const endWord = this.words.find(item => item.id === endId)!;

    let startIndex = startWord.index;
    let endIndex = endWord.index;

    // in case that selection is reversed
    if (startIndex > endIndex) {
      const oldStartIndex = startIndex;
      startIndex = endIndex;
      endIndex = oldStartIndex;
    }

    const toastId = toast.info('Exporting Section...', {
      autoClose: false
    });

    let interval: number | undefined;
    try {
      const segment = yield this.api.createExport(this.projectId, startIndex, endIndex);
      const clipId = segment.clip.id;

      interval = setInterval(async () => {
        const clip = await this.api.getClip(clipId);

        if (clip.state === 'READY') {
          downloadURI(clip.downloadUrl, 'exported-section.mp4');
          toast.success('Section Exported');
          toast.dismiss(toastId);
          clearInterval(interval);
        }

      }, 3000)

    } catch(e) {
      toast.error('Could Not Export Section')
      clearInterval(interval)
      toast.dismiss(toastId);
    } finally {
      this.exportingSection = false;
    }
  })

  loadCuts = flow(function* (this: Editor) {
    const segments = yield this.api.getSegments(this.projectId);
    const sections: CutSection[] = [];

    for (let segment of segments) {
      if (segment.segmentType === 'CUT_OUT') {
        sections.push(new CutSection(segment.id, segment.startIndex, segment.endIndex, this))
      }
    }

    this.cutSections = sections;
  })

  createCut = flow(function* (this: Editor, startId: string, endId: string) {
    this.cutting = true;

    const startWord = this.words.find(item => item.id === startId)!;
    const endWord = this.words.find(item => item.id === endId)!;

    let startIndex = startWord.index;
    let endIndex = endWord.index;

    // in case that selection is reversed
    if (startIndex > endIndex) {
      const oldStartIndex = startIndex;
      startIndex = endIndex;
      endIndex = oldStartIndex;
    }

    // create a section with a fake ID to show it. Once the API returns just replace with real ID
    const section = new CutSection('fakeid' + Math.random(), startIndex, endIndex, this);
    this.cutSections.push(section);

    try {
      const data = yield this.api.createCut(this.projectId, startIndex, endIndex);
      section.id = data.id;
    } catch(e) {
      this.cutSections = this.cutSections.filter(item => item !== section);
      toast.error('Could not delete section')
    } finally {
      this.cutting = false;
    }
  })

  revertCut = flow(function* (this: Editor, cutSection: CutSection) {
    this.cutSections = this.cutSections.filter(item => item !== cutSection);

    try {
      yield this.api.revertSegment(cutSection.id)
    } catch {
      this.cutSections.push(cutSection);
      toast.error('Could not revert. Please try again.')
    }
  })

  fullExport = flow(function* (this: Editor) { 
    if (this.exportingFullVideo) {
      return;
    }

    this.exportingFullVideo = true;

    let interval: number | undefined;
    const toastId = toast.info('Exporting...', {
      autoClose: false
    });

    function download(clip: any) {
      downloadURI(clip.downloadUrl, 'exported-section.mp4');
      toast.success('Section Exported');
      toast.dismiss(toastId);
    }

    try {
      const clip = yield this.api.createFullExport(this.projectId);

      if (clip.state === 'READY') {
        download(clip);
        return;
      }

      const clipId = clip.id;
      interval = setInterval(async () => {
        const clip = await this.api.getClip(clipId);
        if (clip.state === 'READY') {
          download(clip);
          clearInterval(interval);
        }
      }, 3000)

    } catch(e) {
      toast.error('Could Not Export Section')
      clearInterval(interval)
      toast.dismiss(toastId);
    } finally {
      this.exportingFullVideo = false;
    }
  })


  /**
  * can be updated from html video element or from code
  * if the jump is true then it will update HTML video element. Use this if you want to call this function from code
  */
  updateCurrentTime(newTimeSec: number, jump = false) {
    this.currentTimeMs = Math.min(Math.max(newTimeSec * 1000, 0), this.lengthMs);

    // skip over CUT sections
    const cutTimes = this.visibleCutSections.map(item => item.startStopTimestamp);
    for (let cutTime of cutTimes) {
      const fromStart = cutTime.startTimestamp - this.startTimestamp;
      const fromEnd = cutTime.endTimestamp - this.startTimestamp;

      if (this.currentTimeMs >= fromStart && this.currentTimeMs <= fromEnd) {
        this.currentTimeMs = fromEnd + 10;
        jump = true;
        if (this.videoNode) {
          this.videoNode.pause();
        }
      }
    }

    if (jump && this.videoNode) {
      this.videoNode.currentTime = this.currentTimeMs / 1000;
      if (this.isPlaying) {
        this.videoNode.play();
      }
    }
  }

  addVideoNode(videoNode: HTMLVideoElement) {
    this.videoNode = videoNode;
  }

  static createFromData(projectId: string, clip: any, transcript: any, api: API) {
   const paragraphs = transcript.paragraphs.map((item: any) => Paragraph.createFromData(item))
   const editor = new Editor(projectId, clip.id, clip.startedAt, clip.endedAt, paragraphs, clip.downloadUrl, transcript.id, api, clip.thumbnails.find((item: any) => item.thumbnailType === 'IMAGE_FIRST_FRAME').downloadUrl);

   for (let para of paragraphs) {
     para.editor = editor;
     
     for (let word of para.words) {
       word.editor = editor;
     }
   }

   return editor;
  }
}


export class CutSection {
  words: Word[];

  startWordIndex: number;
  endWordIndex: number;
  id: string;

  editor: Editor;

  node: HTMLDivElement | null = null;

  get startingParagraph() {
    return this.words[0].paragraph;
  }

  get startStopTimestamp() {
    return {
      startTimestamp: this.words[0].startedAt,
      endTimestamp: this.words[this.words.length - 1].endedAt
    }
  }

  get dimensionsPercentage() {
    const totalTime = this.editor.lengthMs;
    const left = (this.startStopTimestamp.startTimestamp - this.editor.startTimestamp) / totalTime
    const width = (this.startStopTimestamp.endTimestamp - this.startStopTimestamp.startTimestamp) / totalTime
    return {
      left,
      width
    }
  }

  constructor(id: string, startIndex: number, endIndex: number, editor: Editor) {
    this.startWordIndex = startIndex;
    this.endWordIndex = endIndex;
    this.id = id;

    this.editor = editor;
    this.words = this.editor.words.slice(startIndex, endIndex + 1)
    
    for (let word of this.words) {
      word.markCut();
    }

    makeAutoObservable(this, {
      jumpToNode: action.bound
    })
  }

  revertCut() {
    for (let word of this.words) {
      word.uncut();
    }

    this.editor.revertCut(this)
  }

  addNode(node: HTMLDivElement) {
    this.node = node;
  }

  jumpToNode() {
    this.node?.scrollIntoView({behavior: "smooth", block: "center"})
  }
}