import { flow, Instance, types } from 'mobx-state-tree';
import Participant from '@models/Participant';
import SessionRecordingInfo from '@models/SessionRecordingInfo';
import { getApi } from '@stores/root';

const SessionRecording = types.model('SessionRecording', {
  id: types.identifier,
  name: types.string,
  participants: types.array(Participant),
  recordingInfo: types.maybe(SessionRecordingInfo),
  transcriptState: types.union(types.literal('CREATING'), types.literal('READY'), types.literal('DOES_NOT_EXIST')),
  startTimestamp: types.number,
  endTimestamp: types.maybeNull(types.number)
}).volatile(self => ({
  loadingTranscript: false
})).views(self => ({
  get isUploading() {
    return self.participants.some(item => item.isUploading)
  },
  get isProcessing() {
    return self.participants.some(item => item.isProcessing)
  },
  get isLoadingTranscript() {
    return self.transcriptState !== 'READY'
  },
  get filteredParticipants() {
    return self.participants.filter(item => item.recordingGroups.length > 0)
  },
  get hasRecordings() {
    return self.participants.some(item => item.recordingGroups.length > 0)
  },
  get hasRemoteRecordings() {
    return self.participants.some(item => item.hasRemoteRecordings)
  },
  get sessionRecordingLength() {
    if (!self.endTimestamp) {
      return 0;
    }

    return self.endTimestamp - self.startTimestamp
  }
})).actions(self => {
  const loadRecordingInfo = flow(function* () {
    self.loadingTranscript = true
    const api = getApi(self)

    const [transcript, timestamps] = yield Promise.all([api.fetchSessionRecordingTranscript(self.id), api.fetchSessionRecordingTimestamps(self.id)])

    self.recordingInfo = SessionRecordingInfo.create({
      transcript: transcript,
      timestamps: timestamps
    })

    self.loadingTranscript = false
  })

  const loadTimestamps = flow(function* () {
    const api = getApi(self);
    if (self.recordingInfo) {
      self.recordingInfo.timestamps = yield api.fetchSessionRecordingTimestamps(self.id);
    }
  })

  return {
    loadRecordingInfo,
    loadTimestamps
  }
})

export default SessionRecording;
export interface ISessionRecording extends Instance<typeof SessionRecording> {}