import { observer } from 'mobx-react-lite';
import React from 'react';
import styled, { css } from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { darken } from 'polished';
import Editor from '@models/editor/Editor';


interface VideoControlsProps {
  editor: Editor;
}

function VideoControls(props: VideoControlsProps) {
  return (
    <Container>
      <ControlContainer>
        <PlayPause isPlaying={props.editor.isPlaying} onClick={props.editor.togglePlayback}>
          <FontAwesomeIcon icon={props.editor.isPlaying ? 'pause' : 'play'} />
        </PlayPause>
        <Mute onClick={props.editor.toggleMute}>
          <FontAwesomeIcon icon={props.editor.muted ? 'volume-mute' : 'volume-up'} />
        </Mute>
      </ControlContainer>
      <Time>{props.editor.currentTimeFormatted} / {props.editor.lengthFormatted}</Time>
    </Container>
  )
}

export default observer(VideoControls)

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 15px;
  justify-content: space-between;
`

const PlayPause = styled.div<{isPlaying: boolean}>`
  width: 38px;
  height: 38px;
  background-color: #d7dfe8;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  user-select: none;

  svg {
    color: #000000;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    left: 1px;
  }

  ${(props) => props.isPlaying && css`
    svg {
      left: 0px;
    }
  `}

  &:hover {
    background-color: ${() => darken(0.05, '#d7dfe8')}
  }
`

const Time = styled.div`
  padding: 8px;
  border-radius: 6px;
  background-color: #d7dfe8;
  color: #000000;
  font-size: 14px;
  font-weight: 700;

  min-width: 125px;
  text-align: center;
`

const Mute = styled.div`
  width: 38px;
  height: 38px;
  background-color: #d7dfe8;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  user-select: none;

  svg {
    color: #000000;
    font-size: 17px;
    font-weight: 400;

  }

  &:hover {
    background-color: ${() => darken(0.05, '#d7dfe8')}
  }
`

const ControlContainer = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-left: 15px;
  }

  > *:first-child {
    margin-left: 0px;
  }
`