import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import DownloadButtons from '@components/ui/editor/DownloadButtons';
import Editor from '@models/editor/Editor';

interface HeaderProps {
  editor: Editor;
}

function Header(props: HeaderProps) {
  return (
    <Container>
        <Headline to='/studios'>
          <FontAwesomeIcon icon="chevron-left"/>
          Go back
        </Headline>
        <DownloadButtons editor={props.editor} />
    </Container>
  )
}

export default observer(Header)


const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 17px;
`

const Headline = styled(Link)`
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  line-height: 29.63px;
  text-decoration: none;
  display: flex;
  align-items: center;

  svg {
    font-size: 14px;
    font-weight: 400;
    line-height: 23.04px;
    margin-right: 11px;
  }

  &:hover {
    text-decoration: underline;
  }
`