import EnterStudio from '@components/ui/studio/EnterStudio';
import StudioList from '@components/ui/studio/StudioList';
import { useStore } from '@stores/root';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';
import QS from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


interface StudiosProps {}

function Studios(props: StudiosProps) {
  const store = useStore();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    async function fetchItems() {
      await Promise.all([store.studioStore.loadStudios(), store.studioStore.loadBroadcasts()])
    }
    
    fetchItems();
  }, [store.studioStore])

    // parse query string
    const queryParams = useMemo(() => QS.parse(location.search), [location.search]);

    // When the user navigates to the home page, check if is coming from Stripe pay page and send a Google Analytics event
    useEffect(() => {
      // check if results query param is SUCCESS
      if (queryParams['stripe_result'] === 'SUCCESS') {
        const { gtag } = window as any;
        gtag('event', 'purchase', {
          event_category: 'engagement',
          event_label: 'Payment done',
        });
      }
  
      delete queryParams['stripe_result'];
  
      history.replace({ search: QS.stringify(queryParams) });
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  return (
    <Container>
      <EnterStudio studio={store.studioStore.linkStudio} />
      {store.studioStore.broadcasts.size > 0 ? (
        <StudioList />
      ): (
        <InitialContainer>
          <InitialHeadline>Recordings</InitialHeadline>
          <div>
            <FontAwesomeIcon icon='info-circle' />
            <p>Recordings of your broadcasts will be displayed here and you will be able to edit and repurpose them</p>
          </div>
        </InitialContainer>
      )}
    </Container>
  )
}

export default observer(Studios)

const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 110px;
`

const InitialContainer = styled.div`
  color: #949BA3;

  > div {
    display: flex;
    align-items: center;
  }

  svg {
    margin-right: 8px;
  }
`

const InitialHeadline = styled.p`
  font-weight: 800;
  font-size: 18px;
  margin-bottom: 8px;
`