
import EventEmitter from 'events';
import humps from 'humps';
import { v4 as uuidv4 } from 'uuid';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { timeout } from './generic';


interface IBroadcastUpdatedMessage {
  broadcastUuid: string;
}

interface IBroadcastCreatedMessage {
  broadcastUuid: string;
}

interface IStudioUpdatedMessage {
  studioUuid: string;
}

interface IClipUpdatedMessage {
  clipUuid: string;
}

interface ITranscriptUpdatedMessage {
  transcriptUuid: string;
}

interface IMessageMap {
  'clip.updated': IClipUpdatedMessage,
  'broadcast.created': IBroadcastCreatedMessage,
  'broadcast.updated': IBroadcastUpdatedMessage,
  'studio.updated': IStudioUpdatedMessage,
  'transcript.updated': ITranscriptUpdatedMessage
}

type MessageTypes = keyof IMessageMap;

export declare interface Websocket {
  on<T extends MessageTypes>(event: T, listener: (data: IMessageMap[T]) => void): this;
  off<T extends MessageTypes>(event: T, listener: (data: IMessageMap[T]) => void): this;
}

export class Websocket extends EventEmitter {
  ws: ReconnectingWebSocket | null = null;
  interval: number;

  lastPingId: string | null = null;

  constructor() {
    super()
    this.setMaxListeners(300);  

    // prevents this issue: https://making.close.com/posts/reliable-websockets/
    this.interval = setInterval(this.ping.bind(this), 10000)
  }

  connect(authToken: string) {
    this.release()
    this.interval = setInterval(this.ping.bind(this), 10000);
    this.ws = new ReconnectingWebSocket(`${process.env.REACT_APP_WS_BASE_URL}/organization?token=${authToken}`)
    this.ws.addEventListener('message', this.onMessage.bind(this))
  }

  onMessage(e: MessageEvent<any>) {
    const data = humps.camelizeKeys(JSON.parse(e.data)) as unknown as any;
  
    if (data.eventType === 'pong') {
      this.lastPingId = data.pingId;
      return;
    }

    this.emit(data.eventType, {...data});
    console.log(`Received websocket message ${data.eventType}`)
  }

  async ping() {
    const pingId = uuidv4()
    this.ws?.send(JSON.stringify({ event_type: 'ping', ping_id: pingId }))
    await timeout(5000)
    if (pingId !== this.lastPingId) {
      console.log(`Websocket pong not received from server. Attempting reconnect.`)
      this.ws?.reconnect()
    }
  }

  release() {
    this.ws?.close();
    clearInterval(this.interval);
  }
}