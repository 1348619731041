import { types } from 'mobx-state-tree';
import { flow } from "mobx-state-tree"
import { getApi, getHistory, getNotifications, getWebsocket } from '@stores/root';
import { getStore } from '@stores/getStore';

export default types.model('AuthStore',{
  email: types.string,
  password: types.string,
  basePlanSlug: types.maybe(types.string),
  inProgress: types.boolean,
  token: types.maybe(types.string)
}).views(self => ({
  get isAuthorized() {
    return !!self.token
  }
})).actions(self => {
  const register = flow(function* (userData: {email: string, password: string}, from: any) {
    self.inProgress = true;
    removeToken()
    const referralToken = (window as any).Rewardful?.referral || null

    try {
      const data = yield getApi(self).register({...userData, referralToken })
      setToken(data.authToken.value)
      const store = getStore(self);
      yield store.accountStore.fetchUser()
      getHistory(self).replace(from)
    } catch (error) {
      let e = (error as any);

      if (e.response?.status === 422) {

        if (!!e.response?.data.email) {
          getNotifications(self).error(e.response?.data.email[0])
        }

        if (!!e.response?.data.promocode) {
          getNotifications(self).error(e.response?.data.promocode[0])
        }

        return;
      }

      console.error(e);
      getNotifications(self).error('Could not not register. Please try again later.')
    } finally {
      self.inProgress = false;
    }
  })

  const googleLogin = flow(function* (idToken: string, from: any) {
    self.inProgress = true
    removeToken()
    const referralToken = (window as any).Rewardful?.referral || null

    try {
      const data = yield getApi(self).googleLogin(idToken, referralToken);
      setToken(data.value)
      const store = getStore(self);
      yield store.accountStore.fetchUser()
      getHistory(self).replace(from)
    } catch (e) {
      getNotifications(self).error('Could not login using google account')
    } finally {
      self.inProgress = false;
    }
  })

  const login = flow(function* (userData: {email: string, password: string}, from: any) {
    self.inProgress = true;
    removeToken()

    try {
      const data = yield getApi(self).login(userData)
      setToken(data.value)
      const store = getStore(self);
      yield store.accountStore.fetchUser()
      getHistory(self).replace(from)
    } catch (e) {
      if ((e as any).response?.status === 422) {
        getNotifications(self).error((e as any).response?.data.nonFieldErrors[0] || 'Incorrect email or password')
        return;
      }

      getNotifications(self).error('Could not not login. Please try again later.')
      console.error(e);
    } finally {
      self.inProgress = false;
    }
  })

  const resetPasswordEmail = flow(function* (email: string) {
    self.inProgress = true;

    try {
      yield getApi(self).resetPasswordEmail(email);
      getNotifications(self).success('Great! Please check your email for password reset link.')
    } catch (e) {
      getNotifications(self).error('Could not reset your password. Please try again later')
      console.error(e);
    } finally {
      self.inProgress = false;
    }
  })

  const setNewPassword = flow(function* (password: string, token: string) {
    self.inProgress = true;

    try {
      const data = yield getApi(self).setNewPassword(password, token);
      setToken(data.authToken.value);
      const store = getStore(self);
      yield store.accountStore.fetchUser()
      getNotifications(self).success('Great! Your new password was set')
      getHistory(self).push('/sessions')
    } catch (e) {
      getNotifications(self).error('Could not set your password. Please try again later')
      console.error(e);
    } finally {
      self.inProgress = false;
    }
  })

  function adminLogin(token: string) {
    setToken(token);
    getHistory(self).replace('/sessions')
  }

  function setToken(token: string) {
    getApi(self).setToken(token);
    window.localStorage.setItem('token', token);
    self.token = token;

    // connect to new websocket if new token is supplied
    getWebsocket(self).connect(token);
  }

  function removeToken(redirect = true) {
    const api = getApi(self);
    api.removeToken();
    self.token = undefined;
    const history = getHistory(self);
    window.localStorage.removeItem('token');
    if (!history.location.pathname.startsWith('/auth') && redirect) {
      getHistory(self).replace('/auth/login')
    }
  }

  return {
    register,
    login,
    setToken,
    adminLogin,
    googleLogin,
    resetPasswordEmail,
    setNewPassword,
    removeToken
  }
})