import { useStore } from "@stores/root";
import { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";

export function useCurrentProject() {
  const match = useRouteMatch<{editor_id: string}>();
  const store = useStore();

  if (!match.params.editor_id) {
    throw new Error('editor ID not in route params')
  }

  useEffect(() => {
    store.editorStore.setCurrentEditor(match.params.editor_id)

    return () => {
      store.editorStore.setCurrentEditor(null)
    }
  }, [store.editorStore, match.params.editor_id])

  return store.editorStore.currentProject;
}