import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Transcript from '@components/containers/transcription/Transcript';
import { useCurrentStandaloneTranscript } from '../../../hooks/useCurrentStandaloneTranscript';


function StandaloneTranscript() {
  const standaloneTranscript = useCurrentStandaloneTranscript();
  const sessionRecording = standaloneTranscript?.sessionRecording;

  useEffect(() => {
    if (!sessionRecording && standaloneTranscript) {
      standaloneTranscript.reload()
    }

    if (sessionRecording && !sessionRecording.recordingInfo) {
      sessionRecording.loadRecordingInfo()
    }
  }, [sessionRecording, standaloneTranscript]);

  if (!sessionRecording || !standaloneTranscript) {
    return null;
  }

  return <Transcript sessionRecording={sessionRecording} name={standaloneTranscript.name} backToUrl={`/transcriptions`} />
}

export default observer(StandaloneTranscript);