import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
// @ts-ignore
import useDimensions from "react-use-dimensions";
import { formatToMMSS } from '@util/string';
import { ISessionRecordingInfo } from '@models/SessionRecordingInfo';
import { darken } from 'polished';
import { ITimestamp } from '@models/Timestamp';

interface TimelineProps {
  recordingLength: number;
  onLineClick: (percentage: number) => void;
  onStampClick: (stamp: ITimestamp) => void;
  recordingInfo: ISessionRecordingInfo;
  recordingStartTimestamp: number;
  cardRef:  React.RefObject<HTMLDivElement>
}

function Timeline(props: TimelineProps) {
  const [ref, { y, height }] = useDimensions();
  const [totalScrollHeight, setTotalHeight] = useState(0)
  const [positionPercentage, setPosition] = useState(0)
  const showScrollItem = totalScrollHeight > Math.round(height)

  useEffect(() => {
    function onScroll(e: any) {
      const percentagePosition = e.detail.current / e.detail.total
      window.requestAnimationFrame(() => {
        setPosition(percentagePosition)
      })
    }

    document.addEventListener('transcript-editor-scroll', onScroll)

    return () => {
      document.removeEventListener('transcript-editor-scroll', onScroll)
    };
  }, []);

  useEffect(() => {
    if (props.cardRef.current) {
      setTotalHeight(props.cardRef.current.scrollHeight);
    }
  }, [props.cardRef]);

  const onLineClick = useCallback(
    (e: any) => {
      const clientY = e.clientY
      const clickY = clientY - y
      const percentage = clickY / height
      props.onLineClick(percentage)
    },
    [props, height, y]
  );

  const stamps = props.recordingInfo.timestamps.map(stamp => {
    const fromStart = stamp.start - props.recordingStartTimestamp
    const Y = (fromStart / props.recordingLength) * height

    return <Stamp onClick={() => props.onStampClick(stamp)} key={stamp.id} style={{transform: `translateY(${Y}px)`}}>
      <div />
      <p>{formatToMMSS(fromStart)} STAMP</p>
    </Stamp>
  })

  return (
    <Container>
      <Content>
        <StopPoint>
          <div />
          <p>00:00</p>
        </StopPoint>
        {stamps}
        <Line onClick={onLineClick} ref={ref}>
          {showScrollItem &&
            <ScrollPoint style={{ transform: `translateY(${height * positionPercentage}px)`}}>
              <ScrollPointItem style={{height: ((height / totalScrollHeight) * height) || 0 }} />
              <div style={{position: 'relative'}}>
                <ScrollPointText>{ formatToMMSS(Math.floor(props.recordingLength * positionPercentage) ) }</ScrollPointText>
                <Background />
              </div>
            </ScrollPoint>
          }
        </Line>
        <StopPoint bottom>
          <div />
          <p>{formatToMMSS(props.recordingLength)}</p>
        </StopPoint>
      </Content>
    </Container>
  );
}

export default observer(Timeline);

const Container = styled.div`
  height: 100%;
  position: absolute;
  right: -180px;
  top: 0;
  overflow-y: hidden;
  width: 150px;
  user-select: none;
`

const Content = styled.div`
  position: relative;
  height: 100%;
`

const StopPoint = styled.div<{bottom?: boolean}>`
  position: absolute;
  display: flex;
  
  div {
    width: 14px;
    height: 6px;
    border-radius: 7px;
    background-color: #d7dfe8;
  }
  
  &:last-child {
    bottom: -6px;
  }
  
  p {
    color: #bdc5ce;
    font-size: 12px;
    font-weight: 700;
    margin-left: 5px;
    position: relative;
    top: ${(props) => props.bottom ? '-3px' : '1px'}
  }
`

const Stamp = styled.div`
  position: absolute;
  display: flex;
  left: 2.5px;
  z-index: 1;
  cursor: pointer;
  
  div {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: #feca45;
  }
  
  p {
    color: #feca45;
    font-size: 12px;
    font-weight: 700;
    margin-left: 5px;
    white-space: nowrap;
    position: relative;
    top: -2px
  }
  
  &:hover {
    p {
      color: ${() => darken(0.15, '#feca45' )}
    }
    
    div {
      background-color: ${() => darken(0.15, '#feca45' )}
    }
  }
`

const ScrollPoint = styled.div`
  display: flex;
  align-items: flex-start;
  position: absolute;
  left: -3px;
  z-index: 2;
`

const ScrollPointItem = styled.div`
  width: 8px;
  border-radius: 71px;
  background-color: #000000;
  z-index: 2;
`

const ScrollPointText = styled.p`
  margin: 0;
  color: #000000;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  margin-left: 6px;
  z-index: 3;
  position: relative;
`

const Line = styled.div`
  width: 2px;
  height: 100%;
  background-color: #d7dfe8;
  position: absolute;
  left: 6px;
  
  &:after {
    content: "";
    padding: 0px 10px;
    position: absolute;
    left: -10px;
    height: 100%;
  }
`

const Background = styled.div`
  background-color: #f3f6f9;
  position: absolute;
  width: 105px;
  height: 100%;
  left: 0;
  z-index: 1;
  top: 0;
`