import { types } from 'mobx-state-tree';

const Recording = types.model('Recording', {
  id: types.identifier,
  state: types.union(types.literal('CREATING'), types.literal('UPLOADING'), types.literal('UPLOADED'), types.literal('READY')),
  totalSizeBytes: types.maybeNull(types.number),
  uploadedSizeBytes: types.maybeNull(types.number),
  downloadUrl: types.maybeNull(types.string),
  mediaType: types.union(types.literal('VIDEO'), types.literal('AUDIO')),
  isFinal: types.boolean,
  thumbnails: types.array(types.model('Thumbnail', {
    id: types.identifier,
    type: types.union(types.literal('VIDEO_360_X'), types.literal('IMAGE_ORIGINAL')),
    ready: types.boolean,
    url: types.maybeNull(types.string)
  }))
}).views(self => {
  return {
    get isVideo() {
      return self.mediaType === 'VIDEO'
    },
    get isAudio() {
      return self.mediaType === 'AUDIO'
    }
  }
})

export default Recording;