import { getParentOfType, Instance, types } from 'mobx-state-tree';
import Paragraph, { IParagraph, IParagraphWord } from '@models/Paragraph';
import { TranscriptParticipant } from '@models/TranscriptParticipant';
import SessionRecording from '@models/SessionRecording';
import { BASE_URL } from '../api';

export interface IWordsDimensions {
  offsetTop: number;
}

export const Transcript = types.model('Transcript', {
  state: types.union(types.literal('CREATING'), types.literal('READY')),
  paragraphs: types.array(Paragraph),
  participants: types.array(TranscriptParticipant)
}).volatile(self => ({
  searchString: '',
  currentSearchMatch: 0,
  transcriptEditorNode: null as null | HTMLDivElement,
  timestampWordBounds: new Map<IParagraphWord, IWordsDimensions>()
})).views(self => ({
  get matchedWords() {
    const search = self.searchString.toLocaleLowerCase();
    const matchedWords: {startIndex: number; endIndex: number, paragraph: IParagraph, word: IParagraphWord}[] = []

    if (!search) {
      return matchedWords
    }

    const searchArray = search.split(' ').filter(item => !!item)
    const isMultiple = searchArray.length > 1;

    for (let paragraph of self.paragraphs) {
      for (let [index, word] of paragraph.words.entries()) {

        if (!isMultiple) {
          const wordIndex = word.text.toLocaleLowerCase().indexOf(searchArray[0]);
          if (wordIndex > -1) {
            matchedWords.push({startIndex: wordIndex, endIndex: wordIndex + searchArray[0].length - 1, paragraph: paragraph, word})
          }
        } else if (isMultiple) {

          const firstWordMatch = word.text.toLocaleLowerCase() === searchArray[0];
          const matchedSearch = [{startIndex: 0, endIndex: word.text.length - 1, paragraph: paragraph, word}]


          if (firstWordMatch) {
            for (let [searchIndex, nextTerm] of searchArray.slice(1).entries()) {
              const nextWord = paragraph.words[index + searchIndex + 1]
              const match = nextWord?.text.toLocaleLowerCase() === nextTerm;
              if (match) {
                matchedSearch.push({startIndex: 0, endIndex: nextWord.text.length - 1, paragraph: paragraph, word: nextWord})
              }
            }
          }


          if (matchedSearch.length === searchArray.length) {
            matchedWords.push(...matchedSearch)
            break;
          }
        }

      }
    }

    return matchedWords;
  },
  get matchedWordsMap() {
    return new Map(this.matchedWords.map(item => [item.word, item]))
  },
  get totalSearchMatches() {
    return this.matchedWords.length;
  },
  get wordTimings() {
    return self.paragraphs.flatMap(para => para.wordTimings);
  },
  get allWords() {
    return self.paragraphs.flatMap(para => para.words);
  },
  get txtDownloadUrl() {
    const sessionRecording = getParentOfType(self, SessionRecording) as any;
    return `${BASE_URL}/calls/transcripts/${sessionRecording.id}/txt`
  },
  get srtDownloadUrl() {
    const sessionRecording = getParentOfType(self, SessionRecording) as any;
    return `${BASE_URL}/calls/transcripts/${sessionRecording.id}/srt`
  }
})).actions(self => ({
  setSearch: function(text: string) {
    if (text !== self.searchString) {
      self.currentSearchMatch = 0;
    }

    self.searchString = text
  },
  jumpToNextSearch: function() {
    const next = self.currentSearchMatch + 1;

    if (self.totalSearchMatches >= next) {
      self.currentSearchMatch = next;
    } else {
      self.currentSearchMatch = 1;
    }

    const word = self.matchedWords[self.currentSearchMatch - 1];
    if (!word) {
      return
    }
    const paragraph = word.paragraph

    if (paragraph.node && self.transcriptEditorNode) {
      const transcriptRect = self.transcriptEditorNode.getBoundingClientRect()
      const paragraphRect = paragraph.node.getBoundingClientRect();
      const scrollTo = paragraphRect.top - transcriptRect.top + self.transcriptEditorNode.scrollTop - 200
      self.transcriptEditorNode.scrollTo({
        behavior: 'smooth',
        top: scrollTo
      })
    }
  },
  setTranscriptEditorNode: function(node: HTMLDivElement) {
    self.transcriptEditorNode = node;
  },
  setTimestampWordBounds: function(word: IParagraphWord, dimensions: IWordsDimensions) {
    self.timestampWordBounds.set(word, dimensions)
  }
}))



export default Transcript;
export interface ITranscript extends Instance<typeof Transcript> {}
