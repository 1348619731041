import { flow, types } from 'mobx-state-tree';
import Session, { ISession, ISessionSettings } from '@models/Session';
import { getApi, getNotifications } from '@stores/root';
import { values } from 'mobx';
import moment from 'moment';

export type SessionType = 'SINGLE_PLAYER' | 'MULTI_PLAYER';

export default types.model('SessionStore', {
  sessions: types.map(Session),
  currentSession: types.maybeNull(types.reference(Session)),
  loadingSessions: types.boolean,
  loadingNextPage: types.boolean,
  creatingSession: types.boolean,
  limit: 10,
}).views(self => ({
  get hasSessions() {
    return self.sessions.size > 0
  },
  get sessionList() {
    return (values(self.sessions) as unknown as ISession[]).sort((a, b) => moment.utc(b.dateCreated).diff(moment.utc(a.dateCreated)));
  },
})).views(self => ({
  get after(): string | undefined {
    return self.sessionList[self.sessionList.length - 1]?.id;
  }
})).actions(self => {

  const createSession = flow(function* (name: string, settings: ISessionSettings, sessionType: SessionType) {
    self.creatingSession = true;

    try {
      const sessionData = yield getApi(self).createSession(name, settings, sessionType);
      const session = Session.create(sessionData);
      self.sessions.put(session)
      return session;
    } catch (e) {
      getNotifications(self).error('Could not create session. Please try again.')
      console.error(e);
      throw e
    } finally {
      self.creatingSession = false;
    }
  })

  const refreshSessions = flow(function* () {
    try {
      const sessionsData = yield getApi(self).fetchSessions(self.sessions.size);
      sessionsData.forEach((item: any) => {
        self.sessions.put(item)
      })
    } catch (e) {
      console.error(e);
    }
  })

  const fetchNextSessionPage = flow(function* () {
    self.loadingSessions = true;
    if (self.loadingNextPage) {
      return;
    }

    try {
      self.loadingNextPage = true
      const sessionsData = yield getApi(self).fetchSessions(self.limit, self.after);
      sessionsData.forEach((item: any) => {
        self.sessions.put(item)
      })
    } catch (e) {
      console.error(e);
    } finally {
      self.loadingSessions = false;
      self.loadingNextPage = false;
    }
  })

  const getSession = flow(function* (id: string) {
    try {
      const sessionData = yield getApi(self).fetchSession(id);
      self.sessions.put(sessionData);
    } catch (e) {
      console.error(e);
    }
  })

  const setCurrentSession = flow(function* (id: string | null) {
    if (!id) {
      self.currentSession = null;
      return
    }

    const session = self.sessions.get(id);

    if (!session) {
      yield getSession(id)
    }

    self.currentSession = self.sessions.get(id)!
  })

  const archiveSession = flow(function* (id: string) {
    self.sessions.delete(id);

    try {
      yield getApi(self).archiveSession(id);
    } catch (e) {
      console.error(e);
    }
  })

  return {
    createSession,
    getSession,
    refreshSessions,
    setCurrentSession,
    fetchNextSessionPage,
    archiveSession
  }
})