import React from 'react';
import styled from 'styled-components/macro';
import { FieldError } from 'react-hook-form';

interface InputProps {
  label: string;
  name: string;
  required?: boolean;
  type: 'text' | 'url' | 'email' | 'password';
  placeholder?: string;
  errors?: FieldError;
  autoFocus?: boolean;
}

const Input = React.forwardRef((props: InputProps, ref) => {
  return (
    <Container>
      <Label htmlFor={props.name}>{props.label}</Label>
      <InputField autoFocus={props.autoFocus} error={!!props.errors?.message} ref={ref as any} placeholder={props.placeholder} required={props.required} name={props.name} type={props.type} />
      {props.errors?.message && <ErrorMsg>{props.errors.message}</ErrorMsg>}
    </Container>
  )
})

export default Input;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  
  &:focus-within {
    label {
      color: #feca45;
    }
    
    input {
      border: 2px solid #feca45;
    }
  }
`;

const Label = styled.label`
  color: #949ba3;
  font-size: 18px;
  font-weight: 600;
  line-height: 29.63px;
  margin-bottom: 9px;
`

const InputField = styled.input<{error?: boolean}>`
  height: 62px;
  border-radius: 14px;
  border: 2px solid #d7dfe8;
  color: #000000;
  font-size: 18px;
  font-weight: 700;
  padding-left: 18px;
  outline: none;
  
  &::placeholder {
    color: #d7dfe8;
    font-size: 18px;
    font-weight: 600;
  }
  
  ${(props) => props.error && `
    border: 2px solid #f62c2c!important;
  `}
`

const ErrorMsg = styled.p`
  margin-top: 10px;
  color: #f62c2c;
`