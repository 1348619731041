import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components/macro';

interface FreeBannerProps {
  hasPremium: boolean;
}

function FreeBanner(props: FreeBannerProps) {
  return (
    <Container>
      <Headline>Everything you need. For free. <span role='img' aria-label='emoji'>😎</span></Headline>
      <div>
        <Items>
          <ItemHeadline><span role='img' aria-label='emoji'>🔴</span> LIVE Studio</ItemHeadline>
          <Item>Full HD (1080p) live streaming</Item>
          <Item>Stream to 5+ platforms at the same time</Item>
          <Item>Unlimited recording of your streams</Item>
        </Items>
        <Items>
          <ItemHeadline><span role='img' aria-label='emoji'>🎬</span> LOCAL Studio</ItemHeadline>
          <Item>Unlimited hours of HQ recording (up to 4k video/wav audio)</Item>
          <Item>Up to 8 participants</Item>
          <Item>Separate audio & video tracks for each participant</Item>
          <Item>Automatic transcription after every recording</Item>
        </Items>
        <Items>
          <ItemHeadline>🖊 TRANSCRIPTION Studio</ItemHeadline>
          <Item>Unlimited video & audio to text transcriptions</Item>
        </Items>
      </div>
      {!props.hasPremium && (
        <ButtonContainer>
          <ActivePlan>You are on this plan</ActivePlan>
        </ButtonContainer>
      )}

    </Container>
  )
}

export default observer(FreeBanner)

const Container = styled.div`
  display: flex;
  width: 100%;
  margin-top: 30px;
  padding: 30px;
  justify-content: space-between;
  border-radius: 33px;
  background-color: #feca45;
  background-image: linear-gradient(141deg, #ffd260 17%, #feca45 86%);
  box-shadow: 0 11px 41px 0 rgb(188 160 89 / 40%);
  color: #000;
  margin-top: 75px;
  flex-direction: column;
  position: relative;
`

const Headline = styled.p`
  font-size: 26px;
  color: #000;
  font-size: 26px;
  font-weight: 700;
`

const Items = styled.div`
  margin-top: 20px;
`

const ItemHeadline = styled.p`
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: 700;
`

const Item = styled.p`
  color: #5f4c1c;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;

  &::before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: white;
    margin-right: 10px;
    margin-bottom: 1px;
  }
`

const ButtonContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`

const ActivePlan = styled.div`
  width: 100%;
  outline: none;
  appearance: none;
  border-radius: 14px;
  padding: 18px 20px;
  border: 1px solid white;
  color: white;
  user-select: none;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
`