import React from 'react';
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface SelectProps {
  label: string;
  name: string;
  options: {label: string; value: string}[];
}

const Select = React.forwardRef((props: SelectProps, ref) => {
  let options = props.options.map((item) => (
    <option key={item.value} value={item.value}>
      {item.label}
    </option>
  ));

  return (
    <Container>
      <Label htmlFor={props.name}>{props.label}</Label>
      <SelectContainer>
        <SelectField ref={ref as any} name={props.name}>
          {options}
        </SelectField>
        <FontAwesomeIcon icon="chevron-down" />
      </SelectContainer>
    </Container>
  )
})

export default Select;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  
  &:focus-within {
    label {
      color: #feca45;
    }
    
    input {
      border: 2px solid #feca45;
    }
  }
`;

const Label = styled.label`
  color: #949ba3;
  font-size: 18px;
  font-weight: 600;
  line-height: 29.63px;
  margin-bottom: 9px;
`

const SelectContainer = styled.div`
  position: relative;
  width: 100%;

  > svg {
    position: absolute;
    right: 20px;
    top: 20px;
  }
`;

const SelectField = styled.select`
  appearance: none;
  padding: 18px 20px;
  border-radius: 14px;
  border: 2px solid #d7dfe8;
  width: 100%;
  outline: none;
  color: #000000;
  font-size: 18px;
  font-weight: 600;
`;