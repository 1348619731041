import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components/macro';

import BG from '@assets/studio-enter-bg.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lighten } from 'polished';
import { IStudio } from '@models/Studio';

interface EnterStudioProps {
  studio: IStudio | null;
}

function EnterStudio(props: EnterStudioProps) {

  function onStudioClick() {
    if (props.studio) {
      window.open( props.studio.joinUrls.host, '_blank')    
    }
  }

  return (
    <Container>
      <div>
        <Headline>Welder Studio</Headline>
        <Subtext>Live stream, record, edit & repurpose your video content.</Subtext>
      </div>
      <Button onClick={onStudioClick}>
        Enter Production Studio
        <FontAwesomeIcon icon='arrow-right' />
      </Button>
    </Container>
  )
}

export default observer(EnterStudio)

const Container = styled.div`
  width: 100%;
  max-width: 930px;
  border-radius: 33px;
  position: relative;
  background-image: url(${() => BG});
  background-size: cover;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 70px 90px;
  margin-bottom: 57px;
`

const Headline = styled.h1`
  color: black;
  font-size: 48px;
  font-weight: 700;
`

const Button = styled.a`
  text-decoration: none;
  box-shadow: 0px 5px 41px rgba(223, 176, 56, 0.5);
  border-radius: 14px;
  background: #FECA45;
  padding: 18px 20px;
  color: #000000;
  font-size: 18px;
  font-weight: 700;

  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-left: 32px;
  }

  &:hover {
    background: ${() => lighten(0.05, '#FECA45')};
  }
`

const Subtext = styled.p`
  margin: 0px;
  margin-top: 10px;
  color: black;
  font-weight: 600;
  font-size: 16px;
  line-height: 164.6%;
  font-weight: 700;
  max-width: 333px;
`