import { useRouteMatch } from 'react-router';
import { useEffect } from 'react';
import { useStore } from '@stores/root';

export function useCurrentSession() {
  const match = useRouteMatch<{session_id: string}>();
  const store = useStore();

  if (!match.params.session_id) {
    throw new Error('Session ID not in route params')
  }

  useEffect(() => {
    store.sessionStore.setCurrentSession(match.params.session_id)

    return () => {
      store.sessionStore.setCurrentSession(null)
    }
  }, [store.sessionStore, match.params.session_id])

  return store.sessionStore.currentSession
}