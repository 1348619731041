import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import { ISessionRecording } from '@models/SessionRecording';
import RecordingItem from '@components/ui/RecordingItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RingSpinner } from 'react-spinners-kit';
import { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { ISession } from '@models/Session';

interface SessionRecordingsProps {
  sessionRecording: ISessionRecording;
  showBackups: boolean;
  session: ISession;
}

function SessionRecordings(props: SessionRecordingsProps) {

  const items = props.sessionRecording.filteredParticipants.map(participant => {
    const groups = props.showBackups ? participant.remoteRecordingGroups : participant.localRecordingGroups
    return groups.map(group => <RecordingItem key={group.id} isServerSide={props.showBackups} group={group} />)
  }).flat()

  return (
    <Container>
      <Header>
        <h3>{props.sessionRecording.name}</h3>
        <Buttons>
          {props.sessionRecording.transcriptState !== 'DOES_NOT_EXIST' && (
            <TranscriptionButton to={`/sessions/session/${props.session.id}/transcript/${props.sessionRecording.id}`} $isGenerating={props.sessionRecording.isLoadingTranscript}>
              {props.sessionRecording.isLoadingTranscript ? <>
                <RingSpinner size={20} loading={props.sessionRecording.isLoadingTranscript} color='#0a6c5b' />
                Generating Transcript...
              </> : <>
                <FontAwesomeIcon icon='text' />
                Get Transcription
              </>}
            </TranscriptionButton>
          )}
        </Buttons>
      </Header>
      <RecordingList>
        {items}
      </RecordingList>
    </Container>
  );
}

export default observer(SessionRecordings);

const Container = styled.div`
  margin-top: 60px;
  &:first-child {
    margin-top: 30px;
  }
`;

const RecordingList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -13px;
  
  > div {
    margin: 13px;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 22px;
  
  h3 {
    color: #000000;
    font-size: 18px;
    font-weight: 600;
    line-height: 29.63px;
  }
`

const Buttons = styled.div`
  display: flex;
  align-items: center;
`

const TranscriptionButton = styled(Link)<{$isGenerating: boolean}>`
  border-radius: 12px;
  background-color: rgba(10, 108, 91, 0.1);
  padding: 9px;
  color: #0a6c5b;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  text-decoration: none;
  align-items: center;
  
  > *:first-child {
    margin-right: 7px;
  }
  
  svg {
    font-size: 15px;
    font-weight: 400;
  }
  
  &:hover {
    background-color: #0a6c5b;
    color: #ffffff;
  }
  
  ${props => props.$isGenerating && css`
    pointer-events: none;
  `}
`