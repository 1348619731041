import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Button from '@components/ui/Button';
import TranscriptEditor from '@components/ui/transcript/TranscriptEditor';
import Search from '@components/ui/transcript/Search';
import { ISessionRecording } from '@models/SessionRecording';
import { RingSpinner } from 'react-spinners-kit';

interface TranscriptProps {
  sessionRecording: ISessionRecording;
  name: string;
  backToUrl: string;
}

function Transcript(props: TranscriptProps) {

  const sessionRecording = props.sessionRecording;
  const name = props.name;

  if (!sessionRecording.recordingInfo || sessionRecording.loadingTranscript) {
    return <Spinner><RingSpinner size={50} loading={sessionRecording.loadingTranscript} color='#b8babc' /></Spinner>
  }

  return (
    <Container>
      <Header>
        <Headline to={props.backToUrl}>
          <FontAwesomeIcon icon="chevron-left"/>
          Go back
        </Headline>
        <ControlsContainer>
          <Search transcript={sessionRecording.recordingInfo.transcript} />
          <Buttons>
            <a href={sessionRecording.recordingInfo.transcript.srtDownloadUrl} download>
              <Button text='Download as .srt' icon='file-alt' colorScheme='basic' reverseIcon />
            </a>
            <a href={sessionRecording.recordingInfo.transcript.txtDownloadUrl} download>
              <Button text='Download as .txt' icon='file-alt' colorScheme='basic' reverseIcon />
            </a>
          </Buttons>
        </ControlsContainer>
      </Header>
      <TranscriptEditor transcript={sessionRecording.recordingInfo.transcript} name={name} recordingInfo={sessionRecording.recordingInfo} recordingStartTimestamp={sessionRecording.startTimestamp} recordingLength={sessionRecording.sessionRecordingLength} />
    </Container>
  );
}

export default observer(Transcript);

const Container = styled.div`
  max-width: 875px;
  margin: 0 auto;
`

const Header = styled.div`
  margin-top: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35px;
`

const Headline = styled(Link)`
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  line-height: 29.63px;
  text-decoration: none;
  display: flex;
  align-items: center;

  svg {
    font-size: 14px;
    font-weight: 400;
    line-height: 23.04px;
    margin-right: 11px;
  }

  &:hover {
    text-decoration: underline;
  }
`

const ControlsContainer = styled.div`
  display: flex;
  align-items: center;
`

const Buttons = styled.div`
  display: flex;
  align-items: center;
  
  > a {
    text-decoration: none;
  }

  > * {
    white-space: nowrap;
  }
  
  > *:first-child {
    margin-right: 7px;
  }
`

const Spinner = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 80px;
`