import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';

const MAX_SPARKS = 200;

const SPARK_MAX_AGE_MIN = 40;
const SPARK_MAX_AGE_MAX = 80;

const SPARK_VELOCITY_X_MIN = -2;
const SPARK_VELOCITY_X_MAX = 2;

const SPARK_VELOCITY_Y_MIN = -3;
const SPARK_VELOCITY_Y_MAX = -1;

const GRAVITY = 0.1;

const CLICK_BRUST_SIZE_MIN = 30;
const CLICK_BRUST_SIZE_MAX = 50;

function getRandomFloat(min: number, max: number) {
return Math.random() * (max - min) + min;
}

function getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const sparks: any = [];

function addSpark(x: number, y: number) {
    if (sparks.length >= MAX_SPARKS) {
        return;
    }

    const spark = document.createElement('div') as any;
    spark.style.display = 'block';
    spark.style.width = '2px';
    spark.style.height = '2px';
    spark.style.position = 'fixed';
    spark.style.zIndex = '99999';
    spark.style.pointerEvents = 'none';
    spark.style.left = '0px';
    spark.style.top = '0px';
    spark.style.background = 'rgb(254, 202, 69)';

    spark.position = {'x': x, 'y': y};

    spark.velocity = {
        'x': getRandomFloat(SPARK_VELOCITY_X_MIN, SPARK_VELOCITY_X_MAX),
        'y': getRandomFloat(SPARK_VELOCITY_Y_MIN, SPARK_VELOCITY_Y_MAX),
    };

    spark.age = 0;
    spark.maxAge = getRandomInt(SPARK_MAX_AGE_MIN, SPARK_MAX_AGE_MAX);
    spark.fadingStartAge = Math.round(spark.maxAge / 1.2);
    spark.coldAge = Math.round(spark.maxAge / 1.8);

    document.body.appendChild(spark);
    sparks.push(spark);

    runAnimation();
}

let animationRunning = false;
function runAnimation() {
    if (!animationRunning) {
        animationRunning = true;
        window.requestAnimationFrame(sparksFrame);
    }
}

let lastTimestamp: number | undefined;

function sparksFrame(timestamp: number) {
    if (lastTimestamp === undefined)
      lastTimestamp = timestamp;

    const elapsed = timestamp - lastTimestamp;

    // ±30 fps
    if (elapsed < 66) {
      window.requestAnimationFrame(sparksFrame);
      return;
    }

    sparks.forEach((spark: any) => {
        spark.position.x += spark.velocity.x;
        spark.velocity.y += GRAVITY;
        spark.position.y += spark.velocity.y;
        spark.style.transform = `translate(${Math.round(spark.position.x)}px, ${Math.round(spark.position.y)}px)`;

        spark.style.opacity = (1 - (spark.age - spark.fadingStartAge) / (spark.maxAge - spark.fadingStartAge));

        spark.age += 1;

        if (spark.age === spark.maxAge) {
            document.body.removeChild(spark);
            sparks.splice(sparks.indexOf(spark), 1);
        }
    });


    if (sparks.length > 0 ) {
        window.requestAnimationFrame(sparksFrame);
    } else {
        animationRunning = false;
    }
}


interface SparkingElementProps {
    children: React.ReactNode;
    sparkOnClick: boolean;
    sparkOnMove: boolean;
}

function SparkingElement(props: SparkingElementProps) {
    function mouseClick(mouseEvent: any) {
        if (props.sparkOnClick) {
            for (let i = 0; i < getRandomInt(CLICK_BRUST_SIZE_MIN, CLICK_BRUST_SIZE_MAX); i++) {
                addSpark(mouseEvent.clientX, mouseEvent.clientY);
            }
        }
    }

    function mouseMove(mouseEvent: any) {
        if (props.sparkOnMove) {
            addSpark(mouseEvent.clientX, mouseEvent.clientY);
        }
    }
    
    return (
        <Container onMouseMove={mouseMove} onMouseDown={mouseClick}>
            {props.children}
        </Container>
      );
}

export default observer(SparkingElement);

const Container = styled.div``