import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import { RingSpinner } from "react-spinners-kit";

interface SpinnerProps {
  loading: boolean;
  size?: number;
}

function Spinner(props: SpinnerProps) {
  return (
    <Container>
      <RingSpinner size={props.size} loading={props.loading} color='#b8babc' />
    </Container>
  );
}

export default observer(Spinner);

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`