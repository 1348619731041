import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import Card from '@components/ui/Card';
import InputAction from '@components/ui/InputAction';
import { useStore } from '@stores/root';
import { useHistory, useParams } from 'react-router-dom';
import { ISessionSettings } from '@models/Session';
import SessionSettings from '@components/ui/SessionSettings';
import { SessionType } from '@stores/sessions';

interface SessionNewProps {}

function SessionNew(props: SessionNewProps) {
  const store = useStore();
  const history = useHistory();
  const params = useParams<{session_type: string}>()
  let sessionType: SessionType;

  switch (params.session_type) {
    case 'singleplayer':
      sessionType = 'SINGLE_PLAYER'
      break;
    case 'multiplayer':
      sessionType = 'MULTI_PLAYER'
      break;
  }

  const [settings, setSettings] = useState<ISessionSettings>({
    enableAudioPostProcessing: true
  })

  async function onSubmit(name: string) {
    const session = await store.sessionStore.createSession(name, settings, sessionType);

    if (sessionType === 'MULTI_PLAYER') {
      history.push(`/sessions/session/${session.id}/join`)
    } else {
      history.push(`/sessions/session/${session.id}`)
      window.open(session.joinUrls.host.web, '_blank')?.focus();
    }
  }

  return (
    <Container>
      <NewContainer>
        <Card maxWidth={834}>
          <CardContent>
            <Headline>Name your session</Headline>
            <InputAction autoFocus loading={store.sessionStore.creatingSession} onSubmit={onSubmit} buttonText='Create' />
            <SessionSettings settings={settings} onChange={(newSettings: ISessionSettings) => setSettings({...settings, ...newSettings})} />
          </CardContent>
        </Card>
      </NewContainer>
    </Container>
  );
}

export default observer(SessionNew);

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const NewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  
  > div {
    margin-bottom: 150px;
  }
`

const Headline = styled.h1`
  color: #000000;
  font-size: 48px;
  font-weight: 400;
  margin-bottom: 28px;
`

const CardContent = styled.div`
  width: 100%;
  height: 100%;
`

