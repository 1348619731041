import React from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components/macro';
import { darken, lighten } from 'polished';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import SVGWelder from '@assets/welder.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';

interface InputActionProps {
  buttonText: string;
  icon?: IconProp;
  inputText?: string;
  light?: boolean;
  loading?: boolean;
  onSubmit: (data: string) => void;
  autoFocus?: boolean;
}

function InputAction(props: InputActionProps) {

  const { register, handleSubmit } = useForm();

  const onSubmit = (data: any) => {
    props.onSubmit(data.text)
  }

  const buttonText = props.loading ? 'Loading...' : props.buttonText;

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <Input autoFocus={props.autoFocus} readOnly={!!props.inputText} defaultValue={props.inputText} name='text'  ref={register({ required: true })}  required type='text' />
      <Button disabled={props.loading} light={props.light} type='submit'>
        {props.icon ?
          <FontAwesomeIcon icon={props.icon} /> :
          <img alt='logo' src={SVGWelder} width={21} />
        }
        <p>{buttonText}</p>
      </Button>
    </Container>
  );
}

export default observer(InputAction);

const Container = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Input = styled.input`
  border: 2px solid #d7dfe8;
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  padding: 16px 20px;
  outline: none;
  border-radius: 14px 0 0 14px;
  flex: 1;
  border-right: none;
  
  &:focus {
    border: 2px solid #feca45;
    border-right: none;
  }
`

const Button = styled.button<{light?: boolean}>`
  outline: none;
  appearance: none;
  ${(props) => props.light === false && 'box-shadow: 0 11px 41px rgba(188, 160, 89, 0.4);'}
  
  ${(props) => props.light ? 'background-color: #d7dfe8;' : 'background-image: linear-gradient(90deg, #ffd260 0%, #feca45 100%);'};
  padding: 18px 20px;
  color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  user-select: none;
  cursor: pointer;
  text-decoration: none;
  border-radius: 0 14px 14px 0;
  
  p {
    font-size: 18px;
    font-weight: 700;
  }
  
  img {
    margin-right: 11px;
  }
  
  svg {
    font-size: 18px;
    font-weight: 400;
    margin-right: 11px;
  }
  
  ${(props) => props.light ? css`
    &:hover {
      background-color: ${() => darken(0.1, '#d7dfe8')};
    }
  ` : css`
    &:hover {
        background-image: linear-gradient(90deg, ${() => lighten(0.05, '#ffd260')} 0%, ${() => lighten(0.05, '#feca45')} 100%);
    }
  `}
  
  &:disabled {
    background-image: none;
    box-shadow: none;
    background-color: #d4d4d4;
  }
  
`