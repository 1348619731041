import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';

import Header from '@components/ui/editor/Header';
import Video from '@components/ui/editor/video';
import Content from '@components/ui/editor/content';
import { useCurrentProject } from '../../../hooks/useCurrentEditor';
import Spinner from '@components/ui/Spinner';

interface EditorProps {}

function Editor(props: EditorProps) {
  const project = useCurrentProject();

  if (!project) {
    return <SpinnerContainer><Spinner size={64} loading={true} /></SpinnerContainer>;
  }

  if (!project.editor) {
    project.loadEditor();
    return <SpinnerContainer><Spinner size={64} loading={true} /></SpinnerContainer>;
  }

  return (
    <Container>
      <Header editor={project.editor} />
      <Items>
        <Video editor={project.editor} />
        <Content editor={project.editor} />
      </Items>
    </Container>
  )
}

export default observer(Editor)

const Container = styled.div`
  padding: 25px;
`

const Items = styled.div`
  display: flex;

  > *:first-child {
    margin-right: 15px;
  }

  > *:last-child {
    flex: 1;
  }
`

const SpinnerContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`