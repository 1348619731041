

export function debounceLeading(this: any, func: Function, timeout = 300){
  let timer: any;

  return (...args: any) => {
    if (!timer) {
      func.apply(this, args);
    }
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = undefined;
    }, timeout);
  };
}

export function timeout(ms: number | undefined) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}