import { useCallback, useEffect } from "react";

export function useKeyPress(callback: () => void, keyCodes: string[], preventDefault = false): void {
  const handler = useCallback((e: KeyboardEvent) => {
    if (keyCodes.includes(e.code)) {
      callback();
      if (preventDefault) {
        e.preventDefault()
      }
    }
  }, [callback, keyCodes, preventDefault]);

  useEffect(() => {
    window.addEventListener("keydown", handler);
    return () => {
      window.removeEventListener("keydown", handler);
    };
  }, [handler]);
}