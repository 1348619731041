import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CutSection } from '@models/editor/Editor';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';

interface TextCutProps {
  cutSection: CutSection;
}

function TextCut(props: TextCutProps) {
  const [isHover, setIsHover] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      props.cutSection.addNode(ref.current)
    }
  }, [ref, props.cutSection])

  return (
    <Container ref={ref} onClick={() => props.cutSection.revertCut()} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
      [ {isHover ? <FontAwesomeIcon icon='undo' /> : '  ...  '} ]
    </Container>
  )
}

export default observer(TextCut)

const Container = styled.div`
  display: inline-block;
  font-weight: 900;
  font-size: 15px;
  color: #FE4545;
  user-select: none;
  width: 37.5px;
  cursor: pointer;
  text-align: center;
`