import { flow, getParentOfType, Instance, types } from 'mobx-state-tree';
import SessionRecording from '@models/SessionRecording';
import { TranscriptParticipant } from '@models/TranscriptParticipant';
import { getApi, getNotifications } from '@stores/root';
import Transcript, { IWordsDimensions } from '@models/Transcript';

export interface IParagraphWord {
  "id": string;
  "text": string,
  "startTimestamp": number,
  "endTimestamp": number
}

const Paragraph = types.model('Paragraph', {
  id: types.identifier,
  startTimestamp: types.number,
  endTimestamp: types.number,
  words: types.array(types.frozen<IParagraphWord>()),
  participant: types.reference(TranscriptParticipant)
}).volatile(self => ({
  node: null as null | HTMLDivElement,
})).views(self => ({
  get wordTimings() {
    return self.words.map(word => word.startTimestamp)
  },
  get recordingStartTimestamp(): number {
    return getParentOfType(self, SessionRecording).startTimestamp
  },
  get paragraphStartTimeSec(): number {
    return self.startTimestamp - this.recordingStartTimestamp
  },
})).actions(self => ({
  createTimestamp:  flow(function* (word: IParagraphWord) {
    const sessionRecording = getParentOfType(self, SessionRecording) as any;

    try {
      yield getApi(self).createTranscriptTimestamp(sessionRecording.id, self.participant.id, word.startTimestamp, word.endTimestamp)
      yield sessionRecording.loadTimestamps();
      getNotifications(self).success('Timestamp created')
    } catch (e) {
      getNotifications(self).error('Could not create timestamp')
    }
  }),
  setNode: function(node: HTMLDivElement) {
    self.node = node;
  },
  setTimestampWordBounds: function(word: IParagraphWord, dimensions: IWordsDimensions) {
    const transcript = getParentOfType(self, Transcript);
    transcript.setTimestampWordBounds(word, dimensions)
  }
}))

export default Paragraph
export interface IParagraph extends Instance<typeof Paragraph> {}
