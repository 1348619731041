import React from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components/macro';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { darken, lighten } from 'polished';

type ColorScheme = 'primary' | 'secondary' | 'basic';

interface ButtonProps {
  text: string;
  icon: IconProp;
  type?: 'submit';
  loading?: boolean;
  colorScheme?: ColorScheme;
  onClick?: () => Promise<void> | undefined;
  reverseIcon?: boolean;
}

function Button(props: ButtonProps) {
  const text = props.loading ? 'Loading...' : props.text;
  const scheme = props.colorScheme || 'primary';

  let content = props.reverseIcon ? (
    <>
      <FontAwesomeIcon icon={props.icon} />
      <p>{text}</p>
    </>
  ) : (
    <>
      <p>{text}</p>
      <FontAwesomeIcon icon={props.icon} />
    </>
  )

  return (
    <Container onClick={props.onClick} scheme={scheme} disabled={props.loading} type={props.type}>
      {content}
    </Container>
  );
}

export default observer(Button);

const Container = styled.button<{scheme: ColorScheme}>`
  width: 100%;
  outline: none;
  appearance: none;
  border-radius: 14px;
  background-image: linear-gradient(90deg, #ffd260 0%, #feca45 100%);
  box-shadow: 0 11px 41px rgba(188, 160, 89, 0.4);
  padding: 18px 20px;
  color: #000000;
  border: none;
  user-select: none;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  
  svg {
    color: #000000;
    font-size: 15px;
    font-weight: 400;
    margin-right: 13px;
  }
  
  &:hover {
    background-image: linear-gradient(90deg, ${() => lighten(0.05, '#ffd260')} 0%, ${() => lighten(0.05, '#feca45')} 100%);
  }
  
  ${(props) => props.scheme === 'secondary' && css`
    background-image: none;
    border: 1px solid #181818;
    background-color: #000000;
    box-shadow: none;
    color: #ffffff;
    
    svg {
      color: #ffffff;
    }
    
    &:hover {
      background-image: none;
      background-color: ${() => lighten(0.15, '#000000')};
    }
  `}

  ${(props) => props.scheme === 'basic' && css`
    background-image: none;
    border: none;
    background-color: #e2e8ee;
    box-shadow: none;
    color: #000000;
    font-size: 14px;
    font-weight: 700;
    border-radius: 12px;
    padding: 11px 12px;
    
    > *:first-child {
      margin-right: 6px;
    }
    
    svg {
      color: #000000;
    }
    
    &:hover {
      background-image: none;
      background-color: ${() => darken(0.05, '#e2e8ee')};
    }
  `}
  
  &:disabled {
    background-image: none;
    box-shadow: none;
    background-color: #d4d4d4;
    
    &:hover {
      background-image: none;
    }
  }
`;