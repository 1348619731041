import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import SessionListItem from '@components/ui/SessionListItem';
import { ISession } from '@models/Session';

interface SessionListProps {
  sessions: ISession[];
}

function SessionList(props: SessionListProps) {

  const sessions = props.sessions.map(item => <SessionListItem key={item.id} session={item} />)

  return (
    <Container>
      <Headline>Your Sessions</Headline>
      <Sessions>
        {sessions}
      </Sessions>
    </Container>
  );
}

export default observer(SessionList);

const Container = styled.div`
  width: 100%;
  max-width: 850px;
  margin: 0 auto;
  margin-top: 57px;
`;

const Headline = styled.div`
  color: #949ba3;
  font-size: 18px;
  font-weight: 800;
  line-height: 29.63px;
`

const Sessions = styled.div`
  > * {
    border-bottom: 1px solid #e4ebf3;
  }
  
  > *:last-child {
    border-bottom: 0;
  }
`
