import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import Sidebar from '@components/containers/Sidebar';
import RemoveWatermark from '@components/ui/RemoveWatermark';
import LocalDisabled from '@components/ui/LocalDisabled';


interface SidebarLayoutProps {
  children: React.ReactNode;
}

function SidebarLayout(props: SidebarLayoutProps) {
  const sidebarWidth = 91;

  return (
    <Container>
      <EarlyAccessContainer>
        <LocalDisabled />
      </EarlyAccessContainer>
      <SidebarContainer sidebarWidth={sidebarWidth}>
        <Sidebar sidebarWidth={sidebarWidth} />
      </SidebarContainer>
      <Content>
        {props.children}
      </Content>
    </Container>
  );
}

export default observer(SidebarLayout);

const Container = styled.div`
  display: flex;
`;

const SidebarContainer = styled.div<{sidebarWidth: number}>`
  width: ${props => props.sidebarWidth}px;
  height: 100vh;
`

const Content = styled.div`
  flex: 1;
  min-height: 100vh;
`

const EarlyAccessContainer = styled.div`
  position: absolute;
  top: 24px;
  right: 26px;
  display: flex;
  align-items: flex-start;
`
