import { Instance, types, hasParentOfType, getParentOfType } from 'mobx-state-tree';
import { MomentPrimitive } from '@util/mstTypes';
import Recording from '@models/Recording';
import Participant from '@models/Participant';


const RecordingGroup = types.model('RecordingGroup', {
  id: types.identifier,
  type: types.union(types.literal('CAMERA_LOCAL'), types.literal('SCREEN_REMOTE'), types.literal('SCREEN_LOCAL'), types.literal('CAMERA_REMOTE')),
  dateStarted: types.maybeNull(MomentPrimitive),
  lengthSeconds: types.maybeNull(types.number),
  recordings: types.array(Recording),
  uploadProgress: types.model('uploadProgress', {
    totalSizeBytes: types.maybeNull(types.number),
    uploadedSizeBytes: types.maybeNull(types.number)
  }),
}).views(self => ({
    get fullName(): string {
      if (hasParentOfType(self, Participant)) {
        const parent = getParentOfType(self, Participant)
        return `${parent.name}`
      }

      return 'Recording'
    },
    get isUploading() {
      return self.recordings.some(item => item.state === 'UPLOADING')
    },
    get isProcessing() {
      return self.recordings.some(item => item.state === 'CREATING')
    },
    get isScreenShare() {
      return self.type.includes('SCREEN');
    },
    get thumbnail_url() {
      const recording = self.recordings.find(recording => recording.isFinal && recording.mediaType === 'VIDEO');
      if (!recording) {
        return null;
      }

      const thumbnail = recording.thumbnails.find(item => item.type === 'VIDEO_360_X');

      if (!thumbnail) {
        return null
      }

      return thumbnail.url
    },
    get uploadPercent() {
      if (!self.uploadProgress.uploadedSizeBytes || !self.uploadProgress.totalSizeBytes) {
        return null;
      }

      const progress = self.uploadProgress.uploadedSizeBytes / self.uploadProgress.totalSizeBytes;

      return (Math.round((progress + Number.EPSILON) * 100) / 100) * 100
    },
    get showFiles() {
      return !this.isUploading && !this.isProcessing
    }
}))

export default RecordingGroup;

export interface IRecordingGroup extends Instance<typeof RecordingGroup> {}