import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import Card from '@components/ui/Card';
import Button from '@components/ui/Button';
import Select from '@components/ui/form/Select';
import { useForm } from 'react-hook-form';
import { useStore } from '@stores/root';
import { useHistory } from 'react-router';
import Input from '@components/ui/form/Input';

interface ExtraInfoProps {}

function ExtraInfo(props: ExtraInfoProps) {
  const store = useStore();
  const history = useHistory();

  const { register, handleSubmit } = useForm<FormData>();
  const onSubmit = async (data: any) => {
    await store.accountStore.setExtraInfo(data);
    history.push('/account/walkthrough')
  }

  const occupationOptions = [{label: 'Youtuber', value: 'youtuber'}, {label: 'Podcaster', value: 'podcaster'}, {label: 'Reporter / Interviewer', value: 'reporter/interviewer'}, {label: 'Business / enterprise', value: 'business'}]
  const deviceOptions = [{label: 'Webcamera & Microphone', value: 'webcam'}, {label: 'DSLR (camera) & Microphone', value: 'dslr'}, {label: 'Microphone only', value: 'mic-only'}, {label: 'Other setup', value: 'other'}]

  return (
    <Container>
      <Content>
        <Headline>Tell us a bit more about yourself</Headline>
        <Card padding='45px 57px' maxWidth={618}>
          <CardForm onSubmit={handleSubmit(onSubmit)}>
            <Inputs>
              <Input label='Other people call me:' placeholder='Johnny' name='name' type='text' required ref={register({ required: true })} />
              <Select ref={register({ required: true })} options={occupationOptions} name='occupation' label='I am a' />
              <Select ref={register({ required: true })}  options={deviceOptions} name='recording-device' label='I want to use Welder mainly with:' />
              <Input label='You can learn more about me here: (optional)' placeholder='Website link / youtube link / podcast link' name='more-info-link' type='url' ref={register} />
            </Inputs>
            <Button loading={store.accountStore.submittingExtraInfo} type='submit' text='Continue' icon='arrow-right' />
          </CardForm>
        </Card>
      </Content>
    </Container>
  );
}

export default observer(ExtraInfo);

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
`;

const Content = styled.div`
  max-width: 618px;
  width: 100%;
  position: relative;
  z-index: 10;
`

const CardForm = styled.form`
  width: 100%
`

const Inputs = styled.div`
  > * {
    margin-bottom: 40px;
  }
`

const Headline = styled.h1`
  color: #000000;
  font-size: 38px;
  font-weight: 400;
  margin-bottom: 33px;
`