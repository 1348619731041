import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import Logo from '@assets/logo.svg';
import NavIcon from '@components/ui/NavIcon';
import { useHistory } from 'react-router-dom';
import { useStore } from '@stores/root';
import SparkingElement from '@components/ui/SparkingElement';

interface SidebarProps {
  sidebarWidth: number;
}

function Sidebar(props: SidebarProps) {
  const history = useHistory();
  const store = useStore();
  const hasPremium = !!store.accountStore.currentPlan?.isPremium;

  return (
    <Container sidebarWidth={props.sidebarWidth}>
      <Content>
        <SparkingElement sparkOnClick={true} sparkOnMove={true}>
          <AppLogo onClick={() => history.push('/')} src={Logo} alt='Welder logo' width={38} />
        </SparkingElement>
        <Items>
          <Navigation>
            <NavIcon route='/transcriptions' icon='text' text='Transcription Studio' />
          </Navigation>
          <Footer>

            <NavIcon onClick={store.accountStore.logout} icon='sign-out' text='Log out' />
          </Footer>
        </Items>
      </Content>
    </Container>
  );
}

export default observer(Sidebar);

const Container = styled.div<{sidebarWidth: number}>`
  width: ${(props) => props.sidebarWidth}px;
  background: ${(props) => props.theme.card};
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 17px;
  padding-bottom: 25px;
`;

const Content = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const AppLogo = styled.img`
  cursor: pointer;
`

const Items = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 23px;
  align-self: center;
  justify-content: space-between;
  flex: 1 1 auto;
  position: relative;
  z-index: 9999;
`

const Navigation = styled.div`
  position: relative;
  width: 39px;
  
  > * {
    margin-top: 22px;
  }
  
  > *:first-child {
    margin-top: 0;
  }
`

const Footer = styled.div`
  position: relative;
  width: 39px;
  
  > * {
    margin-top: 18px;
  }
`
