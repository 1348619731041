import { flow, types } from 'mobx-state-tree';
import Editor from '@models/editor/Editor';
import { getApi } from '@stores/root';



const EditorProject = types.model('EditorProject', {
  id: types.identifier,
  state: types.union(types.literal('CREATING'), types.literal('READY')),
  loadingEditor: false,
  clipId: types.string,
}).volatile(self => ({
  editor: null as Editor | null
})).actions(self => {
  const loadEditor = flow(function* () {
    if (self.loadingEditor) {
      return;
    }

    self.loadingEditor =  true;
    const api = getApi(self);

    const clip = yield api.getClip(self.clipId);
    const transcript = yield api.getTranscript(clip.transcriptId);

    try {
      self.editor = Editor.createFromData(self.id, clip, transcript, api);
    } finally {
      self.loadingEditor = false;
    }
  })

  return {
    loadEditor
  }
})


export default types.model('EditorsStore', {
  loadingProject: false,
  projects: types.map(EditorProject),
  currentProject: types.maybeNull(types.reference(EditorProject))
}).actions(self => {

  const loadProject = flow(function* (id: string) {
    self.loadingProject =  true;
    const projectData = yield getApi(self).getProject(id);
    self.projects.put(projectData);
  });

  const setCurrentEditor = flow(function* (id: string | null) {
    if (!id) {
      self.currentProject = null;
      return
    }

    const editor = self.projects.get(id);

    if (!editor) {
      yield loadProject(id)
    }

    self.currentProject = self.projects.get(id)!
  })

  return {
    setCurrentEditor,
    loadProject
  }
})