import React from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Link, useHistory } from 'react-router-dom';
import { lighten } from 'polished';
import {
  Tooltip,
} from 'react-tippy';


interface LinkButtonProps {
  icon: IconProp;
  text: string;
  link: string;
  iconMargin?: number;
  inactive?: boolean;
  hoverText?: string;
  inactiveClick?: () => void;
}

function LinkButton(props: LinkButtonProps) {
  const iconMargin = props.iconMargin || 100;
  const history = useHistory();

  function click(e: any) {
    e.preventDefault();

    if (props.inactive && props.inactiveClick) {
      props.inactiveClick()
    } else {
      history.push(props.link)
    }
  }

  return (
    <Tooltip theme='light' title={props.inactive ? props.hoverText : undefined}>
      <Button onClick={click} $inactive={!!props.inactive} iconmargin={iconMargin} to={props.link}>
        <p>{props.text}</p>
        <FontAwesomeIcon icon={props.icon} />
      </Button>
    </Tooltip>

  );
}

export default observer(LinkButton);

const Button = styled(Link)<{iconmargin: number; $inactive: boolean}>`
  outline: none;
  appearance: none;
  box-shadow: 0 11px 41px rgba(188, 160, 89, 0.4);
  border-radius: 14px;
  background-image: linear-gradient(90deg, #ffd260 0%, #feca45 100%);
  padding: 18px 20px;
  color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  user-select: none;
  cursor: pointer;
  text-decoration: none;
  
  p {
    font-size: 18px;
    font-weight: 700;
  }
  
  svg {
    margin-left: ${(props) => props.iconmargin}px;
    font-size: 15px;
    font-weight: 400;
  }
  
  &:hover {
    background-image: linear-gradient(90deg, ${() => lighten(0.05, '#ffd260')} 0%, ${() => lighten(0.05, '#feca45')} 100%);
  }
  
  ${(props) => props.$inactive && css`
    background-image: none;
    background-color: #e2e8ee;
    box-shadow: none;
    
    &:hover {
      background-image: none;
      background-color: ${() => lighten(0.03, '#e2e8ee')};
    }
  `}
`