import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';

interface CardProps {
  children: React.ReactNode;
  padding?: string;
  maxWidth?: number;
  onScroll?: (e: React.UIEvent<HTMLDivElement>) => void;
  innerRef?:  React.MutableRefObject<any>;
}

function Card(props: CardProps) {
  const padding = props.padding || '70px 80px'

  return (
    <Container ref={props.innerRef} maxWidth={props.maxWidth} padding={padding} {...props}>
      {props.children}
    </Container>
  );
}

export default observer(Card);

const Container = styled.div<{padding: string, maxWidth?: number}>`
  border-radius: 33px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  padding: ${(props) => props.padding};
  width: 100%;
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth}px`}
`;