import 'resize-observer-polyfill'
import 'reset-css';
import 'mobx-react-lite/batchingForReactDom'
import '@util/fontAwesome';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tippy/dist/tippy.css';

import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import { StoreProvider } from '@stores/root';
import App from '@components/containers/App';
import GlobalStyle from '@util/globalStyle';

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider>
      <GlobalStyle />
      <App />
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
