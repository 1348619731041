import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import Button from '@components/ui/Button';

export type PlanSlugs = 'basic' | 'professional' | 'free' | 'standard-unlimited-monthly' | 'standard-unlimited-yearly';

interface PaidPlanProps {
  popular?: boolean;
  name: string;
  price: string;
  description: string;
  features: string[];
  slug: PlanSlugs;
  activePlanSlug: PlanSlugs;
  hasPremium?: boolean;
  planClick: (slug: string) => Promise<void>;
  discount?: string;
  yearly?: boolean;
}

function PaidPlan(props: PaidPlanProps) {
  const isActivePlan = props.slug === props.activePlanSlug;
  const hasAnyPlan = props.hasPremium
  const [loading, setLoading] = useState(false);
  const intervalText = props.yearly ? 'year' : 'mo'

  async function click() {
    setLoading(true)

    try {
      await props.planClick(props.slug)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container>
      {props.popular && <PopularContainer><p>Picked by most users</p></PopularContainer>}
      <PricingContainer>
        <DescriptionContainer>
          <PricingHeader>
            <h2>{props.name}</h2>
            <div style={{position: 'relative'}}>
              {props.discount ?
                <>
                  <PricingDiscount />
                  <NewDiscountPrice>
                    ${props.discount}/{intervalText}
                  </NewDiscountPrice>
                </> : null
              }
              <Pricing>
                <p>${props.price}<span>/{intervalText}</span></p>
              </Pricing>
            </div>
          </PricingHeader>
          <PricingDescription>
            {props.description}
          </PricingDescription>
          {isActivePlan ?
            <ButtonContainer>
              <ActivePlan>You are on this plan</ActivePlan>
            </ButtonContainer>
            :
            <ButtonContainer>
              <Button loading={loading} onClick={click} text={hasAnyPlan ? 'Change to this plan' : 'Upgrade'} icon='arrow-right' colorScheme={props.popular ? 'primary' : 'secondary'} />
            </ButtonContainer>
          }
        </DescriptionContainer>
        <FeatureContainer>
          <p>Including</p>
          <FeatureItems>
            {props.features.map((item, index) => <Feature key={index}>{item}</Feature>)}
          </FeatureItems>
        </FeatureContainer>
      </PricingContainer>
    </Container>
  );
}

export default observer(PaidPlan);

const Container = styled.div`
  position: relative;
  margin-right: 33px;
  max-width: 32%;
  flex: 1;

  &:last-child {
    margin-right: 0px;
  }
`

const PricingContainer = styled.div`
  border-radius: 33px;
  background-color: #ffffff;
  width: 100%;
  position: relative;
  z-index: 2;
`

const PopularContainer = styled.div`
  position: absolute;
  z-index: 1;
  width: 101.2%;
  height: 101.6%;
  background: #feca45;
  left: -0.6%;
  top: -0.5%;
  border-radius: 34px;
  
  p {
    position: absolute;
    bottom: 5px;
    left: 51%;
    white-space: nowrap;
    transform: translateX(-50%);
    color: #ffffff;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
  }
`

const Pricing = styled.div`
  p {
    color: #000000;
    font-weight: 700;
    line-height: 42.8px;
    font-size: 34px;
  }
  
  span {
    font-size: 15px;
    font-weight: 600;
  }
`

const PricingHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  
  h2 {
    color: #000000;
    font-size: 26px;
    font-weight: 700;
    line-height: 42.8px;
  }
`

const PricingDiscount = styled.div`
  width: 101px;
  height: 3px;
  background: #d85637;
  position: absolute;
  border-radius: 4px;
  transform: rotate(-20deg);
  top: 23px;
  left: 4px;
`

const NewDiscountPrice = styled.span`
  position: absolute;
  color: #d85637;
  font-size: 34px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 60.9px;
  text-align: right;
  top: -46px;
  left: -19px;
`

const PricingDescription = styled.p`
  color: #949ba3;
  font-size: 16px;
  font-weight: 400;
  line-height: 26.34px;
  margin-top: 6px;
  margin-bottom: 28px;
`

const ButtonContainer = styled.div`
  margin-bottom: 30px;
`

const DescriptionContainer = styled.div`
  padding: 15px 30px 0 30px;
`

const FeatureContainer = styled.div`
  background-color: #fafafa;
  padding: 0 30px 30px 30px;
  border-bottom-right-radius: 34px;
  border-bottom-left-radius: 34px;
  
  > p {
    color: #dbdbdb;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    padding-top: 26px;
  }
`

const FeatureItems = styled.div`
  margin-top: 10px;
`

const Feature = styled.div`
  display: flex;
  align-items: center;
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  line-height: 25.92px;
  margin-top: 16px;
  
  &::before {
    content: ' ';
    display: block;
    margin-right: 8px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: #feca45;
  }
  
  &:first-child {
    margin-top: 5px;
  }
`

const ActivePlan = styled.div`
  width: 100%;
  outline: none;
  appearance: none;
  border-radius: 14px;
  padding: 18px 20px;
  border: 1px solid #feca45;
  color: #feca45;
  user-select: none;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
`