import { flow, Instance, types } from 'mobx-state-tree';
import SessionRecording from '@models/SessionRecording';
import { MomentPrimitive } from '@util/mstTypes';
import { getStore } from '@stores/getStore';

const StandaloneTranscript = types.model('StandaloneTranscript', {
  id: types.identifier,
  name: types.string,
  createdAt: MomentPrimitive,
  uploadUrl: types.maybeNull(types.string),
  state: types.union(types.literal('CREATED'), types.literal('UPLOADED'), types.literal('PROCESSING'), types.literal('READY')),
  sessionRecording: types.maybe(SessionRecording),
  sessionRecordingId: types.string
}).views(self => ({
  get isProcessing() {
    return self.state === 'PROCESSING'
  }
})).actions(self => ({
  reload: flow(function* () {
    const store = getStore(self)
    yield store.transcriptStore.getTranscript(self.id);
  }),
}))

export default StandaloneTranscript;
export interface IStandaloneTranscript extends Instance<typeof StandaloneTranscript> {}
